import { defineStore } from "pinia";
import Vue from "vue";
import DomainService from "@services/domain/Domain";
import * as _ from "lodash-es";
import { i18n } from "@/plugins/i18n";
import Http from "@/plugins/axios"; // não precisa de .js

const CREATE_RECORD = "/domains/record";
const GET_COURSES = "/domains/get-courses";
const GET_TRAINING = "/domains/get-training";

export const useDomain = defineStore("domain", {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            domains: {
                logo_position: [
                    { label: "Normal", value: "default" },
                    { label: "Centralizada", value: "centralized" },
                    { label: "Superior Quadrada", value: "normal-square" },
                ],
                gender: [
                    { label: "Masc", value: "M" },
                    { label: "Fem", value: "F" },
                    { label: "Não informar", value: "u" },
                ],
                nationalities: [
                    { label: "Brasileiro Nato", value: 1 },
                    { label: "Naturalizado", value: 2 },
                    { label: "Estrangeiro", value: 3 },
                ],
                has_letter_attribute: [
                    { label: "Sim", value: "y" },
                    { label: "Não", value: "n" },
                ],
                professional_history: [
                    { label: "Sim", value: "y" },
                    { label: "Não", value: "n" },
                ],
                option_yes: [{ label: "Sim", value: true }],
                radio_boolean_options: [
                    { label: "Sim", value: true },
                    { label: "Não", value: false },
                ],
                data_holder: [
                    { label: "Sou o titular dos dados", value: true },
                    { label: "Sou procurador ou representante", value: false },
                ],
                data_holder_category: [
                    { label: "Cliente", value: "Cliente" },
                    { label: "Colaborador", value: "Colaborador" },
                    { label: "Lead/Prospect", value: "Lead/Prospect" },
                    { label: "Ex-colaborador", value: "Ex-colaborador" },
                    { label: "Fornecedor", value: "Fornecedor" },
                ],
                exercise_rights: [
                    {
                        label: "Corrigir dados incompletos, inexatos ou desatualizados",
                        value: "cd",
                    },
                    {
                        label: "Permitir a portabilidade dos dados a outro fornecedor de serviço ou produto",
                        value: "pp",
                    },
                    {
                        label: "Receber informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados",
                        value: "ri",
                    },
                    { label: "Revogar consentimento", value: "rc" },
                    {
                        label: "Revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais",
                        value: "rd",
                    },
                    {
                        label: "Somente confirmar a existência de tratamento e ter acesso aos meus dados que são tratados pela Selecty",
                        value: "sc",
                    },
                ],
                result_request: [
                    {
                        label: "Meio eletrônico (e-mail): o e-mail de contato será o mesmo preenchido para o titular dos dados pessoais ou procurador, quando aplicável",
                        value: "email",
                    },
                    {
                        label: "Formato impresso: o endereço utilizado será o mesmo preenchido para o titular de dados pessoais ou procurador, quando aplicável",
                        value: "letter",
                    },
                ],
                countries: [],
                document_type: [],
                states: [],
                cities: [],
                cities_rg: [],
                cities_born: [],
                cities_voter_registration: [],
                occupations: [],
                interest_levels: [],
                interest_areas: [],
                pcd: [],
                admissional_education_level: [],
                education_level: [],
                education_institution: [],
                education_course: [],
                education_situation: [],
                education_period: [],
                education_time: [],
                additional_training: [],
                languages: [],
                computing: [],
                colleges: [],
                civil_status: [],
                kinship: [],
                cnh_type: [],
                street_type: [],
                acting_areas: [],
                company_sizes: [],
                racial: [],
                admissional_racial: [],
                gender_identity: [],
                gender_orientation: [],
                vacancy_interval: [],
                vacancy_contract_type: [],
                vacancy_workplace_type: [],
                vacancy_professional_area: [],
                companies: [],
                armed_forces_service_status: [
                    { label: "Alistado", value: "Alistado" },
                    { label: "Reservista", value: "Reservista" },
                    { label: "Dispensado", value: "Dispensado" },
                ],
                banks: [],
                bank_types: [
                    { label: "Conta Corrente", value: 1 },
                    { label: "Conta Poupança", value: 2 },
                    { label: "Conta Salário", value: 3 },
                ],
                bank_holder_types: [
                    { label: "Pessoa Física", value: 1 },
                    { label: "Pessoa Jurídica", value: 2 },
                ],
                bank_pix_types: [
                    { label: "Não Informado", value: '0' },
                    { label: "Telefone", value: '1' },
                    { label: "Email", value: '2' },
                    { label: "CPF/CNPJ", value: '3' },
                    { label: "Chave Aleatória", value: '4' },
                ],
                tshirt: [
                    { label: "P", value: "P" },
                    { label: "M", value: "M" },
                    { label: "G", value: "G" },
                    { label: "GG", value: "GG" },
                    { label: "EG", value: "EG" },
                ],
                trousers: _.map([...Array(27).keys()], (item) => ({
                    label: item + 34,
                    value: item + 34,
                })),
                shirt: _.map([...Array(10).keys()], (item) => ({
                    label: item + 1,
                    value: item + 1,
                })),
                benefits: [
                    { label: "Vale Refeição", value: "vr" },
                    { label: "Vale Alimentação", value: "va" },
                    {
                        html: "Assistência Médica <small>(verifique se sua empresa é elegível para este benefício)</small>",
                        value: "am",
                    },
                    { label: "Vale Transporte", value: "vt" },
                ],
                transports: [],
                way_transport: [],
            },
            labelMap: new Map(),
        };
    },
    getters: {
        /**
         * @todo Plurarize all domain names
         */

        gender() {
            return this.domains["gender"];
        },
        nationalities() {
            return this.domains["nationalities"];
        },

        countries() {
            return this.domains["countries"];
        },
        document_type() {
            return this.domains["document_type"];
        },
        states() {
            return this.domains["states"];
        },
        cities() {
            return this.domains[`cities`];
        },
        cities_rg() {
            return this.domains[`cities_rg`];
        },
        cities_born() {
            return this.domains[`cities_born`];
        },
        cities_voter_registration() {
            return this.domains[`cities_voter_registration`];
        },
        occupations() {
            return this.domains[`occupations`];
        },
        interest_levels() {
            return this.domains[`interest_levels`];
        },
        interest_areas() {
            return this.domains[`interest_areas`];
        },
        pcd() {
            return this.domains[`pcd`];
        },
        admissional_education_level() {
            return this.domains[`admissional_education_level`];
        },
        education_level() {
            return this.domains[`education_level`];
        },
        education_institution() {
            return this.domains[`education_institution`];
        },
        education_course() {
            return this.domains[`education_course`];
        },
        education_situation() {
            return this.domains[`education_situation`];
        },
        education_period() {
            return this.domains[`education_period`];
        },
        education_time() {
            return this.domains[`education_time`];
        },
        additional_training() {
            return this.domains[`additional_training`];
        },
        languages() {
            return this.domains[`languages`];
        },
        computing() {
            return this.domains[`computing`];
        },
        civil_status() {
            return this.domains[`civil_status`];
        },
        kinship() {
            return this.domains[`kinship`];
        },
        cnh_type() {
            return this.domains[`cnh_type`];
        },
        street_type() {
            return this.domains[`street_type`];
        },
        acting_areas() {
            return this.domains[`acting_areas`];
        },
        company_sizes() {
            return this.domains[`company_sizes`];
        },
        racial() {
            return this.domains[`racial`];
        },
        admissional_racial() {
            return this.domains[`admissional_racial`];
        },
        gender_identity() {
            return this.domains[`gender_identity`];
        },
        gender_orientation() {
            return this.domains[`gender_orientation`];
        },
        vacancy_interval() {
            return this.domains[`vacancy_interval`];
        },
        vacancy_contract_type() {
            return this.domains[`vacancy_contract_type`];
        },
        vacancy_workplace_type() {
            return this.domains[`vacancy_workplace_type`];
        },
        vacancy_professional_area() {
            return this.domains[`vacancy_professional_area`];
        },
        armed_forces_service_status() {
            return this.domains[`armed_forces_service_status`];
        },
        banks() {
            return this.domains[`banks`];
        },
        bank_types() {
            return this.domains[`bank_types`];
        },
        bank_holder_types() {
            return this.domains[`bank_holder_types`];
        },
        bank_pix_types() {
            return this.domains[`bank_pix_types`];
        },
        tshirt() {
            return this.domains[`tshirt`];
        },
        trousers() {
            return this.domains[`trousers`];
        },
        shirt() {
            return this.domains[`shirt`];
        },
        benefits() {
            return this.domains[`benefits`];
        },
        transports() {
            return this.domains[`transports`];
        },
        way_transport() {
            return this.domains[`way_transport`];
        },
        companies() {
            return this.domains[`companies`];
        },
    },
    actions: {
        async load(path, key, subdomain = null) {
            if (_.isUndefined(key)) {
                key = path;
            }

            if (_.has(this.domains, path) && !_.isEmpty(this.domains[path])) {
                return Promise.resolve(this.domains[path]);
            }

            return DomainService.build()
                .read(path)
                .then(({ data }) => {
                    let keys = path.replace("/", "_");
                    this.$patch((state) => {
                        if (!_.has(state.domains, key)) {
                            state.domains[key] = [];
                        }

                        state.domains[key].splice(data.length);

                        for (let i in data) {
                            if (!_.isNull(subdomain)) {
                                key = subdomain;
                            }
                            Vue.set(
                                state.domains[key],
                                i,
                                this.parseItem(data[i])
                            );
                        }
                    });
                    return this.domains[path];
                });
        },
        parseItem(item) {
            if (_.has(item, "token")) {
                item.label = i18n.tc(item.token, item.value);
            }
            return item;
        },
        getLabel(domain, id, defaultValue = "") {
            const key = domain + ":" + id;
            if (this.labelMap.has(key)) {
                return this.labelMap.get(key);
            } else {
                if (this[domain]) {
                    let item = _.find(this[domain], { value: id });
                    if (item) {
                        this.labelMap.set(key, item.label);

                        return item.label;
                    }
                }
                return defaultValue;
            }
        },
        create_record(domain, value) {
            // DO THE REMOTE REQUEST, AND REFRESH THE DOMAIN (OR APPEND)

            return Http.post(`${CREATE_RECORD}/${domain}`, value).then(
                ({ data }) => {
                    this.domains[domain].push(data);

                    return data;
                }
            );
        },
        getCourses(data) {
            if (this.locationTextController) {
                this.locationTextController.abort();
            }

            this.locationTextController = new AbortController();

            return Http.post(GET_COURSES, data, {
                signal: this.locationTextController.signal,
            });
        },
        getTraining(data) {
            if (this.locationTextController) {
                this.locationTextController.abort();
            }

            this.locationTextController = new AbortController();

            return Http.post(GET_TRAINING, data, {
                signal: this.locationTextController.signal,
            });
        },
    },
});
