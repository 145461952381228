<template>
  <div class="d-flex align-items-center justify-content-center p-4">
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0">
      <form
        @submit.prevent
        v-if="!company.config.is_internal || !createPassword"
      >
        <validation-observer ref="formRules">
          <div>
            <vacancy-header
              v-if="internalVacancy"
              :item="internalVacancy"
              class="mb-6"
            />

            <div v-if="onlyAuthAllowed('ad')">
              <h3 class="text-xl font-weight-semibold">
                {{ $t("user.login.joinMessage") }}
              </h3>
              <p class="text-subdued">{{ $t("common.to_continue_auth") }}</p>
            </div>

            <div v-if="isAuthAllowed('default')">
              <h3 class="text-xl font-weight-semibold">
                {{
                  provider == "sj" && !isSJ
                    ? $t("user.login.joinSjMessage")
                    : $t("user.login.joinMessage")
                }}
              </h3>

              <div
                v-if="provider == 'sj' && !isSJ"
                class="d-flex justify-content-center align-items-center"
              >
                <div class="col-5">
                  <img
                    :src="imagesList['/resources/assets/images/sj/sj_h.png']"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>

              <p class="text-subdued">
                {{ $t("user.login.needAccount") }}?

                <a
                  v-if="provider == 'sj' && !isSJ"
                  :href="sjUrl + '/create-account'"
                  target="_blank"
                >
                  {{ $t("user.login.create_account") }}
                </a>
                <router-link v-else :to="{ name: 'register1' }">
                  {{ $t("user.login.create_account") }}
                </router-link>
              </p>

              <div class="mb-6" v-if="showCaptchaAlert">
                <div class="alert alert-danger" role="alert">
                  {{ $t("common.please_type_captcha_correctly") }}
                  Por favor, preencha o captcha corretamente.
                </div>
              </div>
              <b-alert v-if="showError" variant="danger" show>
                <div class="alert-body">
                  <span>{{ $t("user.login.invalidEmailOrPassword") }}</span>
                </div>
              </b-alert>

              <form-field-input
                name="user.login.login"
                v-model="login"
                rules="cpf|required_if:user.login.is_foreigner,false"
                mask="###.###.###-##"
                label-class="font-weight-semibold"
                inputmode="decimal"
                v-show="!is_foreigner"
              />

              <form-field-input
                name="user.login.email"
                v-model="email"
                rules="email|required_if:user.login.is_foreigner,true"
                label-class="font-weight-semibold"
                inputmode="email"
                type="email"
                v-show="is_foreigner"
              />

              <form-field-password
                name="user.login.password"
                v-model="password"
                rules="required"
                untracked
                label-class="font-weight-semibold"
              >
              </form-field-password>

              <form-field-switch
                name="user.login.is_foreigner"
                v-model="is_foreigner"
                label-class="font-weight-semibold"
                autocomplete="off"
                v-if="company.config.flags.foreigner_registration"
              />

              <div class="pb-3" v-if="showCaptcha >= 3">
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6LeRqQclAAAAAJMw7LmjmHK98_zdn9aB4ECMGJ-p"
                  @verify="captchaVerified"
                  @expired="captchaExpired"
                  @error="captchaError"
                />
              </div>

              <div class="d-flex flex-column gap-6 text-center">
                <b-button
                  type="submit"
                  variant="primary"
                  @click="submit()"
                  data-cy="login-submit"
                  pill
                  block
                >
                  {{ $t("user.login.submit") }}
                </b-button>

                <router-link :to="{ name: 'forgot-password' }">
                  {{ $t("user.login.forgotPassword") }}
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="d-flex flex-column gap-6 text-center"
            v-if="!onlyAuthAllowed('default')"
          >
            <div class="divider" v-if="!isSJ && provider != 'sj'">
              <hr class="w-100" />
              <span
                class="text-subdued px-2 d-inline-block relative bg-white"
                style="top: -29px"
                >{{ $t("common.or") }}</span
              >
            </div>
          </div>

          <b-button
            v-if="isAuthAllowed('sj') && !isSJ && provider != 'sj'"
            variant="dt-secondary"
            pill
            block
            href="/login/sj"
          >
            <span>
              <img
                width="20px"
                :src="
                  imagesList['/resources/assets/images/social/sj_small.png']
                "
                alt="SelectyJobs"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_sj") }}</span>
          </b-button>

          <b-button
            v-if="
              isAuthAllowed('ad') &&
              company.config.is_internal &&
              !isSJ &&
              provider != 'sj'
            "
            variant="dt-secondary"
            pill
            block
            :href="'/ad/redirect'"
          >
            <span>
              <img
                width="20px"
                :src="imagesList['/resources/assets/images/social/ad.svg']"
                alt="SelectyJobs"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_ad") }}</span>
          </b-button>

          <!-- <b-button

            variant="dt-secondary"
            pill
            block
            :href="ssoUrl + '/azure/redirect'"
          >
            <span>
              <img
                width="20px"
                :src="imagesList['/resources/assets/images/social/azure.png']"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_azure") }}</span>
          </b-button>           -->
          <b-button
            v-if="isAuthAllowed('linkedin') && provider != 'sj'"
            variant="dt-secondary"
            pill
            block
            href="/linkedin/redirect"
          >
            <span>
              <img
                width="20px"
                :src="imagesList['/resources/assets/images/social/lk.png']"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_linkedin") }}</span>
          </b-button>

          <b-button
            v-if="isAuthAllowed('facebook') && provider != 'sj'"
            variant="dt-secondary"
            pill
            block
            href="/facebook/redirect"
          >
            <span>
              <img
                width="20px"
                :src="imagesList['/resources/assets/images/social/fb.png']"
                alt="Facebook"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_facebook") }}</span>
          </b-button>

          <b-button
            v-if="isAuthAllowed('google') && provider != 'sj'"
            variant="dt-secondary"
            pill
            block
            href="/google/redirect"
          >
            <span>
              <img
                width="20px"
                :src="imagesList['/resources/assets/images/social/google.png']"
                alt="Google"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_google") }}</span>
          </b-button>
        </validation-observer>
      </form>
      <div v-else>
        <h5 class="text-success">{{ $t("user.forgot.email_sent") }}</h5>
        <p>{{ $t("user.forgot.email_instructions", { email }) }}</p>
        <p>{{ $t("user.forgot.email_spam_instructions") }}</p>

        <!-- <b-button
          class="btn-block border"
          variant="link"
          @click="$router.push({ name: 'forgot-account' })"
          pill
          size="sm"
        >
          {{ $t("user.forgot.no_longer_email_access") }}
        </b-button> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import images from "@mixins/images";
import companyMixin from "@state/company";
import { VueRecaptcha } from "vue-recaptcha";
import * as FormComponents from "@components/forms";
import { useVacancies } from "@state/vacancy/vacancies";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin, images],
  components: {
    VueRecaptcha,
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
    VacancyHeader,
  },
  props: {
    vacancy_id: {
      type: Number | String,
      required: false,
    },
    provider: {
      type: String,
      required: false,
      default: "candidate",
      validator: (prop) => ["candidate", "sj"].indexOf(prop) >= 0,
    },
  },
  data() {
    return {
      createPassword: false,
      internalVacancy: null,
      login: "",
      password: "",
      is_foreigner: false,
      showPassword: false,
      showError: false,
      showCaptcha: 0,
      captchaToken: true,
      showCaptchaAlert: false,
      robot: true,
    };
  },
  setup() {
    const vacancies = useVacancies();

    return {
      vacancies,
    };
  },
  mounted() {
    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }
  },
  computed: {
    authTypes() {
      if (this.$route.name == "login-teste") {
        return this.isSJ
          ? ["default", "facebook", "linkedin", "google"]
          : this.company.config.flags["auth.external.allowed"];
      } else {
        return this.isSJ
          ? ["default", "facebook", "linkedin", "google"]
          : this.company.config.flags["auth.external.allowed"];
      }
    },
    companyLogo() {
      return this.company?.config?.menu?.logo;
    },
    isSJ() {
      return this.company.config.sj;
    },
    ssoUrl() {
      return this.company.config.sso_url;
    },
    sjUrl() {
      return this.company.config.sj_url;
    },
    isSSO() {
      return (
        !_.isEmpty(this.$route.query.return_to) &&
        !_.isEmpty(this.$route.query.client_id)
      );
    },
  },
  methods: {
    onlyAuthAllowed(type) {
      return this.isAuthAllowed(type) && this.authTypes.length == 1;
    },
    isAuthAllowed(type) {
    //   console.info("isAuthAllowed", type, this.authTypes);
      return this.authTypes.includes(type);
    },
    captchaVerified(response) {
      if (response) {
        this.captchaToken = response;
        this.robot = false;
        this.showCaptchaAlert = false;
      }
    },
    captchaExpired() {
      this.showCaptchaAlert = true;
      this.recaptchaToken = "";
      this.$refs.recaptcha.reset();
    },
    captchaError() {
      this.showCaptchaAlert = true;
      this.captchaToken = false;
      this.robot = true;
    },

    submit() {
      if ((this.showCaptcha >= 3 && this.robot) || !this.captchaToken) {
        this.showCaptchaAlert = true;
        return;
      }

      this.$refs.formRules.validate().then((success) => {
        if (success) {
          // Autenticaçã via SSO
          // MUST BE selecty.jobs
          this.$root.loading = false;

          this.$auth
            .login({
              data: {
                provider:
                  this.isSJ && this.provider == "candidate"
                    ? "sj"
                    : this.provider,
                driver: "jwt",
                credentials: {
                  login: this.is_foreigner ? this.email : this.login,
                  password: this.password,
                  recaptchaToken: this.captchaToken,
                  is_foreigner: this.is_foreigner,
                  is_sj: this.company.config.sj ?? false,
                },
              },
              redirect: false,
              fetchUser: true,
              remember: this.checkbox_remember_me,
              staySignedIn: true,
            })
            .then(
              (res) => {
                // if (this.isSSO) {
                //   window.location =
                //     this.$route.query.return_to + "&ct=" + res.data.token;
                // } else {
                let redirectPath = sessionStorage.getItem("redirectPath");
                sessionStorage.removeItem("redirectPath");

                //this.$auth.fetchUser();

                if (redirectPath) {
                  this.$router.replace(redirectPath);
                } else if (this.vacancy_id) {
                  // ENROLL HERE!!
                  this.$router.push({
                    name: "vacancy-enroll",
                    params: { vacancy_id: this.vacancy_id },
                  });
                } else {
                  this.$router.push({ name: "profile" });
                }
                // }
              },
              (error) => {
                this.showCaptcha += 1;
                this.recaptchaToken = "";
                this.$refs.recaptcha.reset();
              }
            )
            .finally(() => {
              this.$root.loading = false;
            });
        } else {
        }
      });
    },
  },
};
</script>
