// en, de, fr, br
import validationMessages from "vee-validate/dist/locale/en";

export default {
    //"en-US": {
    validations: {
        messages: {
            ...validationMessages.messages,
            "email-or-cpf": "The field {_field_} is not a valid email or CPF",
            cpf: "The field {_field_} has an invalid format",
            cnpj: "The field {_field_} has an invalid format",
            "zip-code": "The field {_field_} has an invalid format",
            "full-name": "The field {_field_} is invalid",
            date: "The field {_field_} is an invalid date",
            "before-date": "The date entered is greater than the end date",
            "after-date": "The date entered is shorter than the start date",
        },
    },
    files: {
        remove: {
            done: "File removed successfully",
            fail: "Your file could not be deleted. Please try again",
        },
    },
    changeable: {
        "indicator-text":
            "Saved changes | {count} pending changes | {count} pending changes",
    },
    errors: {
        403: "Forbidden",
        404: "Not found",
        communication: {
            "not-sent":
                "We were unable to register your contact. Please try again",
        },
        title: {
            danger: "Error",
            warning: "Notice",
            info: "Information",
        },
        "default-title": "Error",

        registration: {
            message:
                "We were unable to complete your registration. Please try again.",
            "account-existent":
                "You already have an account! Log in to continue.",
        },
        curriculum: { "not-found": "Curriculum not found" },
        enroll: {
            "not-found": "Vacancy not found",
            "already-applied": "You have already applied for this vacancy",
            forbidden: "You are not allowed to apply for this vacancy",
        },
        account: {
            error: "Falha ao enviar email de reset, tente novamente.",
            "not-found":
                "Conta não encontrada, verifique se o CPF foi digitado corretamente.",
            "password-reset-throttled":
                "Um e-mail de reset foi enviado há poucos instantes, aguarde 10 minutos para tentar novamente.",
        },
    },
    common: {
        my_painel: "My Painel",
        yes: "Yes",
        no: "No",
        male: "Masculine",
        female: "Feminine",
        required: "Required field",
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        advance: "Advance",
        "no-results-found": "No result found",
        see: "See",
        or: "or",
        description: "Description",
        progress: "Progress",
        join: "Enter",
        join_with_sj: "Log in with SelectyJobs",
        join_with_facebook: "Log in with Facebook",
        join_with_linkedin: "Log in with LinkedIn",
        join_with_google: "Log in with Google",
        join_with_ad: "Log in with Active Directory",
        please_type_captcha_correctly:
            "Por favor, preencha o captcha corretamente.",
        to_continue_auth: "Para continuar, selecione uma das opções abaixo",
        register: "Register",
        create_account: "Create Account",
        create_profile: "Create Profile",
        lets_begin: "Let's get started!",
        lets_begin_subtitle: "Enter the basic information below:",
        jobs: "Vacancies",
        show: "Display",
        hide: "Hide",
        hello: "Hello",
        complete_curriculum: "Complete my curriculum",
        follow_process: "Follow up on the selection process",
        curriculum: "Curriculum",
        admissional: "Admission",
        logout: "Logout",
        begin: "Start",
        vacancy: "Vacancies",
        messages: "Messages",
        account: "Account",
        email: "Email",
        password: "Password",
        change: "Change",
        close: "Close",
        captcha_error: "Please fill in the captcha correctly.",
        search: "Search for",
        for_companies: "For companies"
    },
    passwords: {
        sent: "E-mail sent successfully.",
        user: "E-mail sent successfully.",
        token: 'vuelva a procesarlo."',
    },
    password_level: {
        very_week: "Very weak",
        week: "Weak",
        regular: "Regular",
        strong: "Very strong",
    },
    forms: {
        fields: {
            upload: "Attach file",
            allowed_extensions: "Permitted extensions",
            default_error: 'inténtelo de nuevo"',
        },
    },
    customize: {
        type: { placeholder: "Select" },
        base_theme: {
            label: "Main theme",
            placeholder: "Select the main theme",
            description: "HELP TEXT MAIN THEME",
        },
        "primary-color": {
            label: "Primary color",
            placeholder: "Select the primary color",
            description: "HELP TEXT PRIMARY COLOR",
        },
        title: {
            label: "Title",
            placeholder: "Enter the title",
            description: "Help Text Title",
        },
    },
    user: {
        login: {
            email: {
                label: "Email",
                placeholder: "",
                description: "",
            },
            is_foreigner: {
                label: "I'm a foreigner",
                placeholder: "",
                description: "",
            },
            login: {
                label: "CPF",
                placeholder: "",
                description: "",
            },
            password: {
                label: "Password",
                placeholder: "",
                description: "",
            },
            submit: "Enter",
            linked: "Login with linkedin",
            sj: "Login with SelectyJobs",
            joinMessage: "Enter to continue",
            needAccount: "Is not registered",
            create_account: "Create an account",
            forgotPassword: "I forgot my password",
            invalidEmailOrPassword: "Email and/or invalid password (s).",
            forgotUsername: "Forgot your access data?",
        },
        forgot: {
            login: {
                label: "CPF",
                placeholder: "Enter your CPF",
                description: "",
            },
            nextStep: "Next",
            title: "Forgot password?",
            info: "No problem. We will send you an e-mail with instructions to create a new password.",
            submit: "Create a new password",
            return_to: "Go back to login",
            email_sent: "Email sent!",
            email_instructions:
                "We have sent an email to <b>{login}</b> with instructions for creating a new password.",
            email_spam_instructions: 'revise su carpeta de correo no deseado."',
        },
        forgotAccount: {
            title: "Forgot your access data?",
            info: 'validaremos algunos datos para recuperar la cuenta."',
        },
        confirmAccountData: {
            title: "We found your account!",
            info: "Let's confirm some data before we recover your account.",
            cellphone:
                "Confirm below the cell phone registered in your account:",
            birth_date: "Confirm your date of birth below:",
            email: "Confirm below the e-mail address registered in your account:",
        },
        reset: {
            title: "Create a new password",
            info: "Almost there! Now all you have to do is enter a new password.",
            submit: "Change my password",
            return_to: "Go back to login",
        },
        reset_email: {
            title: "Register a new email",
            info: "Almost there! Now all you have to do is register a new e-mail address to send the password reset.",
            submit: "Change e-mail",
            return_to: "Go back to login",
        },
    },
    curriculum: {
        title: "Curriculum",
        sections: {
            personal: {
                title: "personal data",
                address: "Address Data",
                contacts: "Contact data",
                diversity: "Diversity and Inclusion",
                diversity_subtitle:
                    "We value diversity in the workplace, so you are free to provide the information below:",
                extra: "Additional fields",
                cnh: "National Driver's License",
                login: "Your login",
                bnt_ready: "All ready",
                confirm: "Confirm",
                extra_by_company: "Additional Fields {company}"
            },
            parsing: {
                title: "Attach your curriculum for automatic filling",
                btn_upload_curriculum:
                    "Fill in for me - Import Curriculum Vitae",
            },
            professional: {
                title: "Professional data",
                experiences: "Your experiences",
                objectives: "Objectives",
                experience: "Professional journey",
                add_experience: "Add your professional experiences",
            },
            education: {
                title: "Education",
                academic: "Sua Escolaridade",
                training: "Complementary training",
                languages: "Languages",
                computing: "Knowledge in Informatics",
            },
            dependents: { title: "Dependents" },
            other_information: { title: "Other information" },
            additional: { title: "Additional Information" },
            presentation: { title: "Presentation" },
        },
        actions: {
            professional: { add_experience: "Add experience" },
            education: {
                add_academic: "Add Schooling",
                add_training: "Add Training",
                add_language: "Add Language",
            },
            dependents: { add_dependent: "Add Dependent" },
        },
        texts: {
            professional: { activities: "Activities" },
            education: {
                academic: {
                    time: {
                        morning: "Morning",
                        afternoon: "Afternoon",
                        night: "Night",
                    },
                    period: { period: "{n} º Period" },
                    situation: {
                        studying: "Studying",
                        completed: "Concluded",
                        stopped: "Interrupted",
                    },
                },
                none: "None",
                basic: "Basic",
                intermediate: "Intermediary",
                advanced: "Advanced",
                fluent: "Fluent",
            },
            dependents: {
                tokens: {
                    a: "Son Valid",
                    b: "Son incapable of work",
                    c: "Spouse",
                    d: "Father",
                    e: "Mother",
                    f: "Father -in -law",
                    g: "Others",
                    h: "Grandfather (ó)",
                    i: "Companion",
                    j: "Stepson",
                    k: "Deleted",
                    l: "Former spouse",
                    m: "Sister Valid",
                    n: "Sister incapable to work",
                    o: "Former partner",
                    p: "Former parent-in-law",
                    q: "Grandson",
                    r: "Former stepson",
                },
            },
        },
        sensitive: {
            login_username: {
                label: "Email or CPF",
                placeholder: "",
                description: "",
            },
            primary_email_confirmation: {
                label: "Email confirmation",
                placeholder: "Confirm your email",
                description: "",
            },
            password: {
                label: "Password",
                placeholder: "Enter a password",
                description: "",
            },
            password_confirmation: {
                label: "Password Confirmation",
                placeholder: "Confirm the password you entered",
                description: "",
            },
        },
        personal: {
            is_foreigner: {
                label: "I'm a foreigner",
                placeholder: "",
                description: "",
            },
            primary_email: {
                label: "Email",
                placeholder: "Enter your email",
                description: "",
            },
            name: {
                label: "Full name",
                placeholder: "Enter your full name",
                description: "",
            },
            assumed_name: {
                label: "Social name",
                placeholder: "",
                description: "",
            },
            photo3x4_file_id: {
                label: "3 x 4 photo",
                placeholder: "",
                description: "",
            },
            cpf: {
                label: "CPF",
                placeholder: "Enter your CPF",
                description: "",
            },
            gender: {
                label: "Gender",
                placeholder: "Sex",
                description: "",
            },
            birth_date: {
                label: "Date of birth",
                placeholder: "dd/mm/yyyy",
                description: "",
            },
            cellphone: {
                label: "Cell phone",
                placeholder: "Cell phone",
                description: "",
            },
            home_phone: {
                label: "Residential phone",
                placeholder: "Residential phone",
                description: "",
            },
            special_needs: {
                label: "Has a disability",
                placeholder: "Select",
                description: "",
            },
            special_needs_details: {
                label: "Specify",
                placeholder: "Select",
                description: "PCD Description",
            },
            special_needs_file: {
                label: "Attach Report",
                description: "Attach Report/Rehabilitation Certificate",
                placeholder: "Annex report",
            },
            rg: { label: "ID", placeholder: "ID", description: "" },
            state_rg: {
                label: "Issuing State ID",
                placeholder: "Issuing State ID",
                description: "",
            },
            civil_status: {
                label: "Marital status",
                placeholder: "Marital status",
                description: "",
            },
            cnh_type: {
                label: "CNH Type",
                placeholder: "CNH Type",
                description: "",
            },
            children: {
                label: "Quantity of children",
                placeholder: "Quantity of children",
                description: "",
            },
            business_phone: {
                label: "Commercial phone",
                placeholder: "Commercial phone",
                description: "",
            },
            message_with: {
                label: "Contact for message",
                placeholder: "Contact for message",
                description: "",
            },
            secondary_email: {
                label: "Secondary email",
                placeholder: "Secondary email",
                description: "",
            },
            site: { label: "site", placeholder: "site", description: "" },
            avatar: {
                label: "Profile photo",
                placeholder: "",
                description: "Insert an image here",
            },
            cv_external: {
                label: "Attached curriculum",
                placeholder: "",
                description: "",
            },
            cpf_file_id: {
                label: "Attach CPF",
                placeholder: "",
                description: "",
            },
            city_rg: {
                label: "Issuing city ID",
                placeholder: "",
                description: "",
            },
            date_of_issue_rg: {
                label: "Issuing date ID",
                placeholder: "",
                description: "",
            },
            rg_file_id: {
                label: "Attach ID (front)",
                placeholder: "",
                description: "",
            },
            rg_file2_id: {
                label: "Attach ID (back)",
                placeholder: "",
                description: "",
            },
            birth_file_id: {
                label: "Attach Birth Certificate",
                placeholder: "",
                description: "",
            },
            nationality: {
                label: "Nationality",
                placeholder: "",
                description: "",
            },
            born_state: {
                label: "State of Birth",
                placeholder: "",
                description: "",
            },
            born_city: {
                label: "City of birth",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Mother's name",
                placeholder: "",
                description: "",
            },
            mother_cpf: {
                label: "Mother's CPF",
                placeholder: "",
                description: "",
            },
            father_name: {
                label: "Father's name",
                placeholder: "",
                description: "",
            },
            father_cpf: {
                label: "Father's CPF",
                placeholder: "",
                description: "",
            },
            racial: {
                label: "Race / color",
                placeholder: "",
                description: "",
            },
            national_health_service_number: {
                label: "SUS card number",
                placeholder: "",
                description: "",
            },
            national_health_service_number_file_id: {
                label: "Attach SUS Card",
                placeholder: "",
                description: "",
            },
            professional_number: {
                label: 'CRP)"',
                placeholder: "",
                description: "",
            },
            professional_file_id: {
                label: "Attach Professional Registration",
                placeholder: "",
                description: "",
            },
        },
        address: {
            country: {
                label: "Country",
                placeholder: "Select the country",
                description: "",
            },
            zip_code: {
                label: "Zip code",
                placeholder: "Enter your zip code",
                description: "",
            },
            street_type: {
                label: "Type of Address",
                placeholder: "Type of Address",
                description: "",
            },
            street: {
                label: "Public place",
                placeholder: "Public place",
                description: "",
            },
            number: {
                label: "Number",
                placeholder: "Number",
                description: "",
            },
            complement: {
                label: "Complement",
                placeholder: "Complement",
                description: "",
            },
            district: {
                label: "Neighborhood",
                placeholder: "Neighborhood",
                description: "",
            },
            state: {
                label: "state",
                placeholder: "Select a state",
                description: "",
            },
            city: {
                label: "City",
                placeholder: "Select a city",
                description: "",
            },
        },
        diversity: {
            racial: {
                label: "Color/breed",
                description: "This information is mandatory for E-social.",
                placeholder: "",
            },
            assumed_name: {
                label: "Social name",
                description: "",
                placeholder: "",
            },
            gender_identity: {
                label: "Gender identity",
                description: "",
                placeholder: "",
            },
            gender_orientation: {
                label: "Sexual orientation",
                description: "",
                placeholder: "",
            },
        },
        professional: {
            intended_occupation: {
                label: "Desired Positions",
                placeholder: "",
                description: "(max 3 levels)",
            },
            interest_levels: {
                label: "Level of interest",
                placeholder: "",
                description: "(max 3 levels)",
            },
            interest_areas: {
                label: "Area of ​​interest",
                placeholder: "",
                description: "(max 3 positions)",
            },
            salary_intended: {
                label: "Salary Expectation",
                placeholder: "Salary Expectation",
                description: "",
            },
            available_trip: {
                label: "Avaible trip?",
                placeholder: "",
                description: "",
            },
            has_professional_history: {
                label: "Do you have professional experience?",
                placeholder: "",
                description: "",
            },
            experiences: {
                company_id: {
                    label: "Company",
                    placeholder: "Company",
                    description: "",
                },
                occupation_id: {
                    label: "Position",
                    placeholder: "Position",
                    description: "",
                },
                start: {
                    label: "Start",
                    placeholder: "dd/mm/yyyy",
                    description: "",
                },
                finish: {
                    label: "Finished",
                    placeholder: "dd/mm/yyyy",
                    description: "",
                },
                last_salary: {
                    label: "Last salary",
                    placeholder: "Last salary",
                    description: "",
                },
                current_job: {
                    label: "Current Job",
                    placeholder: "Current Job",
                    description: "",
                },
                acting_area: {
                    label: "Segment",
                    placeholder: "Segment",
                    description: "",
                },
                company_size: {
                    label: "Company size",
                    placeholder: "Company size",
                    description: "",
                },
                activities: {
                    label: "Activities Performed",
                    placeholder: "Activities Performed",
                    description: "",
                },
            },
        },
        education: {
            has_schooling_level: {
                label: "Do you have an academic degree?",
                placeholder: "",
                description: "",
            },
            academic: {
                education_level_id: {
                    label: "Educational level",
                    description: "",
                    placeholder: "Select the level",
                },
                course_id: {
                    label: "Course",
                    description: "",
                    placeholder: "",
                },
                period: {
                    label: "Period",
                    description: "",
                    placeholder: "",
                },
                time: {
                    label: "Time",
                    description: "",
                    placeholder: "",
                },
                institution: {
                    label: "Institution",
                    description: "",
                    placeholder: "Institution",
                },
                status: {
                    label: "Situation",
                    description: "",
                    placeholder: "Select the situation",
                },
                start_date: {
                    label: "Start date",
                    placeholder: "",
                    description: "",
                },
                conclusion_date: {
                    label: "Finish date",
                    placeholder: "",
                    description: "",
                },
            },
            training: {
                additional_training_id: {
                    label: "Training",
                    placeholder: "Training",
                    description: "",
                },
                description: {
                    label: "Activity",
                    placeholder: "Activity",
                    description: "",
                },
                institution: {
                    label: "Institution",
                    placeholder: "Institution",
                    description: "",
                },
                status: {
                    label: "Situation",
                    placeholder: "Situation",
                    description: "",
                },
                start_date: {
                    label: "Period (start)",
                    placeholder: "Period (start)",
                    description: "",
                },
                finish_date: {
                    label: "Period (finish)",
                    placeholder: "Period (finish)",
                    description: "",
                },
            },
            languages: {
                language_id: {
                    label: "Language",
                    placeholder: "Language",
                    description: "",
                },
                speaking: {
                    label: "Speak",
                    placeholder: "Speak",
                    description: "",
                },
                writing: {
                    label: "Writing",
                    placeholder: "Writing",
                    description: "",
                },
                understanding: {
                    label: "Understanding",
                    placeholder: "Understanding",
                    description: "",
                },
                reading: {
                    label: "Reading",
                    placeholder: "Reading",
                    description: "",
                },
            },
        },
        computing: {
            title: "Computing",
            label: "Saddasdasdasdsdsa",
            basic: {
                label: "Basic",
                placeholder: "Basic",
                description: "",
            },
            intermediate: {
                label: "Intermediary",
                placeholder: "Intermediary",
                description: "",
            },
            advanced: {
                label: "Advanced",
                placeholder: "Advanced",
                description: "",
            },
        },
        additional: {
            abroad_experience: {
                label: "Experience abroad",
                placeholder: "Experience abroad",
                description: "",
            },
            has_abroad_experience: {
                label: "Has experience abroad",
                placeholder: "Has experience abroad",
                description: "",
            },
            description: {
                label: "Description",
                placeholder: "Description",
                description: "",
            },
            complementary_activities: {
                label: "Additional activities",
                placeholder: "Additional activities",
                description: "",
            },
            sports: {
                label: "sports",
                placeholder: "sports",
                description: "",
            },
            hobby: {
                label: "Hobbies",
                placeholder: "Hobbies",
                description: "",
            },
            music: {
                label: "Music",
                placeholder: "Music",
                description: "",
            },
            passions: {
                label: "Passions",
                placeholder: "Passions",
                description: "",
            },
            books: {
                label: "Books",
                placeholder: "Books",
                description: "",
            },
            social_work: {
                label: "Social work",
                placeholder: "Social work",
                description: "",
            },
            extra_informations: {
                label: "Additional Information",
                placeholder: "Additional Information",
                description: "",
            },
            cover_letter: {
                label: "Cover letter",
                placeholder: "Cover letter",
                description: "",
            },
        },
        ctps: {
            has_ctps: {
                label: "Do you have a work permit?",
                placeholder: "",
                description: "",
            },
            is_first_job: {
                label: "Will it be your first formal job?",
                placeholder: "",
                description: "",
            },
            ctps_type: {
                label: "Type of work permit?",
                placeholder: "",
                description: "",
            },
            ctps_number: {
                label: "CTPS Number",
                placeholder: "",
                description: "",
            },
            ctps_serial: {
                label: "CTPS Series",
                placeholder: "",
                description: "",
            },
            ctps_state: {
                label: "CTPS State",
                placeholder: "",
                description: "",
            },
            ctps_city: {
                label: "CTPS City",
                placeholder: "",
                description: "",
            },
            ctps_date: {
                label: "CTPS Issuance Date",
                placeholder: "",
                description: "",
            },
            ctps_file_id: {
                label: "Attach CTPS (front)",
                placeholder: "",
                description: "",
            },
            ctps_file2_id: {
                label: "Attach CTPS (Back)",
                placeholder: "",
                description: "",
            },
            ctps_file3_id: {
                label: "Attach Last CTPS Registration",
                placeholder: "",
                description: "",
            },
            has_pis: {
                label: "Do you have PIS?",
                placeholder: "",
                description: "",
            },
            pis_pasep_number: {
                label: "PIS/PASEP Number",
                placeholder: "",
                description: "",
            },
            pis_pasep_date: {
                label: "PIS/Pasep Date",
                placeholder: "",
                description: "",
            },
            pis_file_id: {
                label: "Attach PIS",
                placeholder: "",
                description: "",
            },
        },
        voter: {
            has_voter_registration: {
                label: "Do you have a Voter ID?",
                placeholder: "",
                description: "",
            },
            voter_registration_number: {
                label: "Number",
                placeholder: "",
                description: "",
            },
            voter_registration_zone: {
                label: "Zone",
                placeholder: "",
                description: "",
            },
            voter_registration_section: {
                label: "Section",
                placeholder: "",
                description: "",
            },
            voter_registration_state: {
                label: "state",
                placeholder: "",
                description: "",
            },
            voter_registration_city: {
                label: "City",
                placeholder: "",
                description: "",
            },
            voter_registration_date: {
                label: "Date of issue",
                placeholder: "",
                description: "",
            },
            voter_registration_file_id: {
                label: "Attach Voter ID (Front)",
                placeholder: "",
                description: "",
            },
            voter_registration_file2_id: {
                label: "Attach Voter ID (Back)",
                placeholder: "",
                description: "",
            },
        },
        armed_forces: {
            has_certificate_armed_forces: {
                label: "Do you have a Reservist Certificate?",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_status: {
                label: "Situation",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service: {
                label: "Ra number",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_serie: {
                label: "Series",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_organ: {
                label: "Organ",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_uf: {
                label: "federative unit",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_type: {
                label: "Type",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_category: {
                label: "Category",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_date: {
                label: "Date of issue",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file_id: {
                label: "Attach reservist certificate (front)",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file2_id: {
                label: "Attach Reservist Certificate (Back)",
                placeholder: "",
                description: "",
            },
        },
        cnh: {
            has_cnh: {
                label: "Do you have a driver's license?",
                placeholder: "",
                description: "",
            },
            cnh_number: {
                label: "Number",
                placeholder: "",
                description: "",
            },
            cnh_local: {
                label: "Place",
                placeholder: "",
                description: "",
            },
            cnh_organ: {
                label: "Issuing Agency",
                placeholder: "",
                description: "",
            },
            cnh_state: {
                label: "Emitter",
                placeholder: "",
                description: "",
            },
            cnh_type: {
                label: "CNH Type",
                placeholder: "",
                description: "",
            },
            cnh_date_emission: {
                label: "Date of issue",
                placeholder: "",
                description: "",
            },
            cnh_date_validity: {
                label: "Expiration date",
                placeholder: "",
                description: "",
            },
            cnh_date_first_emission: {
                label: "First issue",
                placeholder: "",
                description: "",
            },
            cnh_file_id: {
                label: "Attach CNH (front)",
                placeholder: "",
                description: "",
            },
            cnh_file_2_id: {
                label: "Attach CNH (Back)",
                placeholder: "",
                description: "",
            },
        },
        bank: {
            has_bank: {
                label: "Do you have a Bank Account in your name?",
                placeholder: "",
                description: "",
            },
            bank_name: {
                label: "Bank name",
                placeholder: "",
                description: "",
            },
            bank_type: {
                label: "Account Type",
                placeholder: "",
                description: "",
            },
            bank_holder_name: {
                label: "Name of Holder",
                placeholder: "",
                description: "",
            },
            bank_holder_type: {
                label: "Type of Holder",
                placeholder: "",
                description: "",
            },
            bank_agency: {
                label: "Agency",
                placeholder: "",
                description: "",
            },
            bank_operation: {
                label: "Bank operation",
                placeholder: "",
                description: "",
            },
            account_file_id: {
                label: "Attach Proof of Bank Account (Front)",
                placeholder: "",
                description: "",
            },
            account_file2_id: {
                label: "Attach Proof of Bank Account (back)",
                placeholder: "",
                description: "",
            },
            bank_pix_type: {
                label: "Pix Key Type",
                placeholder: "",
                description: "",
            },
            bank_pix_value: {
                label: "PIX Key",
                placeholder: "",
                description: "",
            },
        },
        dependents: {
            name: {
                label: "Full name",
                placeholder: "",
                description: "",
            },
            birth_date: {
                label: "Date of birth",
                placeholder: "",
                description: "",
            },
            gender: { label: "Sex", placeholder: "", description: "" },
            civil_status: {
                label: "Marital status",
                placeholder: "",
                description: "",
            },
            kinship: {
                label: "Degree of kinship",
                placeholder: "",
                description: "",
            },
            rg: { label: "ID", placeholder: "", description: "" },
            cpf: { label: "CPF", placeholder: "", description: "" },
            vaccine_number: {
                label: "Vaccination Booklet",
                placeholder: "",
                description: "",
            },
            sus_card: {
                label: "SUS Card Number",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Mother's name",
                placeholder: "",
                description: "",
            },
            income_tax: {
                label: "Do you want to declare it on your income tax?",
                placeholder: "",
                description: "",
            },
            life_insurance: {
                label: "Include this dependent as a beneficiary of your life insurance?",
                placeholder: "",
                description: "",
            },
            health_care: {
                label: "Include this dependent in the Health Care plan?",
                placeholder: "",
                description: "",
            },
            file_1_id: {
                label: "Attach Birth Certificate (Front)",
                placeholder: "",
                description: "",
            },
            file_2_id: {
                label: "Attach Birth Certificate (Back)",
                placeholder: "",
                description: "",
            },
            file_3_id: {
                label: "Attach CPF from the dependent",
                placeholder: "",
                description: "",
            },
            file_4_id: {
                label: "Attach School Enrollment Statement",
                placeholder: "",
                description: "",
            },
            file_5_id: {
                label: "Attach Proof of University Enrollment",
                placeholder: "",
                description: "",
            },
            file_6_id: {
                label: "Attach Declaration of Economic Dependence Registered in Notary's Office",
                placeholder: "",
                description: "",
            },
            file_7_id: {
                label: "Attach Term of Guardianship",
                placeholder: "",
                description: "",
            },
            file_8_id: {
                label: "Attach Vaccination Card (Front)",
                placeholder: "",
                description: "",
            },
            file_9_id: {
                label: "Attach SUS Card",
                placeholder: "",
                description: "",
            },
            file_10_id: {
                label: "Attach Vaccination Card (back)",
                placeholder: "",
                description: "",
            },
            file_11_id: {
                label: "Attach ID",
                placeholder: "",
                description: "",
            },
            file_12_id: {
                label: "Attach marriage certificate",
                placeholder: "",
                description: "",
            },
            file_13_id: {
                label: "Attach PCD Report",
                placeholder: "",
                description: "",
            },
            file_14_id: {
                label: "Attach Academic Transcrip",
                placeholder: "",
                description: "",
            },
        },
        epi: {
            tshirt: {
                label: "T-shirt",
                placeholder: "",
                description: "",
            },
            trousers: {
                label: "Pants",
                placeholder: "",
                description: "",
            },
            shoe: { label: "Shoe", placeholder: "", description: "" },
            jacket: {
                label: "Jacket",
                placeholder: "",
                description: "",
            },
            shirt: { label: "Shirt", placeholder: "", description: "" },
        },
        benefits: {
            benefits: {
                label: "Select the benefits you wish to receive",
                placeholder: "",
                description: "",
            },
        },
        cnpj: {
            has_cnpj: {
                label: "Do you have a Legal Entity?",
                placeholder: "",
                description: "",
            },
            cnpj_name: {
                label: "Corporate Name",
                placeholder: "",
                description: "",
            },
            cnpj_number: {
                label: "CNPJ",
                placeholder: "",
                description: "",
            },
        },
        contacts: {
            contact_name1: {
                label: "Name 1",
                placeholder: "",
                description: "",
            },
            contact_phone1: {
                label: "Phone 1",
                placeholder: "",
                description: "",
            },
            contact_name2: {
                label: "Name 2",
                placeholder: "",
                description: "",
            },
            contact_phone2: {
                label: "Phone 2",
                placeholder: "",
                description: "",
            },
        },
        forms: {
            transport_form_file_id: {
                label: "cAttach Transportation Allowance Card",
                placeholder: "",
                description: "",
            },
            transport_file_id: {
                label: "Copy of the Transport Allowance Card",
                placeholder: "",
                description: "",
            },
            chartered_file_id: {
                label: "Charter Form",
                placeholder: "",
                description: "",
            },
            salary_family_file_id: {
                label: "Family Salary Form",
                placeholder: "",
                description: "",
            },
            impost_file_id: {
                label: "Attach Income Tax Form",
                placeholder: "",
                description: "",
            },
            has_contribution_sindical: {
                label: "Do you want to make Union Contribution?",
                placeholder: "",
                description: "",
            },
            syndicate_contribution_file_id: {
                label: "Attach Declaration of Adherence to Union Contribution",
                placeholder: "",
                description: "",
            },
            vaccine_file_id: {
                label: "Attach Other",
                placeholder: "",
                description: "",
            },
            unemployment_insurance_file_id: {
                label: "Attach Proof of Unemployment Insurance",
                placeholder: "",
                description: "",
            },
            contribution_inss_file_id: {
                label: "Attach proof contribution INSS",
                placeholder: "",
                description: "",
            },
            other_file_id: {
                label: "Attach Other",
                placeholder: "",
                description: "",
            },
            other_file2_id: {
                label: "Attach Other",
                placeholder: "",
                description: "",
            },
            other_file3_id: {
                label: "Attach",
                placeholder: "",
                description: "",
            },
            other_file4_id: {
                label: "Attach",
                placeholder: "",
                description: "",
            },
        },
    },
    admissional: {
        title: "Admission form",
        sections: {
            personal: { title: "Basic Data", address: "Address Data" },
            ctps: { title: "Work permit" },
            voter: { title: "Voter title" },
            armed_forces: { title: "Reservist Certificate" },
            cnh: { title: "Driver's license" },
            bank: {
                title: "Bank data",
                generate_bank_letter:
                    "Generate your letter to open a bank account here",
            },
            dependents: { title: "Dependents" },
            epi: { title: "PPE/Uniform" },
            benefits: { title: "Benefits Selection" },
            cnpj: { title: "Legal Entity Data" },
            contacts: { title: "Emergency Contacts" },
            forms: { title: "Other documents" },
        },
    },
    vacancy: {
        filters: {
            text: {
                label: "",
                placeholder: "Search for keywords like job title or company",
                description: "",
            },
            location: {
                label: "",
                placeholder: "Location",
                description: "",
            },
            interval: {
                label: "",
                placeholder: "Publication date",
                description: "",
                tokens: {
                    anyTime: "All",
                    lastDay: "Last 24 hours",
                    lastThreeDays: "Last 3 days",
                    lastWeek: "Last week",
                    lastMonth: "Last 30 days",
                },
            },
            positions: {
                label: "",
                placeholder: "",
                description: "",
                tokens: {
                    all: "All",
                    oneOrMore: "More than 1 vacancy",
                    fiveOrMore: "More than 5 vacancies",
                    TenOrMore: "More than 10 vacancies",
                },
            },
            contract_type: {
                label: "",
                placeholder: "Type of contract",
                description: "",
                tokens: {
                    all: "All",
                    autonomous: "Autonomous",
                    clt: "CLT (effective)",
                    freeLance: "Free-lancer",
                    juridicPerson: "Service Provider (PJ)",
                    temporary: "Temporary",
                    trainee: "Trainee",
                    internship: "Internship",
                    apprentice: "Minor apprentice",
                    diarist: "Diarist",
                    cooperated: "Cooperative",
                    outsourced: "Outsourced",
                },
            },
            workplace_type: {
                label: "",
                placeholder: "All job types",
                description: "",
                tokens: {
                    all: "All",
                    presential: "Presencial",
                    hybrid: "Hybrid",
                    homeOffice: "Home office",
                },
            },
            results:
                "No results found | {count} job found | {count} jobs found",
        },
        actions: {
            share: "Share",
            apply: "Apply",
            "already-applied": "Applied",
            "see-vacancy": "See vacancy",
            seeAllVacancies: "See all vacancies",
            copy: "Copy",
            save: "Save",
            favorite: "Favorite vacancy",
            unfavorite: "Unfavorite vacancy",
            //favorite_success: "Vaga favoritada com sucesso!",
            //please_login_to_favorite: "Faça o login para favoritar a vaga!",
            //favorite_failure: "Falha ao favoritar a vaga!",
            //none_favorite_vacancy: "Sem vagas favoritas",
            //almost_there: "Quase lá!",
            //needed_information_to_complete: "As informações abaixo são necessárias para completar a sua candidatura.",
            //keep_looking_vacancies: "Dê uma olhada em mais oportunidades e adicione as que mais combinam com seu perfil à sua lista de favoritos.",
            save_vacancy: "Save vacancy",
            //log_to_save: "Entre para poder salvar sua vaga"
        },
        texts: { pcd: { info: "Vacancies for people with disabilities" } },
        shareJob: "Share this vacancy",
        codVacancy: "Code. vacancy:",
        jobsQuantity: "Number of vacancies:",
        contract_type: "Type of hiring:",
        interest_area: "Profissional area :",
        workload: "Workload:",
        work_schedule: "Working hours:",
        salary: "Salary:",
        copyLink: "Or copy the link",
        location: "Location:",
        company: "Company",
        pcd: "PCD vacancy?",
        workplace_type: "Workplace type: ",
        pcdInfo: "PCD Types:",
        search: "Search",
        lastDay: "Last 24 hours",
        lastThreeDays: "Last 3 days",
        lastWeek: "Last week",
        lastMonth: "Last 30 days",
        anyTime: "Anytime",
        moreThan: "more of",
        anyJobsQuantity: "Any amount of vacancies",
        cleanFilters: "Clean filters",
        jobs: "vacancies",
        share: "To share",
        apply: "Apply",
        about: "About Us",
        vacancy_info: "Vacancy Info",
        responsabilities: "Responsibilities and attributions",
        requirements: "Requirements and Qualifications",
        desirable: "Desirable",
        benefits: "Benefits",
        additional_info: "Additional Information",
        keep_searching:
            "Keep looking and experiment with other terms and filters to find the job that's right for you.",
        empty_vacancy: "No job found",
        published_at: " Published at  ",
    },
    home: {
        sections: { vacancies: { title: "Featured Jobs" } },
        resume: "Curriculum",
        "active-vacancies":
            "No vacancies | 1 active vacancy | {count} active vacancies",
        "pending-items": "No pending | 1 pending | {count} pending",
        "unread-messages": "1 not read | {count} not read",
        completeness: "filled",
        messages: "Messages",
        welcome_msg: "Welcome to Home",
        hi: "Hello",
        follow: "follow",
        currentJobs: "You are participating in",
        jobs: "vacancies",
        profileJobs: "Vacancies that fit your profile",
        appointments: "Commitments",
        curriculum: {
            empty: "Your curriculum is a little empty",
            half: "You are just a few steps away from completing your curriculum",
            full: "Your curriculum is complete",
            complete: "Complete your curriculum",
            review: "Revise curriculum",
            follow: "Follow",
        },
    },
    registration: {
        placeholders: { "email-or-cpf": "" },
        congratulations_text: "How nice that you found an opportunity!",
        account_not_found: '¿le gustaría registrarse?"',
        candidacy_start: "Let's start your application",
        create_mini_curriculum:
            "Fill out your mini curriculum vitae to continue your application",
        hasAccount: "Do you already have an account?",
        login: "Enter here",
        fullName: "Full name",
        typeFullName: "Enter your full name",
        createPassword: "Create a password",
        lowerLetter: "A lower case letter",
        upperLetter: "An uppercase letter",
        number: "A number",
        specialChar: "A special character",
        eightChars: "8 characters at least",
        agree_with: "By clicking start, you accept",
        as: "the",
        specific_policies: "Specific Policies",
        from_company: "from company",
        os: "the",
        terms_and_conditions: "Terms and conditions",
        and_the: "and the",
        privacy_policy: "Privacy Policy",
        from_selecty_platform: "from the Selecty platform",
        continue: "Continue",
        start: "Start",
        createAccount: "Accept and register",
        confirmPassword: "Confirm your password",
    },
    messages: {
        search: "Search Messages",
        noMessagesFound: "Your mailbox is empty",
        noSubject: "(no subject)",
        earlierMessages: "Previous messages",
        all: "All",
        read: "Read",
        unread: "Unread",
        reply: "Reply",
        reply_message: "Type your reply message and click reply",
    },
    chat: { startConversation: "Start conversation", send: "Send" },
    config: {
        title: "Account configurations",
        shortTitle: "Account",
        notificationsTitle: "Notifications",
        password: {
            changePassword: "Change Password",
            savePassword: "Save password",
            actualPassword: "current password",
            informActualPassword: "Enter your current password",
            newPassword: "New Password",
            informNewPassword: "Enter a new password",
            confirmPassword: "Password Confirmation",
            informConfirmPassword: "Confirm your new password",
        },
        email: {
            changeEmail: "Change your email",
            saveEmail: "Save email",
            newEmail: "New email",
            informNewEmail: "Enter the new email",
            confirmeNewEmail: "Confirm the new email",
        },
        notifications: {
            push: {
                title: "Push Notifications",
                description:
                    'mensajes y progreso de los procesos de selección."',
            },
            email: {
                title: "E-mail Notifications",
                description:
                    'mensajes y progreso de los procesos de selección."',
            },
            whatsapp: {
                title: "Whatsapp Notifications",
                description:
                    'mensajes y progreso de los procesos de selección."',
            },
        },
        dataPrivacy: {
            title: "Data privacy",
            description1: "You can also",
            description2: "exercise all the rights of the holders",
            description3:
                "foreseen in the General Law of Data Protection - LGPD.",
            seePrivacyPolicy: "See privacy policy",
        },
        cancelAccount: {
            title: "Account Cancellation",
            btnCancelAccount: "Cancel account",
            description1: "Account cancellation is a process",
            description2: "irreversible",
            description3: "which will erase your data from the system",
            description4:
                "The cancellation and removal of all your data will be carried out within 7 working days and you will receive confirmation in your e-mail.",
        },
    },
    application: {
        title: "My vacancies",
        progress: "Vacancy progress",
        jobDetail: "See vacancy details",
        applicationStep: "You are at the application step",
        completeSteps: "Complete the steps below to proceed",
        candidacy_complete:
            "Supplement your curriculum to increase the chances of being found.",
        candidacy_confirmed: "Application confirmed!",
        start: "Start",
        startInfo: 'debe completar el paso anterior"',
        nextStep: "Next step",
        completeMessage: 'pasará a la siguiente fase del proceso de selección"',
        myApplications: "My vacancies",
        curriculumStep: "Curriculum filling",
        quit: "To give up",
        seeProgress: "See progress",
        noVacancyEnrolled: "You are not participating in any selection process",
        searchForVacancies: "Search for vacancies",
    },
    lgpd: {
        title: "Personal Data Holder Rights Request Form",
        subtitle:
            "para facilitar el ejercicio de sus derechos previstos en la Ley de Datos Personal General (LGPD). Recuerde que usar este formulario es opcional",
        btn_finish: "Finish",
        declaration_of_authenticity: {
            label: "Declaration of Authenticity of the information provided",
            placeholder: "",
            description: "bajo la pena de derecho penal y",
        },
        data_holder: {
            label: "Are you the data subject or proxy/representative?",
            placeholder: "",
            description: "",
        },
        sections: {
            personal_data_holder: {
                title: "Qualifications of the personal data holder",
                subtitle:
                    "Fill in the spaces below with the qualifications of the holder of personal data.",
            },
            identity_comprovation: {
                title: "Proof of Identity of the Personal Data Holder",
                subtitle: "pasaporte",
            },
            data_holder_rights: {
                title: "Personal Data Subject Rights: Which rights would you like to exercise?",
                subtitle:
                    '"Select ""Yes"" only for those that you wish to exercise at this time."',
            },
            data_holder_rights_actions: {
                title: "Holding Rights",
                subtitle: "",
            },
            data_request: {
                title: "le solicitamos que nos facilite la información solicitada con el mayor detalle posible. Especifique aquí",
                subtitle: "",
            },
            result_request: {
                title: "Communication of the Result of the Request",
                subtitle: "",
            },
            legal_agent_qualifications: {
                title: "Qualifications of the Attorney or Legal Representative of the Personal Data Holder (if applicable)",
                subtitle:
                    "Fill in the spaces below with the qualifications of the attorney or legal representative of the holder of personal data (if applicable).",
            },
            comprovation_power_grant: {
                title: "Proof of grant or delegation of powers to third parties (if applicable) or without title",
                subtitle:
                    "así como al instrumento legal de representación o prueba de representación de los padres. Los documentos de identidad civil serán aceptados como identificación",
            },
        },
        data_holder_name: {
            label: "Full name of the holder of personal data",
            placeholder: "",
            description: "",
        },
        data_holder_cpf: {
            label: "CPF of the Personal Data Holder",
            placeholder: "",
            description: "",
        },
        data_holder_phone: {
            label: "Contact telephone of the holder of personal data",
            placeholder: "",
            description: "",
        },
        data_holder_email: {
            label: "Contact email of the holder of personal data",
            placeholder: "",
            description: "",
        },
        data_holder_category: {
            label: "Personal data subject category",
            placeholder: "",
            description: "",
        },
        data_holder_additional_info: {
            label: "Additional Information",
            placeholder: "",
            description:
                "Any additional information that may help us locate your personal data",
        },
        data_holder_document: {
            label: "Attach civil identity document",
            placeholder: "",
            description: 'RG o CNH"',
        },
        confirm_treatment_data: {
            label: "Do you want to confirm the existence of processing of your personal data?",
            placeholder: "",
            description:
                "incluso si no está seguro de si ha recibido alguna relación previa con nuestra empresa. Art.18",
        },
        confirm_access_data: {
            label: "Would you like to have access to your data?",
            placeholder: "",
            description: "II",
        },
        hide_data: {
            label: 'anonimizar o bloquear tus datos?"',
            placeholder: "",
            description:
                "el bloqueo o la eliminación de datos innecesarios y excesivos tratados en falta de comisión con LGPD o proporcionados por consentimiento. Art.18",
        },
        exercise_rights: {
            label: '¿qué derechos desea ejercer?"',
            placeholder: "",
            description: "",
        },
        data_request: {
            label: "nos gustaría que nos facilitara horas concretas",
            placeholder: "",
            description: "",
        },
        result_request: {
            label: "How would you like to be communicated about the outcome of your request?",
            placeholder: "",
            description: "",
        },
        legal_agent_name: {
            label: "Full name of the proxy or legal representative",
            placeholder: "",
            description: "",
        },
        legal_agent_cpf: {
            label: "CPF of the proxy or legal representative",
            placeholder: "",
            description: "",
        },
        legal_agent_address: {
            label: "Address of the proxy or legal representative",
            placeholder: "",
            description: "número",
        },
        legal_agent_phone: {
            label: "Phone number of the proxy or legal representative",
            placeholder: "",
            description: "Telephone with area code in the format XX XXXXX-XXXX",
        },
        legal_agent_email: {
            label: "Contact e-mail of the proxy or legal representative",
            placeholder: "",
            description: "",
        },
        legal_agent_document: {
            label: "Proof of identity of the proxy or legal representative of the data subject",
            placeholder: "",
            description: "",
        },
        legal_agent_representative_document: {
            label: "Legal representation instrument or proof of parental representation",
            placeholder: "",
            description: "",
        },
    },
    disc: {
        profile: {
            achiever: "Director",
            agent: "Representative",
            appraiser: "Evaluator",
            counselor: "Counselor",
            creative: "Creative",
            developer: "Pioneer",
            inspirational: "Inspiring",
            investigator: "Investigator",
            "objective thinker": "Objective thinker",
            overshift: "Expanded",
            perfectionist: "Perfectionist",
            persuader: "Convincing",
            pratitioner: "Practitioner",
            promoter: "Promoter",
            "result-oriented": "Results-Oriented",
            specialist: "Specialist",
            tight: "tight",
            undershift: "Compressed",
        },
        question: {
            I_am: "I am ...",
            More: "More",
            Less: "Less",
            Enthuastic: "Enthuastic",
            Daring: "Daring",
            Diplomatic: "Diplomatic",
            Satisfied: "Satisfied",
            Cautious: "Cautious",
            Determined: "Determined",
            Convicing: "Convicing",
            "Good Natured": "Good Natured",
            Friendly: "Friendly",
            Accurate: "Accurate",
            Outspoken: "Outspoken",
            Calm: "Calm",
            Talkative: "Talkative",
            Controlled: "Controlled",
            Conventional: "Conventional",
            Decisive: "Decisive",
            Adventurous: "Adventurous",
            Insightful: "Insightful",
            "Out-going": "Out-going",
            Moderate: "Moderate",
            Gentle: "Gentle",
            Persuasive: "Persuasive",
            Humble: "Humble",
            Original: "Original",
            Expressive: "Expressive",
            Conscientious: "Conscientious",
            Dominant: "Dominant",
            Responsive: "Responsive",
            Poised: "Poised",
            Observant: "Observant",
            Modest: "Modest",
            Inpatient: "Inpatient",
            Tactful: "Tactful",
            Agreeable: "Agreeable",
            Magnetic: "Magnetic",
            Insistent: "Insistent",
            Brave: "Brave",
            Inspiring: "Inspiring",
            Submissive: "Submissive",
            Timid: "Timid",
            Reserved: "Reserved",
            Obliging: "Obliging",
            "Strong-willed": "Strong-willed",
            Cheerful: "Cheerful",
            Stimulating: "Stimulating",
            Kind: "Kind",
            Perceptive: "Perceptive",
            Independent: "Independent",
            Competitive: "Competitive",
            Considerate: "Considerate",
            Joyful: "Joyful",
            Private: "Private",
            Fussy: "Fussy",
            Obedient: "Obedient",
            Firm: "Firm",
            Playful: "Playful",
            Attractive: "Attractive",
            Instropective: "Instropective",
            Stubborn: "Stubborn",
            Predictable: "Predictable",
            Logical: "Logical",
            Bold: "Bold",
            Loyal: "Loyal",
            Charming: "Charming",
            Sociable: "Sociable",
            Patient: "Patient",
            "Self-reliant": "Self-reliant",
            "Soft spoken": "Soft spoken",
            Wiling: "Wiling",
            Eager: "Eager",
            Thorough: "Thorough",
            "High-spirited": "High-spirited",
            Aggressive: "Aggressive",
            Extroverted: "Extroverted",
            Amiable: "Amiable",
            Fearful: "Fearful",
            Confident: "Confident",
            Sympathetic: "Sympathetic",
            Impartial: "Impartial",
            Assertive: "Assertive",
            "Well-desciplined": "Well-desciplined",
            Generous: "Generous",
            Animated: "Animated",
            Persistent: "Persistent",
            Impulsive: "Impulsive",
            Introverted: "Introverted",
            Forceful: "Forceful",
            "Easy-going": "Easy-going",
            "Good mixer": "Good mixer",
            Refined: "Refined",
            Vigorous: "Vigorous",
            Lenient: "Lenient",
            Captivating: "Captivating",
            Contented: "Contented",
            Demanding: "Demanding",
            Compliant: "Compliant",
            Argumentative: "Argumentative",
            Systematic: "Systematic",
            Cooperative: "Cooperative",
            "Light-hearted": "Light-hearted",
            Jovial: "Jovial",
            Precise: "Precise",
            Direct: "Direct",
            "Even-tempered": "Even-tempered",
            Restless: "Restless",
            Neighborly: "Neighborly",
            Appealing: "Appealing",
            Careful: "Careful",
            Respectful: "Respectful",
            Pioneering: "Pioneering",
            Optimistic: "Optimistic",
            Helpful: "Helpful",
        },
        form: {
            welcome: "Welcome to your evaluation.",
            instructions: "Please click continue and follow the instructions.",
            hello_user: "Hello {0}",
            lets_try_again: "Start again",
            warning_recording_again: 'intente nuevamente"',
            "time-to-response": "for response",
            minutes: "{0} min.",
            seconds: "{0} sec",
            "testing-your-camera": "Testing your camera",
            "i-can-see-me-and-the-quality-is-good":
                "I see myself and the quality is good.",
            "testing-your-audio": "Testing your audio",
            "i-can-hear-me-and-the-quality-is-good":
                "I hear and the quality is good.",
            congratulations: "Congratulations {0}",
            "you-finished": "You completed the questionnaire",
            "wait-return": "Wait for the selector's return",
            "to-start": "to begin",
            "answer-now": "Answer now",
            start: "Start",
            continue: "Continue",
            "ok-already-answered": 'he respondido"',
            "all-working": "All working",
            "have-a-good-interview": "Have a good interview",
            question: "Question",
            of: "in",
            disc_behavioural_profile: "DISC Behavioural Profile",
            "you-are-here-to-discover-your-behavioral-disc-profile":
                "You are here to discover your behavioral DiSC profile",
            "what-is-it-for": "What is it for?",
            "your-behavioral-profile-will-help-you-understand-how you-work-on-a-team-and-even-in-life-in-general":
                "Your behavioral profile will help you understand how you behave on a team and even in life in general",
            "lets-begin": "Let's begin",
            "there-will-be-28-quick-questions":
                "There will be 28 quick questions",
            "see-more-instructions-next": "See more instructions below",
            "skip-and-start-the-test": "Skip and start the test",
            "youve-completed-more-than-half-of-the-test":
                "You've completed more than half of the test",
            "How_was_the_test?": "How was the test?",
            thanks_for_your_feedback: "Thanks for your feedback!",
            we_will_do_our_best_to_improve_your_user_experience:
                "We will do our best to improve your user experience",
            understood: "Understood",
            start: "Start",
            disc_invalid_profile_message:
                "Based on the answers provided, it was not possible to fit their behavioral profile into the standards established by the DISC methodology.",
            remake_evaluation:
                "We need you to redo the assessment so that your profile is identified and you can advance in this Recruitment and Selection process in which you are applying.",
            disc_denied_message_intro:
                "For now, it is not necessary to perform your DISC again",
            disc_denied_message_body:
                "You have completed the DISC behavioral inventory in the last 6 months and your last result is still valid.",
            disc_denied_message_conclusion:
                "This measure is adopted to ensure the consistency and transparency of our process. We really appreciate your understanding!",
            execution_date: "Execution date",
            valid_until: "Valid until",
        },
    },
    evaluation: {
        expired: {
            title: "Prazo Expirado",
            description:
                "Infelizmente o prazo para responder o teste já venceu",
        },
        form: {
            attention: "Attention",
            forward_without_answering:
                "Do you want to go forward without replying?",
            not_possible_return:
                "It will not be possible to go back on this issue",
            "choose-a-quiet-and-well-lit-place":
                "Choose a quiet and well-lit place",
            "if-possible-use-wifi-or-broadband-connection":
                "If possible, use wifi or broadband connection",
            "so-you-can-focus-on-what-really-matter":
                "So you can focus on what really matter",
            "this-way-you-avoid-problems-with-slowness-during-the-interview":
                "This way you avoid problems with slowness during the interview",
            "got-it": "Got it",
            start: "Start",
            "browser-not-supported": "Browser not supported",
            "please-use-one-of-the-browsers-below":
                "Please, use one of the browsers below:",
        },
    },
    "test-alerts": {
        "test-cam": "Test video",
        "test-mic": "Test mic",
    },
    styleguide: {
        spinbutton: {
            field: {
                label: "Form Field Spinbutton",
                placeholder: "",
                description: "Use the fields below to adjust the component",
            },
            step: { label: "Step", placeholder: "", description: "" },
            min: { label: "Min", placeholder: "", description: "" },
            max: { label: "Max", placeholder: "", description: "" },
        },
    },
    //},
};
