<template>
  <div
    class="d-flex align-items-center justify-content-center p-4"
    v-if="!isLoading"
  >
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0" variant="primary">
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="curriculumStep2Rules">
          <div class="d-flex flex-column gap-4">
            <vacancy-header v-if="internalVacancy" :item="internalVacancy" />
            <div>
              <h3 class="text-xl font-weight-semibold">
                <span>
                  {{ $t("registration.finish_your_account_creation") }}
                </span>
              </h3>
              <p class="text-subdued">
                {{ $t("registration.you_need_your_account_to_check") }}
              </p>
            </div>

            <div>
              <h4 class="pb-4">
                {{ $t("curriculum.sections.personal.login") }}
              </h4>

              <div @click="showInstructions = true">
                <form-field-password
                  name="curriculum.sensitive.password"
                  v-model="curriculum.sensitive.password"
                  rules="required"
                  untracked
                  autocomplete="off"
                />

                <form-field-password
                  name="curriculum.sensitive.password_confirmation"
                  v-model="curriculum.sensitive.password_confirmation"
                  rules="required|confirmed:curriculum.sensitive.password"
                  untracked
                  autocomplete="off"
                />
              </div>

              <password-strength-checker
                v-show="showInstructions"
                :password="curriculum.sensitive.password"
                :password_confirmation="
                  curriculum.sensitive.password_confirmation
                "
              />
            </div>

            <b-button variant="primary" block pill @click="submitForm()">
              {{ $t("curriculum.sections.personal.bnt_ready") }}
            </b-button>
          </div>
        </validation-observer>
      </form>
    </b-card>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import { useAuth } from "@state/auth/auth";
import { notify } from "@/plugins/notification";
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import { useVacancies } from "@state/vacancy/vacancies";
import { useChangeable } from "@state/behaviours/changeable";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PasswordStrengthChecker from "@components/user/PasswordStrengthChecker.vue";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    VacancyHeader,
    ...FormComponents,
    PasswordStrengthChecker,
  },
  props: {
    vacancy_id: Number | String,
    change_password: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      domain: null,
      forms: null,
      curriculum: null,
      changeable: null,
      vacancies: null,
      isLoading: true,
      stateSubscribe: null,
      showPassword: false,
      sections: {},
      emittedByZipCodeField: false,
      curriculumFile: "",
      internalVacancy: null,
      showInstructions: false,
    };
  },
  created() {
    this.domain = useDomain();
    this.forms = useCompanyForm();
    this.curriculum = useCurriculum();
    this.changeable = useChangeable();
    this.vacancies = useVacancies();

    this.authentication = useAuth();

    this.isLoading = true;
    this.$root.loading = true;

    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }

    this.forms.fields("express", true).then((fields) => {
      for (let i in this.forms.express.sections) {
        this.$set(this.sections, i, this.forms.express.sections[i]);
      }
      //this.$nextTick(() => {
      this.isLoading = false;
      this.$root.loading = false;
      //});
    });

    //this.preparePage();

    if (this.changeable.loadChanges("changeable-express-form")) {
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.curriculum.applyChanges(poll_changes.changes);
    }

    this.stateSubscribe = this.changeable.$subscribe((mutation, state) => {
      //const poll_changes = this.changeable.poolChanges('curriculum')
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.changeCounter = poll_changes.counter;

      if (poll_changes.counter >= 1) {
        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;
        this.changeable.localPersist("changeable-express-form");
        this.changeCounter = 0;
      }
    });

    this.isLoading = false;
    this.$root.loading = false;
  },
  destroyed() {
    this.stateSubscribe();
    this.curriculum.resetState();
  },

  /**
   * @todo Move to a getter inside the state
   */
  computed: {
    firstName() {
      return this.curriculum.personal.name.split(" ")[0] || "";
    },
    isSJ() {
      return this.company.config.sj;
    },
  },
  methods: {
    // preparePage() {
    //     //Merging local data into state
    //     const local = this.curriculum.getStep1ExpressDataFromLocal();

    //     this.curriculum.personal = {
    //         ...this.curriculum.personal,
    //         ...local,
    //     };

    //     //Redirect to step-1 if name or email is empty
    //     if (
    //         !this.curriculum.personal.name ||
    //         !this.curriculum.personal.cpf
    //     ) {
    //         this.$router.push({ name: "create-account" });
    //     }
    // },

    submitForm() {
      return this.change_password ? this.changePassword() : this.register();
    },
    changePassword() {
      this.$refs.curriculumStep2Rules.validate().then((success) => {
        if (!success) {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }

        const password_data = {
          is_sj: this.company.config.sj ?? false,
          password: this.curriculum.sensitive.password,
          password_confirmation: this.curriculum.sensitive.password_confirmation,
        };

        this.authentication.changePassword(password_data).then((data) => {
          this.$auth.fetch();

          this.$router.push({
            name: "profile",
            query: { showNps: true },
          });
        });
      });
    },
    register() {
      this.$refs.curriculumStep2Rules.validate().then((success) => {
        if (!success) {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }

        const register = {
          data: {
            vacancy_id: this.vacancy_id,
            auth: {
              name: this.curriculum.personal.name,
              primary_email: this.curriculum.personal.primary_email,
              password: this.curriculum.sensitive.password,
              password_confirmation:
                this.curriculum.sensitive.password_confirmation,
              cpf: this.curriculum.personal.cpf,
            },
            provider: this.isSJ ? "sj" : "candidate",
            driver: "jwt",
            credentials: {
              login: this.curriculum.personal.is_foreigner
                ? this.curriculum.personal.primary_email
                : this.curriculum.personal.cpf,
              password: this.curriculum.sensitive.password,
              is_foreigner: this.curriculum.personal.is_foreigner,
              is_sj: this.company.config.sj ?? false,
            },
          },
          autoLogin: true,
          fetchUser: true,
          redirect: false,
        };

        this.isLoading = true;
        this.$root.loading = true;

        this.$auth
          .register(register)
          .then(() => {
            this.curriculum
              .create()
              .then(({ data }) => {
                this.changeable.clearLocalStorage();
                this.curriculum.resetState();
                this.changeable.cleanUpAfterSave();
                this.$root.loading = false;
                if (this.vacancy_id) {
                  // ENROLL HERE!!
                  this.$router.push({
                    name: "vacancy-enroll",
                    params: { vacancy_id: this.vacancy_id },
                  });
                } else {
                  this.$auth.fetch();

                  this.$router.push({
                    name: "profile",
                    query: { showNps: true },
                  });
                }
              })
              .catch((error) => {
                // ON CURRICULUM SAVE ERROR, MUST CANCEL?
                this.isLoading = false;
                this.$root.loading = false;
                notify({
                  title: "$t:errors.title.danger",
                  message: "$t:errors.registration.message",
                  variant: "danger",
                });
              });
          })
          .catch((error) => {
            this.isLoading = false;
            this.$root.loading = false;

            notify({
              title: "$t:errors.title.danger",
              message: "$t:errors.registration.message",
              variant: "danger",
            });
          });
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },
};
</script>
