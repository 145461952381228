// en, de, fr, br
import { faB } from "@fortawesome/free-solid-svg-icons";
import validationMessages from "vee-validate/dist/locale/pt_BR";

export default {
    //"pt-BR": {
    validations: {
        messages: {
            ...validationMessages.messages,
            "email-or-cpf": "O campo {_field_} não é um E-MAIL ou CPF válido",
            cpf: "O campo {_field_} possui um formato inválido",
            cnpj: "O campo {_field_} possui um formato inválido",
            pis: "O campo {_field_} possui um formato inválido",
            "zip-code": "O campo {_field_} possui um formato inválido",
            "full-name": "O campo {_field_} é inválido",
            date: "O campo {_field_} é uma data inválida",
            "before-date": "A data informada é maior que a data final",
            "after-date": "A data informada é menor que a data inicial",
            "minority-date": "A idade mínima para cadastro é de 14 anos.",
            required_unless: "O campo {_field_} é obrigatório",
            must_exists_if: "É necessário selecionar ao menos uma opção",
            cns: "O campo {_field_} possui um formato inválido",
        },
    },
    files: {
        remove: {
            done: "Arquivo removido com sucesso",
            fail: "Não foi possível excluir o seu arquivo. Por favor tente novamente",
        },
    },
    changeable: {
        "indicator-text":
            "Alterações salvas | {count} alteração pendente | {count} alterações pendentes",
    },
    errors: {
        403: "Acesso não permitido",
        404: "Página não encontrada",
        "create-password": "Crie uma senha!",
        account_recover: {
            "not-found": "Dados inválidos, tente novamente.",
        },
        link_account: {
            "already-existent":
                "Já existe um cadastro com este documento. Faça o login e vincule sua conta no menu de configurações.",
        },
        communication: {
            "not-sent":
                "Não foi possível registrar o seu contato. Por favor tente novamente",
        },
        title: {
            danger: "Erro",
            warning: "Aviso",
            info: "Informação",
        },
        "default-title": "Erro",
        account: {
            error: "Falha ao enviar email de reset, tente novamente.",
            "social-media-account":
                "Sua conta foi criada a partir de uma rede social. Para recuperar sua conta, entre em contato com a empresa responsável.",
            "not-found":
                "Conta não encontrada, verifique se o CPF foi digitado corretamente.",
            "password-reset-throttled":
                "Um e-mail de reset foi enviado há poucos instantes, aguarde 10 minutos para tentar novamente.",
        },
        registration: {
            message:
                "Não foi possível completar o seu cadastro. Por favor tente novamente.",
            "account-existent":
                "Você já possuí uma conta! Faça o login para continuar.",
        },
        curriculum: {
            "not-found": "Currículo não encontrado",
        },
        vacancy: {
            "not-found": "Vaga não encontrada",
        },
        enroll: {
            "not-found": "Vaga não encontrada",
            "already-applied": "Você já candidatou-se a esta vaga!",
            forbidden: "Você não tem permissão para candidatar-se a esta vaga",

            "unfilled-fields":
                "Existem alguns campos necessários para conclusão da candidatura. Por favor, preencha os campos abaixo para prosseguir:",

            "does-not-attend":
                "Seu currículo não atende os requisitos mínimos abaixo:",
            "maintain-updated":
                "Mantenha sempre seu currículo atualizado para novas oportunidades.",
            pcd: "PCD",
            education: "Formação Acadêmica",
            languages: "Idiomas",
            computing: "Computação",
            "exclusive-selective-process":
                "Este processo seletivo exige exclusividade e o candidato está participando de outro processo",
        },
    },
    responses: {
        communication: {
            success:
                "Recebemos o seu pedido e entraremos em contato o quanto antes para resolução",
        },
        feedback: {
            success: "Agradecemos pela sua ajuda!",
        },
    },
    ocr: {
        error_title: "Atenção!",
        error_description: "Não conseguimos identificar algumas informações do documento:",
        error_validate: "Por favor verifique:",
        error_validate_item_1: "A imagem do documento anexado",
        error_validate_item_2: " O preenchimento dos campos acima",
        await_title: "Aguarde",
        await_description: "Aguarde, estamos validando as informações do documento."
    },
    parsing: {
        send_another_file: "Enviar outro arquivo",
        check_the_info:
            "Confira se os campos foram preenchidos corretamente e complete o que precisar",
        ready: "Prontinho!",
        read_file: "Lendo seu currículo...",
        start_fill: "Iniciar Preenchimento",
        search_file: "Buscar Arquivo",
        send_file:
            "Se preferir, envie seu currículo (PDF ou Word) para criar seu perfil como num passe de mágica",
    },
    common: {
        understood: "Entendi",
        wait: "Aguarde!",
        saving_info: "Salvando informações...",
        dependent: "Dependente",
        attention: "Atenção",
        success: "Sucesso",
        error: "Erro",
        loading: "Carregando",
        send: "Enviar",
        my_painel: "Meu Painel",
        complementary_data: "Dados Complementares",
        admission_data: "Dados Admissionais",
        apply: "Aplicar",
        yes: "Sim",
        create_new_section: "Criar nova seção",
        no: "Não",
        male: "Masculino",
        female: "Feminino",
        required: "Campo obrigatório",
        back_to_home: "Voltar ao início",
        edit: "Editar",
        save: "Salvar",
        cancel: "Cancelar",
        delete: "Excluir",
        advance: "Avançar",
        "no-results-found": "Nenhum resultado encontrado",
        "search-new-term": ", digite uma nova busca.",
        see: "Ver",
        search: "Buscar",
        or: "ou",
        and: "e",
        description: "Descrição",
        progress: "Progresso",
        link_with_sj: "Vincular com SelectyJobs",
        link_with_facebook: "Vincular com Facebook",
        link_with_linkedin: "Vincular com LinkedIn",
        link_with_google: "Vincular com Google",
        join: "Entrar",
        join_with_sj: "Entrar com SelectyJobs",
        join_with_facebook: "Entrar com Facebook",
        join_with_linkedin: "Entrar com LinkedIn",
        join_with_google: "Entrar com Google",
        join_with_ad: "Entrar com Active Directory",
        please_type_captcha_correctly:
            "Por favor, preencha o captcha corretamente.",
        to_continue_auth: "Para continuar, selecione uma das opções abaixo",
        register: "Cadastrar-se",
        create_account: "Criar Conta",
        create_profile: "Criar perfil",
        lets_begin: "Vamos começar!",
        lets_begin_subtitle: "Insira as informações básicas abaixo",
        jobs: "Vagas",
        show: "Mostrar",
        hide: "Ocultar",
        hello: "Olá",
        complete_curriculum: "Completar meu currículo",
        follow_process: "Acompanhar processo seletivo",
        curriculum: "Currículo",
        admissional: "Admissional",
        logout: "Sair",
        begin: "Início",
        vacancy: "Vagas",
        messages: "Mensagens",
        account: "Conta",
        email: "E-mail",
        password: "Senha",
        change: "Mudar",
        close: "Fechar",
        in: "em",
        click: "Clique aqui",
        to_add: "para adicionar",
        captcha_error: "Por favor, preencha o captcha corretamente.",
        course_not_found: "Não foi possível encontrar o curso",
        institution_not_found: "Não foi possível encontrar a instituição",
        document_not_found: "Não foi possível encontrar o documento",
        training_not_found: "Não foi possível encontrar o treinamento",
        occupation_not_found: "Não foi possível encontrar o cargo",
        please_insert_document: "Por favor, informe seu CPF.",
        why_we_need_that: "Por que estamos pedindo essa informação?",
        creation_garantee:
            "Queremos garantir que você terá um cadastro único e com identificação segura. Por isso, o CPF será sua chave principal de acesso.",
        validate_informations: "Validar informações",
        present: "até o momento",
        create_professional_profile:
            "Cadastre seu perfil profissional agora mesmo!",
        start_end: "Inicio/Fim",
        period: "Período",
        visit: "Visitar",
        for_companies: "Para empresas"
    },
    redirecting: {
        redirecting: "Redirecionando",
        please_wait: "Por favor aguarde...",
        access_forbiden: "Acesso não autorizado",
        enroll_portal: "Você será redirecionado para o portal",
    },
    passwords: {
        sent: "E-mail enviado com sucesso.",
        // Mostrado no caso de usuário não ter sido encontrado,
        user: "E-mail enviado com sucesso.",
        token: "Token Inválido. Por favor o processo novamente.",
        reset: "Sua senha foi alterada com êxito.",
    },
    password_level: {
        very_week: "Muito fraca",
        week: "Fraca",
        regular: "Regular",
        strong: "Muito Forte",
    },
    forms: {
        fields: {
            upload: "Anexar Arquivo",
            remove_file: "Remover arquivo",
            allowed_extensions: "Extensões permitidas",
            default_error:
                "Não foi possível realizar o envio do arquivo. Por favor tente novamente",
        },
    },
    company: {
        config: {
            logo_position: {
                label: "",
                placeholder: "",
                description: "",
            },
        },
    },
    customize: {
        main_title: "Customizar",
        subtitle:
            "Escolha a cor do tema, adicione áreas de conteúdo, fotos e mais.",
        color_theme: "Cor do tema",
        color_theme_description: "Afeta botões e elementos interativos.",
        logo_position: "Posição da logo",
        logo_position_description:
            "Selecione a melhor posição para sua logo no portal.",
        sections: "Áreas de conteúdo",
        sections_description:
            "Arraste a seção para alterar a posição de exibição.",

        type: {
            placeholder: "Selecione",
        },
        base_theme: {
            label: "Tema Principal",
            placeholder: "Selecione o tema principal",
            description: "",
        },
        "primary-color": {
            label: "Cor primária",
            placeholder: "Selecione a cor primária",
            description: "",
        },
        title: {
            label: "Título",
            placeholder: "Digite o Título",
            description: "",
        },
        banner: {
            add_banner: 'Adicionar Banner',
            remove_banner: 'Remover Banner',
            more_details: 'Mais detalhes',
            redirect_url: {
                label: "Link de redirecionamento",
                placeholder: "",
                description: "",
            }
        }
    },
    passwords: {
        cpf: {
            label: "CPF",
            placeholder: "Insira seu CPF",
            description: "",
        },
        cellphone: {
            label: "Celular",
            placeholder: "Insira o número de celular cadastrado",
            description: "",
        },
        birth_date: {
            label: "Data de Nascimento",
            placeholder: "Insira sua data de nascimento",
            description: "",
        },
        primary_email: {
            label: "Email",
            placeholder: "Insira seu email cadastrado",
            description: "",
        },
    },
    user: {
        login: {
            email: {
                label: "E-mail",
                placeholder: "",
                description: "",
            },
            is_foreigner: {
                label: "Sou estrangeiro",
                placeholder: "",
                description: "",
            },
            login: {
                label: "CPF",
                placeholder: "",
                description: "",
            },
            password: {
                label: "Senha",
                placeholder: "",
                description: "",
            },
            submit: "Entrar",
            linked: "Entrar com linkedin",
            sj: "Entrar com SelectyJobs",
            joinMessage: "Entre para continuar",
            joinSjMessage: "Entre na sua conta SelectyJobs para continuar",
            needAccount: "Não é cadastrado",
            create_account: "Crie uma conta",
            forgotPassword: "Esqueci minha senha",
            invalidEmailOrPassword: "E-mail e/ou senha inválido(s).",
            forgotUsername: "Esqueceu seus dados de acesso?",
        },
        forgot: {
            login: {
                label: "CPF",
                placeholder: "Digite seu CPF",
                description: "",
            },
            nextStep: "Próximo",
            title: "Esqueceu a senha?",
            info: "Sem problemas. Vamos te enviar um e-mail com instruções para criar uma nova senha.",
            submit: "Criar nova senha",
            return_to: "Voltar ao login",
            email_sent: "E-mail enviado!",
            email_instructions:
                "Enviamos um e-mail para {email} com instruções para criar uma nova senha.",
            email_spam_instructions:
                "Se o e-mail estiver demorando para chegar verifique sua caixa de spam.",
            no_longer_email_access: "Não tenho mais acesso a este e-mail",
            recover_account: "Recuperar conta",
            recover_account_failure: "Falha ao recuperar conta.",
            contact_to:
                "Falha ao validar dados da conta. Para recuperar sua conta, entre em contato com a {company_name}.",
            social_media_account:
                "Sua conta foi criada a partir de uma rede social, faça o login por esta rede social ou recupere a conta abaixo:",
            atention: "Atenção!",
        },
        forgotAccount: {
            title: "Esqueceu seus dados de acesso?",
            info: "Sem problemas! Vamos validar algumas informações para recuperar sua conta.",
            next: "Próximo",
            validate_data: "Validar dados",
        },
        confirmAccountData: {
            title: "Encontramos sua conta!",
            info: "Para sua segurança, vamos confirmar algumas informações antes de recuperar sua conta.",
            cellphone:
                "Confirme abaixo o número do celular cadastrado em sua conta:",
            birth_date: "Confirme abaixo sua data de nascimento:",
            email: "Confirme abaixo o seu e-mail cadastrado em sua conta:",
        },
        reset: {
            title: "Crie uma nova senha",
            info: "Quase lá! Agora basta informar uma nova senha.",
            submit: "Alterar minha senha",
            return_to: "Voltar ao login",
        },
        reseted_password_success: {
            title: "Sucesso!",
            info: "Sua senha foi alterada com êxito.",
        },
        reseted_password_error: {
            title: "Ops!",
            info: "Houve um erro ao tentar alterar sua senha, tente novamente.",
        },
        reset_email: {
            title: "Cadastre um novo e-mail",
            info: "Quase lá! Agora basta cadastrar um novo e-mail para enviar o reset de senha.",
            submit: "Alterar e-mail",
            return_to: "Voltar ao login",
        },
    },
    curriculum: {
        title: "Currículo",
        sections: {
            personal: {
                title: "Dados pessoais",
                address: "Dados de Endereço",
                contacts: "Dados de Contato",
                required_contacts: "Insira ao menos um telefone para contato.",
                diversity: "Diversidade e Inclusão",
                diversity_subtitle:
                    "Nós valorizamos a diversidade no ambiente de trabalho, por isso você tem liberdade para fornecer as informações abaixo:",
                extra: "Campos adicionais",
                cnh: "Carteira Nacional de Habilitação",
                login: "Seu login",
                bnt_ready: "Tudo pronto",
                confirm: "Confirmar",
                extra_by_company: "Campos Extras {company}"
            },
            parsing: {
                title: "Leitor automático de CV",
                btn_upload_curriculum: "Preencha pra mim - Importar Currículo",
            },
            professional: {
                title: "Dados profissionais",
                experiences: "Suas experiências",
                objectives: "Objetivos",
                experience: "Jornada Profissional",
                add_experience: "Adicione suas experiências profissionais",
            },
            education: {
                title: "Educação",
                academic: "Sua Escolaridade",
                training: "Treinamento Complementar",
                languages: "Idiomas",
                computing: "Conhecimento em Informática",
            },
            dependents: {
                title: "Dependentes",
            },
            other_information: {
                title: "Outras informações",
            },
            additional: {
                title: "Informações adicionais",
            },
            presentation: {
                title: "Apresentação",
            },
        },
        actions: {
            professional: {
                add_experience: "Adicionar Experiência",
            },
            education: {
                add_academic: "Adicionar Escolaridade",
                add_training: "Adicionar Treinamento",
                add_language: "Adicionar Idioma",
            },
            dependents: {
                add_dependent: "Adicionar dependente",
            },
            transports: {
                add_transports: "Adicionar transporte",
            },
        },
        texts: {
            professional: {
                activities: "Atividades",
            },
            education: {
                academic: {
                    time: {
                        morning: "Manhã",
                        afternoon: "Tarde",
                        night: "Noite",
                    },
                    period: {
                        period: "{n}º Período",
                    },
                    situation: {
                        studying: "Cursando",
                        completed: "Concluído",
                        stopped: "Interrompido",
                    },
                },
                none: "Nenhum",
                basic: "Básico",
                intermediate: "Intermediário",
                advanced: "Avançado",
                fluent: "Fluente",
            },
            dependents: {
                tokens: {
                    a: "Filho(a) Válido",
                    b: "Filho incapacitado para trabalhar",
                    c: "Cônjuge",
                    d: "Pai",
                    e: "Mãe",
                    f: "Sogro(a)",
                    g: "Outros",
                    h: "Avô(ó)",
                    i: "Companheiro(a)",
                    j: "Enteado(a)",
                    k: "Excluído",
                    l: "Ex-cônjuge",
                    m: "Irmã(o) Válido",
                    n: "Irmã(o) incapacitada(o) para trabalhar",
                    o: "Ex-companheiro(a)",
                    p: "Ex-sogro(a)",
                    q: "Neto(a)",
                    r: "Ex-enteado(a)",
                },
            },
            personal: {
                pcd: {
                    not_informed: "Não informado",
                    none: "Nenhuma",
                    physical: "Física",
                    visual: "Visual",
                    auditive: "Auditiva",
                    intelectual: "Intelectual",
                    psicosocial: "Psicossocial",
                    autism: "Autismo",
                    multiple: "Múltiplas",
                    rehabilitated: "Reabilitado",
                },
            },
        },
        sensitive: {
            login_username: {
                label: "E-mail ou CPF",
                placeholder: "",
                description: "",
            },
            new_primary_email: {
                label: "Novo Email",
                placeholder: "Insira um novo email",
                description: "",
            },
            new_primary_email_confirmation: {
                label: "Confirmação de Email",
                placeholder: "Confirme o seu novo email",
                description: "",
            },
            password: {
                label: "Senha",
                placeholder: "Digite sua senha",
                description: "",
            },
            password_confirmation: {
                label: "Confirmação de senha",
                placeholder: "Confirme a senha digitada",
                description: "",
            },
            new_password: {
                label: "Nova Senha",
                placeholder: "Crie uma nova senha",
                description: "",
            },
            new_password_confirmation: {
                label: "Confirmação de Nova Senha",
                placeholder: "Confirme sua nova senha",
                description: "",
            },
        },
        personal: {
            is_foreigner: {
                label: "Sou Estrangeiro",
                placeholder: "",
                description: "",
            },
            origin_country: {
                label: "País de Origem",
                placeholder: "",
                description: "",
            },
            document_type_id: {
                label: "Documento de Identificação",
                placeholder: "",
                description: "",
            },
            document_number: {
                label: "Número do Documento",
                placeholder: "",
                description: "",
            },
            primary_email: {
                label: "E-mail",
                placeholder: "Informe seu e-mail",
                description: "",
            },
            primary_email_confirmation: {
                label: "Confirmação de e-mail",
                placeholder: "Confirme o seu e-mail",
                description: "",
            },
            name: {
                label: "Nome completo",
                placeholder: "Informe seu nome completo",
                description: "",
            },
            assumed_name: {
                label: "Nome Social",
                placeholder: "",
                description: "",
            },
            photo3x4_file_id: {
                label: "Foto 3 x 4",
                placeholder: "",
                description: "",
            },
            cpf: {
                label: "CPF",
                placeholder: "Informe seu CPF",
                description: "",
            },
            gender: {
                label: "Gênero",
                placeholder: "Sexo",
                description: "",
            },
            birth_date: {
                label: "Data de Nascimento",
                placeholder: "dd/mm/aaaa",
                description: "",
            },
            cellphone: {
                label: "Celular (com WhatsApp)",
                placeholder: "(00) 00000-0000",
                description: "",
            },
            home_phone: {
                label: "Telefone Residencial",
                placeholder: "Digite o seu telefone residencial",
                description: "",
            },
            special_needs: {
                label: "Se enquadra como PCD?",
                placeholder: "Selecione",
                description: "",
            },
            special_needs_details: {
                label: "Especifique",
                placeholder: "Selecione",
                description: "Descrição PCD",
            },
            special_needs_file: {
                label: "Anexar Laudo PCD",
                description: "Anexar Laudo/Certificado de Reabilitação",
                placeholder: "Anexo Laudo",
            },
            rg: {
                label: "RG",
                placeholder: "RG",
                description: "",
            },
            state_rg: {
                label: "Estado Emissor RG",
                placeholder: "Estado Emissor RG",
                description: "",
            },
            organ_rg: {
                label: "Órgão Emissor RG",
                placeholder: "Órgão Emissor RG",
                description: "",
            },
            civil_status: {
                label: "Estado Civil",
                placeholder: "Estado Civil",
                description: "",
            },
            cnh_type: {
                label: "Tipo de CNH",
                placeholder: "Tipo de CNH",
                description: "",
            },
            children: {
                label: "Quantidade de filhos",
                placeholder: "Quantidade de filhos",
                description: "",
            },
            children_info: {
                label: "Informações extras sobre seus filhos",
                placeholder: "",
                description: "",
            },

            cellphone: {
                label: "Celular (com WhatsApp)",
                placeholder: "(00) 00000-0000",
                description: "",
            },
            home_phone: {
                label: "Telefone residencial",
                placeholder: "Telefone residencial",
                description: "",
            },
            business_phone: {
                label: "Telefone comercial",
                placeholder: "Telefone comercial",
                description: "",
            },
            message_with: {
                label: "Contato para recado",
                placeholder: "Contato para recado",
                description: "",
            },
            secondary_email: {
                label: "E-mail secundário",
                placeholder: "E-mail secundário",
                description: "",
            },
            site: {
                label: "Site",
                placeholder: "Site",
                description: "",
            },
            avatar: {
                label: "",
                placeholder: "",
                description: "",
            },
            cv_external: {
                label: "Anexar Currículo",
                placeholder: "",
                description: "",
            },
            cpf_file_id: {
                label: "Anexar CPF",
                placeholder: "",
                description: "",
            },
            city_rg: {
                label: "Cidade emissora RG",
                placeholder: "",
                description: "",
            },
            date_of_issue_rg: {
                label: "Data de emissão RG",
                placeholder: "",
                description: "",
            },
            rg_file_id: {
                label: "Anexar RG (frente)",
                placeholder: "",
                description: "",
            },
            rg_file2_id: {
                label: "Anexar RG (verso)",
                placeholder: "",
                description: "",
            },
            birth_file_id: {
                label: "Anexar Certidão de Nascimento",
                placeholder: "",
                description: "",
            },
            nationality: {
                label: "Nacionalidade",
                placeholder: "",
                description: "",
            },
            born_state: {
                label: "Estado de Nascimento",
                placeholder: "",
                description: "",
            },
            born_city: {
                label: "Cidade de Nascimento",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Nome da Mãe",
                placeholder: "",
                description: "",
            },
            mother_cpf: {
                label: "Cpf da Mãe",
                placeholder: "",
                description: "",
            },
            father_name: {
                label: "Nome do Pai",
                placeholder: "",
                description: "",
            },
            father_cpf: {
                label: "Cpf do Pai",
                placeholder: "",
                description: "",
            },
            racial: {
                label: "Raça / Cor",
                placeholder: "",
                description: "",
            },
            national_health_service_number: {
                label: "Número do Cartão SUS",
                placeholder: "",
                description: "",
            },
            national_health_service_number_file_id: {
                label: "Anexar Cartão SUS",
                placeholder: "",
                description: "",
            },
            professional_number: {
                label: "Número do Registro Profissional (Ex: CRM, CRP)",
                placeholder: "",
                description: "",
            },
            professional_file_id: {
                label: "Anexar Registro Profissional",
                placeholder: "",
                description: "",
            },
            mother_name_not_declared_in_the_document:
                "Nome da mãe não declarado no documento",
            father_name_not_declared_in_the_document:
                "Nome do pai não declarado no documento",
        },
        address: {
            country: {
                label: "País",
                placeholder: "Selecione o País",
                description: "",
            },
            zip_code: {
                label: "CEP",
                placeholder: "Digite seu CEP",
                description: "",
            },
            street_type: {
                label: "Tipo do Logradouro",
                placeholder: "Tipo do Logradouro",
                description: "",
            },
            street: {
                label: "Logradouro",
                placeholder: "Logradouro",
                description: "",
            },
            number: {
                label: "Número",
                placeholder: "Número",
                description: "",
            },
            complement: {
                label: "Complemento",
                placeholder: "Complemento",
                description: "",
            },
            district: {
                label: "Bairro",
                placeholder: "Bairro",
                description: "",
            },
            state: {
                label: "Estado",
                placeholder: "Selecione um estado",
                description: "",
            },
            city: {
                label: "Cidade",
                placeholder: "Selecione uma cidade",
                description: "",
            },
            address_file_id: {
                label: "Comprovante de Residência",
                placeholder: "",
                description: "",
            },
        },
        diversity: {
            racial: {
                label: "Étnico-racial",
                description: "Esta informação é obrigatória para o E-social.",
                placeholder: "",
            },
            assumed_name: {
                label: "Nome Social",
                description: "",
                placeholder: "",
            },
            gender_identity: {
                label: "Identidade de Gênero",
                description: "",
                placeholder: "",
            },
            gender_orientation: {
                label: "Orientação Sexual",
                description: "",
                placeholder: "",
            },
        },
        professional: {
            intended_occupation: {
                label: "Cargos Pretendidos",
                placeholder: "",
                description: "(máx 3 cargos)",
            },
            interest_levels: {
                label: "Nível de Interesse",
                placeholder: "",
                description: "(máx 3 interesses)",
            },
            interest_areas: {
                label: "Área de Interesse",
                placeholder: "",
                description: "(máx 3 áreas de interesse)",
            },
            salary_intended: {
                label: "Pretensão salarial",
                placeholder: "Pretensão salarial",
                description: "",
            },
            available_trip: {
                label: "Possui disponibilidade para viajar?",
                placeholder: "",
                description: "",
            },
            has_professional_history: {
                label: "Possui experiência profissional?",
                placeholder: "",
                description: "",
            },
            has_not_professional_history: {
                label: "Não possuo experiência",
                placeholder: "",
                description: "",
            },

            experiences: {
                company_id: {
                    label: "Empresa",
                    placeholder: "Empresa",
                    description: "",
                },
                company_name: {
                    label: "Empresa",
                    placeholder: "Empresa",
                    description: "",
                },
                occupation_id: {
                    label: "Cargo",
                    placeholder: "Cargo",
                    description: "",
                },
                start: {
                    label: "Início",
                    placeholder: "dd/mm/aaaa",
                    description: "",
                },
                finish: {
                    label: "Término",
                    placeholder: "dd/mm/aaaa",
                    description: "",
                },
                last_salary: {
                    label: "Último Salário",
                    placeholder: "Último Salário",
                    description: "",
                },
                current_job: {
                    label: "Emprego Atual",
                    placeholder: "Emprego Atual",
                    description: "",
                },
                acting_area: {
                    label: "Segmento",
                    placeholder: "Segmento",
                    description: "",
                },
                company_size: {
                    label: "Porte da empresa",
                    placeholder: "Porte da empresa",
                    description: "",
                },
                activities: {
                    label: "Atividades Realizadas",
                    placeholder: "Atividades Realizadas",
                    description: "",
                },
            },
        },
        education: {
            schooling_level: {
                label: "Nível Educacional",
                description: "",
                placeholder: "Selecione o nível",
            },
            school_file_id: {
                label: "Anexar Certidão de Escolaridade",
                description: "",
                placeholder: "",
            },
            has_schooling_level: {
                label: "Possui formação acadêmica?",
                placeholder: "",
                description: "",
            },
            has_not_academic_formation: {
                label: "Não possuo escolaridade",
                placeholder: "",
                description: "",
            },
            has_computer_knowledge: {
                label: "Possui conhecimentos em informática?",
                placeholder: "",
                description: "",
            },
            has_languages: {
                label: "Possui conhecimentos em outros idiomas?",
                placeholder: "",
                description: "",
            },
            has_additional_training: {
                label: "Possui treinamento complementar?",
                placeholder: "",
                description: "",
            },
            academic: {
                education_level_id: {
                    label: "Nível Educacional",
                    description: "",
                    placeholder: "Selecione o nível",
                },
                course_id: {
                    label: "Curso",
                    description: "",
                    placeholder: "",
                },
                period: {
                    label: "Período",
                    description: "",
                    placeholder: "",
                },
                time: {
                    label: "Horário",
                    description: "",
                    placeholder: "",
                },
                institution: {
                    label: "Instituição",
                    description: "",
                    placeholder: "Instituição",
                },
                status: {
                    label: "Situação",
                    description: "",
                    placeholder: "Selecione a situação",
                },
                start_date: {
                    label: "Data de início",
                    placeholder: "",
                    description: "",
                },
                conclusion_date: {
                    label: "Data de término",
                    placeholder: "",
                    description: "",
                },
            },
            training: {
                additional_training_id: {
                    label: "Treinamento",
                    placeholder: "Treinamento",
                    description: "",
                },
                description: {
                    label: "Atividade",
                    placeholder: "Atividade",
                    description: "",
                },
                institution: {
                    label: "Instituição",
                    placeholder: "Instituição",
                    description: "",
                },
                status: {
                    label: "Situação",
                    placeholder: "Situação",
                    description: "",
                },
                start_date: {
                    label: "Período (início)",
                    placeholder: "Período (início)",
                    description: "",
                },
                finish_date: {
                    label: "Período (término)",
                    placeholder: "Período (término)",
                    description: "",
                },
            },
            languages: {
                language_id: {
                    label: "Idioma",
                    placeholder: "Idioma",
                    description: "",
                },
                speaking: {
                    label: "Fala",
                    placeholder: "Fala",
                    description: "",
                },
                writing: {
                    label: "Escrita",
                    placeholder: "Escrita",
                    description: "",
                },
                understanding: {
                    label: "Compreensão",
                    placeholder: "Compreensão",
                    description: "",
                },
                reading: {
                    label: "Leitura",
                    placeholder: "Leitura",
                    description: "",
                },

                /*
                    'add_language_title' : 'Adicionar idioma',
                    'add_language_info' : 'Adicione aqui as suas informações de idiomas',
                    'language': 'Línguas estrangeiras',
                    */
            },
        },

        computing: {
            title: "Informática",
            label: "sadasdassdasdasdsa",
            basic: {
                label: "Básico",
                placeholder: "Básico",
                description: "",
            },
            intermediate: {
                label: "Intermediário",
                placeholder: "Intermediário",
                description: "",
            },
            advanced: {
                label: "Avançado",
                placeholder: "Avançado",
                description: "",
            },
        },
        additional: {
            abroad_experience: {
                label: "Experiência no exterior",
                placeholder: "Experiência no exterior",
                description: "",
            },
            has_abroad_experience: {
                label: "Possui experiência no exterior",
                placeholder: "Possui experiência no exterior",
                description: "",
            },
            description: {
                label: "Descrição",
                placeholder: "Descrição",
                description: "",
            },
            complementary_activities: {
                label: "Atividades complementares",
                placeholder: "Atividades complementares",
                description: "",
            },
            sports: {
                label: "Esportes",
                placeholder: "Esportes",
                description: "",
            },
            hobby: {
                label: "Hobbies",
                placeholder: "Hobbies",
                description: "",
            },
            music: {
                label: "Música",
                placeholder: "Música",
                description: "",
            },
            passions: {
                label: "Paixões",
                placeholder: "Paixões",
                description: "",
            },
            books: {
                label: "Livros",
                placeholder: "Livros",
                description: "",
            },
            social_work: {
                label: "Trabalhos sociais",
                placeholder: "Trabalhos sociais",
                description: "",
            },
            extra_informations: {
                label: "Informações adicionais",
                placeholder: "Informações adicionais",
                description: "",
            },
            cover_letter: {
                label: "Carta de apresentação",
                placeholder: "Carta de apresentação",
                description: "",
            },
        },
        ctps: {
            has_ctps: {
                label: "Possui Carteira de Trabalho?",
                placeholder: "",
                description: "",
            },
            is_first_job: {
                label: "Será seu primeiro emprego formal?",
                placeholder: "",
                description: "",
            },
            ctps_type: {
                label: "Tipo de Carteira de Trabalho?",
                placeholder: "",
                description: "",
            },
            ctps_number: {
                label: "CTPS Número",
                placeholder: "",
                description: "",
            },
            ctps_serial: {
                label: "CTPS Série",
                placeholder: "",
                description: "",
            },
            ctps_state: {
                label: "CTPS Estado",
                placeholder: "",
                description: "",
            },
            ctps_city: {
                label: "CTPS Cidade",
                placeholder: "",
                description: "",
            },
            ctps_date: {
                label: "CTPS Data Emissão",
                placeholder: "",
                description: "",
            },
            ctps_file_id: {
                label: "Anexar CTPS (Frente)",
                placeholder: "",
                description: "",
            },
            ctps_file2_id: {
                label: "Anexar CTPS (Verso)",
                placeholder: "",
                description: "",
            },
            ctps_file3_id: {
                label: "Anexar Último Registro CTPS",
                placeholder: "",
                description: "",
            },
            has_pis: {
                label: "Possui PIS?",
                placeholder: "",
                description: "",
            },
            pis_pasep_number: {
                label: "PIS/Pasep Número",
                placeholder: "",
                description: "",
            },
            pis_pasep_date: {
                label: "PIS/Pasep Data",
                placeholder: "",
                description: "",
            },
            pis_file_id: {
                label: "Anexar PIS",
                placeholder: "",
                description: "",
            },
        },
        voter: {
            has_voter_registration: {
                label: "Possui Título de Eleitor?",
                placeholder: "",
                description: "",
            },
            voter_registration_number: {
                label: "Número",
                placeholder: "",
                description: "",
            },
            voter_registration_zone: {
                label: "Zona",
                placeholder: "",
                description: "",
            },
            voter_registration_section: {
                label: "Seção",
                placeholder: "",
                description: "",
            },
            voter_registration_state: {
                label: "Estado",
                placeholder: "",
                description: "",
            },
            voter_registration_city: {
                label: "Cidade",
                placeholder: "",
                description: "",
            },
            voter_registration_date: {
                label: "Data de Emissão",
                placeholder: "",
                description: "",
            },
            voter_registration_file_id: {
                label: "Anexar Título de Eleitor (Frente)",
                placeholder: "",
                description: "",
            },
            voter_registration_file2_id: {
                label: "Anexar Título de Eleitor (Verso)",
                placeholder: "",
                description: "",
            },
        },
        armed_forces: {
            has_certificate_armed_forces: {
                label: "Possui Certificado de Reservista?",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_status: {
                label: "Situação",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service: {
                label: "Número RA",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_serie: {
                label: "Série",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_organ: {
                label: "Órgão",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_uf: {
                label: "UF",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_type: {
                label: "Tipo",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_category: {
                label: "Categoria",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_date: {
                label: "Data de Emissão",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file_id: {
                label: "Anexar Certificado de Reservista (frente)",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file2_id: {
                label: "Anexar Certificado de Reservista (verso)",
                placeholder: "",
                description: "",
            },
        },
        cnh: {
            has_cnh: {
                label: "Possui Carteira de Motorista?",
                placeholder: "",
                description: "",
            },
            cnh_number: {
                label: "Número",
                placeholder: "",
                description: "",
            },
            cnh_local: {
                label: "Local",
                placeholder: "",
                description: "",
            },
            cnh_organ: {
                label: "Órgão Emissor",
                placeholder: "",
                description: "",
            },
            cnh_state: {
                label: "Estado Emissor",
                placeholder: "",
                description: "",
            },
            cnh_type: {
                label: "Tipo de CNH",
                placeholder: "",
                description: "",
            },
            cnh_date_emission: {
                label: "Data de Emissão",
                placeholder: "",
                description: "",
            },
            cnh_date_validity: {
                label: "Data de Validade",
                placeholder: "",
                description: "",
            },
            cnh_date_first_emission: {
                label: "Primeira emissão",
                placeholder: "",
                description: "",
            },
            cnh_file_id: {
                label: "Anexar CNH (Frente)",
                placeholder: "",
                description: "",
            },
            cnh_file_2_id: {
                label: "Anexar CNH (Verso)",
                placeholder: "",
                description: "",
            },
        },
        // A Traduzir
        bank: {
            has_bank: {
                label: "Possui Conta Bancária no seu nome?",
                placeholder: "",
                description: "",
            },
            bank_name: {
                label: "Nome do Banco",
                placeholder: "",
                description: "",
            },
            bank_type: {
                label: "Tipo de Conta",
                placeholder: "",
                description: "",
            },

            //has_account_company

            bank_holder_name: {
                label: "Nome do Titular",
                placeholder: "",
                description: "",
            },
            bank_holder_type: {
                label: "Tipo de Titular",
                placeholder: "",
                description: "",
            },
            bank_agency: {
                label: "Agência",
                placeholder: "",
                description: "",
            },
            bank_account: {
                label: "Conta",
                placeholder: "",
                description: "",
            },
            bank_operation: {
                label: "Operação Bancária",
                placeholder: "",
                description: "",
            },
            account_file_id: {
                label: "Anexar Comprovante Conta Bancária (Frente)",
                placeholder: "",
                description: "",
            },
            account_file2_id: {
                label: "Anexar Comprovante Conta Bancária (Verso)",
                placeholder: "",
                description: "",
            },
            bank_pix_type: {
                label: "Tipo de Chave Pix",
                placeholder: "",
                description: "",
            },
            bank_pix_value: {
                label: "Chave PIX",
                placeholder: "",
                description: "",
            },
        },
        dependents: {
            name: {
                label: "Nome completo",
                placeholder: "",
                description: "",
            },
            birth_date: {
                label: "Data de Nascimento",
                placeholder: "",
                description: "",
            },
            gender: {
                label: "Sexo",
                placeholder: "",
                description: "",
            },
            civil_status: {
                label: "Estado Civil",
                placeholder: "",
                description: "",
            },
            kinship: {
                label: "Grau de Parentesco",
                placeholder: "",
                description: "",
            },
            rg: {
                label: "RG",
                placeholder: "",
                description: "",
            },
            cpf: {
                label: "CPF",
                placeholder: "",
                description: "",
            },
            vaccine_number: {
                label: "Caderneta de Vacinação",
                placeholder: "",
                description: "",
            },
            sus_card: {
                label: "Número do Cartão SUS",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Nome da Mãe",
                placeholder: "",
                description: "",
            },
            income_tax: {
                label: "Deseja declarar em seu imposto de renda?",
                placeholder: "",
                description: "",
            },
            life_insurance: {
                label: "Incluir esse dependente como beneficiário de seu seguro de vida?",
                placeholder: "",
                description: "",
            },
            health_care: {
                label: "Incluir esse dependente no plano de Assistência Médica?",
                placeholder: "",
                description: "",
            },
            file_1_id: {
                label: "Anexar Certidão de Nascimento (Frente)",
                placeholder: "",
                description: "",
            },
            file_2_id: {
                label: "Anexar Certidão de Nascimento (Verso)",
                placeholder: "",
                description: "",
            },
            file_3_id: {
                label: "Anexar CPF do Dependente",
                placeholder: "",
                description: "",
            },
            file_4_id: {
                label: "Anexar Declaração de Matrícula Escolar",
                placeholder: "",
                description: "",
            },
            file_5_id: {
                label: "Anexar Comprovante de matrícula Universitária",
                placeholder: "",
                description: "",
            },
            file_6_id: {
                label: "Anexar Declaração de Dependência Econômica Registrada em Cartório",
                placeholder: "",
                description: "",
            },
            file_7_id: {
                label: "Anexar Termo de Tutela",
                placeholder: "",
                description: "",
            },
            file_8_id: {
                label: "Anexar Carteirinha de Vacinação (Frente)",
                placeholder: "",
                description: "",
            },
            file_9_id: {
                label: "Anexar Cartão SUS",
                placeholder: "",
                description: "",
            },
            file_10_id: {
                label: "Anexar Carteirinha de Vacinação (Verso)",
                placeholder: "",
                description: "",
            },
            file_11_id: {
                label: "Anexar RG",
                placeholder: "",
                description: "",
            },
            file_12_id: {
                label: "Anexar Certidão de Casamento",
                placeholder: "",
                description: "",
            },
            file_13_id: {
                label: "Anexar Laudo PCD",
                placeholder: "",
                description: "",
            },
            file_14_id: {
                label: "Anexar Histórico Escolar",
                placeholder: "",
                description: "",
            },
        },
        epi: {
            tshirt: {
                label: "Camiseta",
                placeholder: "",
                description: "",
            },
            trousers: {
                label: "Calça",
                placeholder: "",
                description: "",
            },
            shoe: {
                label: "Calçado",
                placeholder: "",
                description: "",
            },
            jacket: {
                label: "Jaqueta",
                placeholder: "",
                description: "",
            },
            shirt: {
                label: "Camisa",
                placeholder: "",
                description: "",
            },
        },
        benefits: {
            benefits: {
                label: "Selecione os benefícios que deseja receber",
                placeholder: "",
                description: "",
            },
            has_transports: {
                label: "Precisa de vale transporte?",
                placeholder: "",
                description: "",
            },
        },
        transports: {
            way_transport: {
                label: "Itinerário",
                description: "",
                placeholder: "",
            },
            type_transport: {
                label: "Tipo de Transporte",
                description: "",
                placeholder: "",
            },
            path_transport: {
                label: "Trajeto",
                description: "",
                placeholder:
                    "Ex: Linha Vermelha do Metrô (Corinthians-Itaquera)",
            },
            value_transport: {
                label: "Valor da passagem (R$)",
                description: "",
                placeholder: "",
            },
        },
        cnpj: {
            has_cnpj: {
                label: "Possui Pessoa Jurídica?",
                placeholder: "",
                description: "",
            },
            cnpj_name: {
                label: "Razão Social",
                placeholder: "",
                description: "",
            },
            cnpj_number: {
                label: "CNPJ",
                placeholder: "",
                description: "",
            },
        },
        contacts: {
            contact_name1: {
                label: "Nome 1",
                placeholder: "",
                description: "",
            },
            contact_phone1: {
                label: "Telefone 1",
                placeholder: "",
                description: "",
            },
            contact_name2: {
                label: "Nome 2",
                placeholder: "",
                description: "",
            },
            contact_phone2: {
                label: "Telefone 2",
                placeholder: "",
                description: "",
            },
        },
        forms: {
            transport_form_file_id: {
                label: "Anexar Formulário de Vale Transporte",
                placeholder: "",
                description: "",
            },
            transport_file_id: {
                label: "Cópia do Cartão de Vale Transporte",
                placeholder: "",
                description: "",
            },
            chartered_file_id: {
                label: "Formulário de Fretado",
                placeholder: "",
                description: "",
            },
            salary_family_file_id: {
                label: "Formulário de Salário Familia",
                placeholder: "",
                description: "",
            },
            impost_file_id: {
                label: "Anexar Formulário Imposto de Renda",
                placeholder: "",
                description: "",
            },
            has_contribution_sindical: {
                label: "Deseja realizar Contribuição Sindical?",
                placeholder: "",
                description: "",
            },
            syndicate_contribution_file_id: {
                label: "Anexar Declaração de Adesão à Contribuição Sindical",
                placeholder: "",
                description: "",
            },
            vaccine_file_id: {
                label: "Anexar Carteira de Vacinação",
                placeholder: "",
                description: "",
            },
            unemployment_insurance_file_id: {
                label: "Anexar Comprovante de Seguro Desemprego",
                placeholder: "",
                description: "",
            },
            contribution_inss_file_id: {
                label: "Anexar Comprovante Contribuição INSS",
                placeholder: "",
                description: "",
            },
            other_file_id: {
                label: "Anexar Outros",
                placeholder: "",
                description: "",
            },
            other_file2_id: {
                label: "Anexar Outros",
                placeholder: "",
                description: "",
            },
            other_file3_id: {
                label: "Anexar Outros",
                placeholder: "",
                description: "",
            },
            other_file4_id: {
                label: "Anexar Outros",
                placeholder: "",
                description: "",
            },
        },
    },
    admissional: {
        title: "Formulário Admissional",
        sections: {
            personal: {
                title: "Dados Básicos",
                address: "Dados de Endereço",
                education: "Dados Acadêmicos",
                diversity: "Diversidade e Inclusão"
            },
            ctps: {
                title: "Carteira de Trabalho",
            },
            voter: {
                title: "Título de Eleitor",
            },
            armed_forces: {
                title: "Certificado de Reservista",
            },
            cnh: {
                title: "Carteira de Motorista",
            },
            bank: {
                title: "Dados Bancários",
                generate_bank_letter:
                    "Gere aqui sua carta para abertura de conta bancária",
            },
            dependents: {
                title: "Dependentes",
            },
            transports: {
                title: "Transporte",
            },
            epi: {
                title: "EPI/Uniforme",
            },
            benefits: {
                title: "Benefícios",
            },
            cnpj: {
                title: "Dados de Pessoa Jurídica",
            },
            contacts: {
                title: "Contatos de Emergência",
            },
            forms: {
                title: "Outros Documentos",
            },
        },
    },
    complementary_data: {
        title: "Dados Complementares",
    },
    esocial: {
        title: 'Cadastro E-social'
    },
    admission_data: {
        title: "Dados Admissionais",
    },
    vacancy: {
        "no-vacancy": "Não há vagas a serem exibidas",
        filters: {
            text: {
                label: "",
                placeholder:
                    "Pesquise pelas palavras chave como cargo ou empresa",
                description: "",

                occupation: "Cargo",
                interest_area: "Área Profissional",
                activities: "Atividades",
                requirements: "Requisitos",
            },
            location: {
                label: "",
                placeholder: "Localização",
                description: "",
            },
            interval: {
                label: "",
                placeholder: "Data de publicação",
                description: "",
                tokens: {
                    anyTime: "Todas as datas de publicação",
                    lastDay: "Últimas 24 horas",
                    lastThreeDays: "Últimos 3 dias",
                    lastWeek: "Última semana",
                    lastMonth: "Últimos 30 dias",
                },
            },
            positions: {
                label: "",
                placeholder: "",
                description: "",
                tokens: {
                    all: "Todas",
                    oneOrMore: "Mais de 1 vaga",
                    fiveOrMore: "Mais que 5 vagas",
                    TenOrMore: "Mais que 10 vagas",
                },
            },
            contract_type: {
                label: "",
                placeholder: "Tipo de contrato",
                description: "",
                tokens: {
                    all: "Todos os tipos de contrato",
                    autonomous: "Autônomo",
                    clt: "CLT (Efetivo)",
                    freeLance: "Free-lancer",
                    juridicPerson: "Prestador de serviço (PJ)",
                    temporary: "Temporário",
                    trainee: "Trainee",
                    internship: "Estágio",
                    apprentice: "Menor aprendiz",
                    diarist: "Diarista",
                    cooperated: "Cooperado",
                    outsourced: "Terceirizado",
                },
            },
            workplace_type: {
                label: "",
                placeholder: "Modalidade de trabalho",
                description: "",
                tokens: {
                    all: "Todas as modalidades de vaga",
                    presential: "Presencial",
                    hybrid: "Híbrido",
                    homeOffice: "Remoto",
                    extern: "Externo"
                },
            },
            professional_area: {
                label: "",
                placeholder: "Área profissional",
                description: "",
                tokens: {
                    all: "Todas as áreas profissionais",
                    interest_areas_1: "Administração Comercial/Vendas",
                    interest_areas_2: "Administração de Empresas",
                    interest_areas_3: "Administração Pública",
                    interest_areas_4: "Advocacia/Jurídica",
                    interest_areas_5: "Agrícola",
                    interest_areas_6: "Agronomia",
                    interest_areas_7: "Alimentos e Bebidas",
                    interest_areas_8: "Arquitetura/Urbanismo",
                    interest_areas_9: "Arquivologia",
                    interest_areas_10: "Artes Cênicas",
                    interest_areas_11: "Artes Gráficas",
                    interest_areas_12: "Artes Plásticas",
                    interest_areas_13:
                        "Atend. a cliente - Bares e Restaurantes",
                    interest_areas_14:
                        "Atend. a cliente - Telefonistas/Recepcionistas",
                    interest_areas_15:
                        "Atend. a cliente - Telemarketing/Call Center",
                    interest_areas_16: "Atend. a cliente",
                    interest_areas_17: "Auditoria",
                    interest_areas_18: "Automação Industrial",
                    interest_areas_19: "Automobilística",
                    interest_areas_20: "Bancos",
                    interest_areas_21: "Biblioteconomia",
                    interest_areas_22: "Biologia/Ciências Biológicas",
                    interest_areas_23: "Biomedicina",
                    interest_areas_24: "Biotecnologia/Bioprocessos",
                    interest_areas_25: "Captação e Fomento",
                    interest_areas_26: "Ciências Sociais",
                    interest_areas_27: "Cinema",
                    interest_areas_28: "Comércio Exterior",
                    interest_areas_29: "Compras",
                    interest_areas_30: "Comunicação",
                    interest_areas_31: "Confecção",
                    interest_areas_32: "Construção Civil",
                    interest_areas_33: "Contabilidade",
                    interest_areas_34: "Controladoria",
                    interest_areas_35: "Culinária/Gastronomia",
                    interest_areas_36: "Cultura",
                    interest_areas_37: "Desenho Industrial",
                    interest_areas_38: "Desenvolvimento de Negócios",
                    interest_areas_39: "Design de Interiores",
                    interest_areas_40: "Design Gráfico",
                    interest_areas_41: "Ecologia/Meio Ambiente",
                    interest_areas_42: "Economia",
                    interest_areas_43: "Educação",
                    interest_areas_44: "Eletroeletrônica",
                    interest_areas_45: "Empreendedorismo Juvenil",
                    interest_areas_46: "Enfermagem",
                    interest_areas_47: "Enfermagem - do Trabalho",
                    interest_areas_48: "Engenharia de Alimentos",
                    interest_areas_49: "Engenharia Civil",
                    interest_areas_50: "Engenharia Eletrônica",
                    interest_areas_51: "Engenharia Eletrotécnica",
                    interest_areas_52: "Engenharia Mecânica",
                    interest_areas_53: "Engenharia Metalúrgica e de Materiais",
                    interest_areas_54: "Engenharia de Minas",
                    interest_areas_55: "Engenharia de Produção",
                    interest_areas_56: "Engenharia Química",
                    interest_areas_57: "Engenharia - Outras",
                    interest_areas_58: "Ensino Superior e Pesquisa",
                    interest_areas_59: "Educação - Ensino Médio / Técnico",
                    interest_areas_60: "Educação - Ensino Fundamental",
                    interest_areas_61: "Educação - Ensino Infantil",
                    interest_areas_62: "Educação - Ensino a Distância",
                    interest_areas_63: "Ensino - Outros",
                    interest_areas_64: "Entretenimento",
                    interest_areas_65: "Esportes",
                    interest_areas_66: "Estética",
                    interest_areas_67: "Eventos",
                    interest_areas_68: "Farmácia",
                    interest_areas_69: "Filosofia",
                    interest_areas_70: "Finanças",
                    interest_areas_71: "Fiscal",
                    interest_areas_72: "Física",
                    interest_areas_73: "Fisioterapia",
                    interest_areas_74: "Florestal",
                    interest_areas_75: "Fonoaudiologia",
                    interest_areas_76: "Geografia",
                    interest_areas_77: "Geologia",
                    interest_areas_78: "Gestão Empresarial",
                    interest_areas_79: "Gestão de Projetos",
                    interest_areas_80: "Gráfica e Editorial",
                    interest_areas_81: "História",
                    interest_areas_82: "Hotelaria",
                    interest_areas_83: "Industrial",
                    interest_areas_84: "Informática/T.I.",
                    interest_areas_85: "Inovação",
                    interest_areas_86: "Internet",
                    interest_areas_87: "Jornalismo",
                    interest_areas_88: "Lazer",
                    interest_areas_89: "Letras",
                    interest_areas_90: "Logística",
                    interest_areas_91: "Madeira e Mobiliário",
                    interest_areas_92: "Manutenção Industrial",
                    interest_areas_93: "Manutenção Predial",
                    interest_areas_94: "Marketing",
                    interest_areas_95: "Matemática/Estatística",
                    interest_areas_96: "Mecatrônica",
                    interest_areas_97: "Medicina/Hospitalar",
                    interest_areas_98: "Metalúrgica",
                    interest_areas_99: "Meteorologia",
                    interest_areas_100: "Mídias Educacionais",
                    interest_areas_101: "Mineração",
                    interest_areas_102: "Minerais não Metálicos",
                    interest_areas_103: "Moda",
                    interest_areas_104: "Museologia",
                    interest_areas_105: "Música",
                    interest_areas_106: "Nutrição",
                    interest_areas_107: "Oceanografia",
                    interest_areas_108: "Odontologia",
                    interest_areas_109: "Orçamento",
                    interest_areas_110:
                        "Organização de Eventos/Produção Cultural",
                    interest_areas_111: "Organização e Métodos",
                    interest_areas_112: "Papel e Celulose",
                    interest_areas_113: "Patrimônio - Gestão",
                    interest_areas_114: "Pesquisa de Mercado",
                    interest_areas_115: "Petrolífera",
                    interest_areas_116: "Plásticos e Polímeros",
                    interest_areas_117: "Produção/Fabricação",
                    interest_areas_118: "Propaganda",
                    interest_areas_119: "Psicologia",
                    interest_areas_120: "Qualidade",
                    interest_areas_121: "Química",
                    interest_areas_122: "Radialismo e Televisão",
                    interest_areas_123: "Recursos Humanos",
                    interest_areas_124: "Refrigeração e Climatização",
                    interest_areas_125: "Relações Internacionais",
                    interest_areas_126: "Relações Públicas",
                    interest_areas_127: "Responsabilidade Social",
                    interest_areas_128: "Secretariado",
                    interest_areas_129: "Segurança e Saúde no Trabalho",
                    interest_areas_130: "Segurança Patrimonial",
                    interest_areas_131: "Seguros",
                    interest_areas_132: "Serviço Social",
                    interest_areas_133: "Serviços Administrativos",
                    interest_areas_134: "Serviços Domésticos",
                    interest_areas_135: "Serviços Especializados - Açougue",
                    interest_areas_136:
                        "Serviços Especializados - Padaria/Confeitaria",
                    interest_areas_137: "Serviços Especializados - Peixaria",
                    interest_areas_138: "Serviços Gerais",
                    interest_areas_139: "Serviços Técnicos - Elétricos",
                    interest_areas_140: "Serviços Técnicos - Eletrônicos",
                    interest_areas_141: "Serviços Técnicos - Mecânicos",
                    interest_areas_142: "Serviços Técnicos - Outros",
                    interest_areas_143: "Suprimentos",
                    interest_areas_144: "Telecomunicações",
                    interest_areas_145: "Terapia Ocupacional",
                    interest_areas_146:
                        "Terceiro Setor/Responsabilidade Social",
                    interest_areas_147: "Tradução/Interpretação",
                    interest_areas_148: "Transporte - Gestão",
                    interest_areas_149: "Transporte Aéreo",
                    interest_areas_150: "Transporte Marítimo",
                    interest_areas_151: "Transporte Terrestre",
                    interest_areas_152: "Transporte Ferroviário",
                    interest_areas_153: "Treinamento e Orientação Profissional",
                    interest_areas_154: "Tributária",
                    interest_areas_155: "Turismo",
                    interest_areas_156: "Vendas",
                    interest_areas_157: "Vendas - Varejo",
                    interest_areas_158: "Vendas Técnicas",
                    interest_areas_159: "Veterinária",
                    interest_areas_160: "Viagens",
                    interest_areas_161: "Web Design",
                    interest_areas_162: "Zoologia",
                    interest_areas_163: "Zootecnia",
                    interest_areas_164: "Trade Marketing",
                },
            },
            results:
                "Nenhum resultado encontrado | {count} vaga encontrada | {count} vagas encontradas",
            count_paginated_results: "Mostrando {count} de ",
            count_all_results: "{count} resultados",
        },
        share: {
            title: "Compartilhar",
            tw: { label: "Twitter" },
            fb: { label: "Facebook" },
            lk: { label: "Linked In" },
            whatsapp: { label: "Whats app" },
        },
        actions: {
            share: "Compartilhar",
            apply: "Candidatar-se",
            "already-applied": "Candidatado",
            "see-vacancy": "Ver vaga",
            seeAllVacancies: "Ver todas as vagas",
            seeOurVacancies: "Veja todas as nossas vagas!",
            copy: "Copiar",
            save: "Salvar",
            favorite: "Favoritar vaga",
            unfavorite: "Desfavoritar vaga",
            saved: "Salva",
            favorite_success: "Vaga favoritada com sucesso!",
            please_login_to_favorite: "Faça o login para favoritar a vaga!",
            favorite_failure: "Falha ao favoritar a vaga!",
            none_favorite_vacancy: "Sem vagas favoritas",
            almost_there: "Quase lá!",
            needed_information_to_complete:
                "As informações abaixo são necessárias para completar a sua candidatura.",
            keep_looking_vacancies:
                "Dê uma olhada em mais oportunidades e adicione à sua lista de favoritas as que mais combinam com seu perfil.",
            save_vacancy: "Salvar vaga",
            log_to_save: "Faça login para poder salvar essa vaga",
            remove_favorite: "Remover vaga dos favoritos",
        },
        texts: {
            pcd: {
                info: "Vagas destinada a pessoas portadoras de deficiência",
            },
        },
        shareJob: "Compartilhe esta vaga",
        codVacancy: "Cód. da Vaga:",
        jobsQuantity: "Número de vagas:",
        contract_type: "Tipo de contratação: ",
        interest_area: "Área profissional : ",
        workload: "Carga horária: ",
        work_schedule: "Jornada de trabalho: ",
        salary: "Salário: ",
        copyLink: "Ou copie o link",
        location: "Localização: ",
        company: "Empresa",
        pcd: "Vaga PCD?",
        workplace_type: "Modalidade de trabalho: ",
        pcdInfo: "Tipos de PCD:",
        search: "Pesquisar",
        lastDay: "Últimas 24 horas",
        lastThreeDays: "Últimos 3 dias",
        lastWeek: "Última semana",
        lastMonth: "Últimos 30 dias",
        anyTime: "A qualquer momento",
        moreThan: "mais de ",
        anyJobsQuantity: "Qualquer quantidade de vagas",
        cleanFilters: "Limpar filtros",
        jobs: "vagas",
        //share: "Compartilhar",
        apply: "Candidatar-se",
        about: "Sobre nós",
        vacancy_info: "Dados da vaga",
        responsabilities: "Responsabilidades e atribuições",
        requirements: "Requisitos e qualificações",
        desirable: "Desejável",
        benefits: "Benefícios",
        additional_info: "Informações adicionais",
        keep_searching:
            "Continue procurando e experimente outros termos e filtros para encontrar a vaga ideal para você.",
        empty_vacancy: "Nenhuma vaga encontrada",
        vacancy_not_found: "Vaga não encontrada",
        published_at: " Publicada há ",
        published_days: "N/A|1 dia|{count} dias",
        published_hours: "N/A|1 hora|{count} horas",
    },
    home: {
        sections: {
            vacancies: {
                title: "Vagas em destaque",
            },
        },
        resume: "Currículo",
        "active-vacancies": "Sem vagas | 1 vaga ativa | {count} vagas ativas",
        "pending-items": "Sem pendências | 1 pendência | {count} pendências",
        "unread-messages": "1 não lida | {count} não lidas",

        completeness: "preenchido",
        messages: "Mensagens",

        welcome_msg: "Bem vindo a home",
        hi: "Olá",
        follow: "acompanhar",
        currentJobs: "Você está participando de",
        jobs: "vagas",
        profileJobs: "Vagas que se encaixam no seu perfil",
        appointments: "Compromissos",
        curriculum: {
            empty: "Seu currículo está um pouco vazio",
            half: "Falta pouco para completar seu currículo",
            full: "Seu currículo está completo",
            complete: "Completar seu currículo",
            review: "Revisar currículo",
            follow: "Acompanhar",
        },
    },
    registration: {
        placeholders: {
            "email-or-cpf": "",
        },
        hello: "Olá, {0}",
        lets_go: "Vamos lá, {0}",
        congratulations_text: "Que bom que encontrou uma oportunidade!",
        account_not_found:
            "Não encontramos uma conta registrada para este CPF, deseja se cadastrar?",
        candidacy_start: "Vamos iniciar sua candidatura",
        create_mini_curriculum: "Preencha seu mini currículo para continuar",
        hasAccount: "Já tem uma conta?",
        login: "Entre aqui",
        fullName: "Nome completo",
        typeFullName: "Digite o seu nome completo",
        createPassword: "Crie uma senha",
        lowerLetter: "Uma letra minúscula",
        upperLetter: "Uma letra maiúscula",
        number: "Um número",
        specialChar: "Um caractere especial",
        eightChars: "8 caracteres no mínimo",
        agree_with: "Ao clicar em começar, você aceita",
        as: "as",
        specific_policies: "Políticas Específicas",
        from_company: "da empresa",
        os: "os",
        terms_and_conditions: "Termos e Condições",
        and_the: "e a",
        privacy_policy: "Política de Privacidade",
        from_selecty_platform: "da plataforma Selecty",
        continue: "Continuar",
        start: "Começar",
        createAccount: "Aceite e cadastre-se",
        confirmPassword: "Confirme a sua senha",
        finish_your_account_creation: "Finalize a criação da sua conta",
        you_need_your_account_to_check:
            "Você precisa dela para acompanhar suas candidaturas",
    },
    communication: {
        confirmation:
            "Recebemos sua solicitação e iremos analisar assim que possível.",
        error: "Ocorreu um erro ao enviar sua solicitação, tente novamente mais tarde.",
        almost_done: "Estamos quase lá!",
        invalid_token: {
            title: "Falha!",
            text: "Token inválido! Tente novamente mais tarde.",
        },
        request_sent: {
            title: "Tudo certo!",
            text: "Obrigado! Recebemos sua solicitação e iremos analisar assim que possível.",
        },
        email_confirm: {
            title: "Estamos quase lá!",
            text: "Enviamos um link de verificação em seu e-mail para poder registrar o contato, o link tem uma validade de 15 minutos.",
        },
    },
    confirm_communication: {
        loading: {
            title: "Estamos registrando seu contato",
            text: "Por favor aguarde...",
        },
    },
    feedback: {
        communication: {
            success: "Muito obrigado! Recebemos seu feedback.",
            confirmation:
                "Por favor, confirme seu e-mail para registrar seu contato.",
        },
        modal: {
            title: "Gostaríamos de pedir a sua colaboração contando para nós como foi sua experiência.",
        },
        rating_0: {
            name: "rating_0",
            label: "",
        },
        rating_1: {
            name: "rating_1",
            label: "",
        },
        rating_2: {
            name: "rating_2",
            label: "",
        },
    },
    help: {
        communication: {
            success:
                "Recebemos a sua dúvida. Assim que possível, você será contactado.",
            confirmation:
                "Por favor, confirme seu e-mail para registrar seu contato.",
        },
        modal: {
            title: "Dúvidas, Sugestões, por favor entre em contato",
        },
        message: {
            name: "Mensagem",
            label: "Conte para nós o que aconteceu",
            description:
                "Prometemos não utilizar suas informações de contato para enviar qualquer tipo de SPAM.",
        },
        confirmation_mail: {
            title: "Obrigado por confirmar seu e-mail!",
        },
    },
    messages: {
        search: "Buscar mensagens",
        noMessagesFound: "Sua caixa de mensagens está vazia",
        noSubject: "(sem assunto)",
        earlierMessages: "Mensagens anteriores",
        all: "Todas",
        read: "Lidas",
        unread: "Não Lidas",
        reply: "Responder",
        reply_message: "Digite sua mensagem de resposta e clique em responder",
    },
    chat: {
        startConversation: "Iniciar Conversa",
        send: "Enviar",
    },
    config: {
        title: "Configurações da conta",
        shortTitle: "Conta",
        notificationsTitle: "Notificações",
        linkAccount: "Vincular conta",
        password: {
            changePassword: "Mudar senha",
            savePassword: "Salvar senha",
            actualPassword: "Senha atual",
            informActualPassword: "Informe sua senha atual",
            newPassword: "Nova senha",
            informNewPassword: "Informe uma nova senha",
            confirmPassword: "Confirmação de senha",
            informConfirmPassword: "Confirme sua nova senha",
        },
        email: {
            changeEmail: "Mudar seu e-mail",
            saveEmail: "Salvar e-mail",
            newEmail: "Novo e-mail",
            informNewEmail: "Informe o novo e-mail",
            confirmeNewEmail: "Confirme o novo e-mail",
        },
        notifications: {
            push: {
                title: "Notificações push",
                description:
                    "Receba notificações push sobre novas vagas, mensagens e andamento de processos seletivos.",
            },
            email: {
                title: "Notificações via e-mail",
                description:
                    "Receba atualizações via email sobre novas vagas, mensagens e andamento de processos seletivos.",
            },
            whatsapp: {
                title: "Notificações via Whatsapp",
                description:
                    "Receba atualizações via WhatsApp sobre novas vagas, mensagens e andamento de processos seletivos.",
            },
        },
        dataPrivacy: {
            title: "Privacidade de dados",
            description1: "Você também poderá ",
            description2: "exercer todos os direitos dos titulares ",
            description3: "previstos na Lei Geral de Proteção de Dados – LGPD.",
            seePrivacyPolicy: "Ver política de privacidade",
        },
        cancelAccount: {
            title: "Cancelamento da conta",
            btnCancelAccount: "Cancelar Conta",
            description1: "O cancelamento da conta é um processo ",
            description2: "irreversível ",
            description3: "que apagará seus dados do sistema",
            description4:
                "O cancelamento e remoção de todos os seus dados será realizada em até 7 dias úteis e você receberá a confirmação em seu e-mail.",
        },
    },
    application: {
        status: {
            NOT_DEFINED: "Não aplicável",
            // const STATUS_WAITING = 'w';
            // // Status de convocação
            // const STATUS_WAITING = 'w';
            STATUS_NOT_ACCEPTED: "Não selecionado no Processo Seletivo",
            STATUS_EXCLUDED: "Currículo não selecionado para oportunidade",
            STATUS_LEAVE: "Desistiu do Processo Seletivo",
            STATUS_CALLED: "Participando do Processo Seletivo",
            STATUS_ACCEPTED: "Participando do Processo Seletivo",
            STATUS_WAITING: "Currículo em análise para oportunidade",
            STATUS_NOT_CONTACTED: "Currículo em análise para oportunidade",
            STATUS_THINKING: "Currículo em análise para oportunidade",
            STATUS_NOT_ATTEND: "Candidato não compareceu para entrevista",
            // const STATUS_SHOW_UP = 's';
            STATUS_NO_PROFILE: "Currículo não selecionado para oportunidade",
            // const STATUS_MASS_CONVOCATION = 'v';

            STATUS_HIRED: "Aprovado no Processo Seletivo",
            STATUS_HIRING: "Aprovado no Processo Seletivo",
            STATUS_DISAPPROVED: "Não selecionado no Processo Seletivo",
            STATUS_APPROVED: "Participando do Processo Seletivo",
            STATUS_ACCEPTING: "Candidatado à oportunidade",
            // const STATUS_ACCEPTED = 'a';
            STATUS_HIRED_CANCELED: "Não selecionado no Processo Seletivo",
            STATUS_HIRED_REPLACEMENT: "Não selecionado no Processo Seletivo",
            // const STATUS_HIRED_REPLACEMENT = 'm';
            // const STATUS_RECOMMENDED = 'r';
            // const STATUS_INVITED = 'q';
            // //status geral do candidato no PS
            // const STATUS_EXCLUDED = 'e';
            // const STATUS_STAND_BY = 'y';
        },
        title: "Minhas Vagas",
        progress: "Progresso da vaga",
        jobDetail: "Ver detalhes da vaga",
        applicationStep: "Você está na fase de candidatura",
        completeSteps: "Complete as etapas abaixo para prosseguir",
        candidacy_complete:
            "Complemente seu currículo para aumentar as chances de ser encontrado.",
        candidacy_confirmed: "Candidatura confirmada!",
        candidacy_failure: "Candidatura não foi confirmada!",
        start: "Iniciar",
        startInfo:
            "Para iniciar esta etapa você precisa concluir a etapa anterior",
        nextStep: "Próxima fase",
        completeMessage:
            "Ao completar a trilha de candidatura você passa para a próxima fase do processo seletivo",
        myApplications: "Minhas Vagas",
        curriculumStep: "Preenchimento do Currículo",
        quit: "Desistir",
        seeProgress: "Ver progresso",
        noVacancyEnrolled:
            "Você não está participando de nenhum processo seletivo",
        searchForVacancies: "Buscar Vagas",

        confirming_enrollment: "Confirmando Candidatura",
        we_wish_you_very_luck: "Te desejamos toda a sorte do mundo!",
        confirmed_enrollment: "Candidatura Confirmada",
        you_can_now_do_the_next_steps:
            "Agora você pode dar os próximos passos no processo seletivo",
        see_next_steps: "Ver Próximos passos",
        fill_my_curriculum: "Complementar meu currículo",
        none_application_yet: "Nenhuma candidatura ainda",
        keep_searching:
            "Continue pesquisando e adicionando vagas à sua lista de favoritos.",
        status_application: "Status: ",
    },
    lgpd: {
        title: "Formulário de Requisição de Direitos do Titular de Dados Pessoais",
        subtitle:
            "Orientações Gerais Disponibilizamos este formulário fundamentado legalmente pelo Art.18 da LGPD, para facilitar o exercício dos seus direitos previstos na Lei Geral de Dados Pessoais (LGPD). Lembramos de que a utilização deste formulário é facultativa, mas se trata de uma forma eficiente e segura para o processamento do seu pleito. Após o preenchimento do formulário ele será encaminhado, juntamente com o(s) comprovante(s) de identidade do requerente, para a área de proteção de dados da empresa.",
        btn_finish: "Finalizar",
        declaration_of_authenticity: {
            label: "Declaração de Autenticidade das informações prestadas",
            placeholder: "",
            description:
                "DECLARO, sob as penas da lei penal e, sem prejuízo das sanções administrativas e cíveis, que as cópias dos documentos anexados ao presente documento são autênticas e condizem com o documento original.",
        },
        data_holder: {
            label: "Você é o titular dos dados ou procurador/representante?",
            placeholder: "",
            description: "",
        },
        sections: {
            personal_data_holder: {
                title: "Qualificações do Titular de Dados Pessoais",
                subtitle:
                    "Preencha os espaços abaixo com as qualificações do titular de dados pessoais.",
            },
            identity_comprovation: {
                title: "Comprovação da Identidade do Titular de Dados Pessoais",
                subtitle:
                    "Solicitamos que seja anexado ao pedido o comprovante de identidade do titular de dados pessoais. Serão aceitos como documentos de identidade civil o RG, o passaporte, a carteira profissional ou quaisquer outros documentos que conste a qualificação e a foto do requisitante. Caso o documento de identidade civil não seja anexado a este formulário, a Selecty poderá solicitar informações adicionais ao titular de dados pessoais, a fim de poder atender à referida demanda.",
            },
            data_holder_rights: {
                title: "Direitos do Titular de Dados Pessoais: Quais  direitos você desejaria exercer?",
                subtitle:
                    'Selecione "Sim" somente para os que deseja exercer neste momento.',
            },
            data_holder_rights_actions: {
                title: "Direitos dos Titulares",
                subtitle: "",
            },
            data_request: {
                title: "Para auxiliar a Selecty a responder a sua requisição de maneira rápida e eficiente, solicitamos, por gentileza, que você nos forneça o máximo de detalhes das informações solicitadas. Por favor, especifique, detalhadamente, seu requerimento aqui:",
                subtitle: "",
            },
            result_request: {
                title: "Comunicação do Resultado do Requerimento",
                subtitle: "",
            },
            legal_agent_qualifications: {
                title: "Qualificações do Procurador ou Representante Legal do Titular de Dados Pessoais (caso se aplique)",
                subtitle:
                    "Preencha os espaços abaixo com as qualificações do procurador ou representante legal do titular de dados pessoais (caso se aplique).",
            },
            comprovation_power_grant: {
                title: "Comprovação da outorga ou delegação de poderes a terceiros (caso se aplique)o sem título",
                subtitle:
                    "Solicitamos que seja anexado ao pedido o comprovante de identidade do procurador ou do representante legal do titular de dados pessoais, bem como o instrumento legal de representação ou a prova de representação parental. Serão aceitos como documentos de identidade civil o RG, o passaporte, a carteira profissional ou quaisquer outros documentos que conste a qualificação e a foto do procurador ou representante legal do titular de dados pessoais. Caso o documento de identidade civil ou o instrumento legal de representação não sejam anexados a este formulário, a Selecty poderá solicitar informações adicionais ao requisitante ou ao seu representante legal do titular de dados pessoais, a fim de poder atender à referida demanda.",
            },
        },
        data_holder_name: {
            label: "Nome completo do titular de dados pessoais",
            placeholder: "",
            description: "",
        },
        data_holder_cpf: {
            label: "CPF do titular de dados pessoais",
            placeholder: "",
            description: "",
        },
        data_holder_phone: {
            label: "Telefone de Contato do titular de dados pessoais",
            placeholder: "",
            description: "",
        },
        data_holder_email: {
            label: "E-mail de Contato do titular de dados pessoais",
            placeholder: "",
            description: "",
        },
        data_holder_category: {
            label: "Categoria do titular de dados pessoais",
            placeholder: "",
            description: "",
        },
        data_holder_additional_info: {
            label: "Informações Adicionais",
            placeholder: "",
            description:
                "Quaisquer informações adicionais que possam nos ajudar a localizar seus dados pessoais",
        },
        data_holder_document: {
            label: "Anexar documento de identidade civil",
            placeholder: "",
            description: "CPF, RG ou CNH",
        },
        confirm_treatment_data: {
            label: "Deseja confirmar a existência de tratamento de seus dados pessoais?",
            placeholder: "",
            description:
                "Permite saber se a Selecty realizou qualquer tipo de tratamento de seus dados pessoais, ainda que não se tenha certeza se você foi atendido ou se teve algum tipo de relação anterior com a nossa empresa. Art.18, I, LGPD.",
        },
        confirm_access_data: {
            label: "Deseja ter acesso aos seus dados?",
            placeholder: "",
            description:
                "Permite saber sobre o tratamento de dados pessoais realizado pela Selecty. Esse item deve ser assinalado apenas se você já foi atendido ou teve algum tipo de relação anterior com a nossa empresa. Art.18, II, LGPD.",
        },
        hide_data: {
            label: "Deseja eliminar, anonimizar ou bloquear seus dados?",
            placeholder: "",
            description:
                "Permite a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos, tratados em desconformidade com a LGPD ou fornecidos mediante consentimento. Art.18, IV e VI, LGPD. Caso algum dado pessoal não possa ser eliminado, anonimizado ou bloqueado, em razão da necessidade de tratamento para cumprimento de obrigação legal, execução de contrato ou qualquer outro motivo, lhe informaremos.",
        },
        exercise_rights: {
            label: "Caso você já saiba da existência de tratamento dos seus dados pessoais pela Selecty ou ela venha a ser confirmada, quais direitos você gostaria de exercer?",
            placeholder: "",
            description: "",
        },
        data_request: {
            label: "Se possível, gostaríamos que você nos informasse os períodos específicos, horários, datas, nomes, tipos de documentos ou quaisquer outras informações acerca da requisição. Lembre-se de que a Selecty poderá entrar em contato com o requisitante ou seu procurador para solicitar informações adicionais, no caso de a requisição não estar clara o suficiente.",
            placeholder: "",
            description: "",
        },
        result_request: {
            label: "Como você gostaria de ser comunicado acerca do resultado da sua requisição?",
            placeholder: "",
            description: "",
        },
        legal_agent_name: {
            label: "Nome completo do procurador ou do representante legal",
            placeholder: "",
            description: "",
        },
        legal_agent_cpf: {
            label: "CPF do procurador ou do representante legal",
            placeholder: "",
            description: "",
        },
        legal_agent_address: {
            label: "Endereço do procurador ou do representante legal",
            placeholder: "",
            description: "Rua, Número, Complemento, CEP, Cidade, Estado",
        },
        legal_agent_phone: {
            label: "Telefone de contato do procurador ou do representante legal",
            placeholder: "",
            description: "Telefone com DDD no formato XX XXXXX-XXXX",
        },
        legal_agent_email: {
            label: "E-mail de contato do procurador ou do representante legal",
            placeholder: "",
            description: "",
        },
        legal_agent_document: {
            label: "Comprovante de identidade do procurador ou do representante legal do titular de dados pessoais",
            placeholder: "",
            description: "",
        },
        legal_agent_representative_document: {
            label: "Instrumento legal de representação ou a prova de representação parental",
            placeholder: "",
            description: "",
        },
    },
    disc: {
        profile: {
            achiever: "Realizador",
            agent: "Representante",
            appraiser: "Avaliador",
            counselor: "Conselheiro",
            creative: "Criativo",
            developer: "Desbravador",
            inspirational: "Inspirador",
            investigator: "Investigador",
            "objective thinker": "Pensador Objetivo",
            overshift: "Expandido",
            perfectionist: "Perfeccionista",
            persuader: "Convincente",
            pratitioner: "Praticante",
            promoter: "Promotor",
            "result-oriented": "Orientado a Resultados",
            specialist: "Especialista",
            tight: "tight",
            undershift: "Comprimido",
        },
        question: {
            I_am: "Sou uma pessoa...",
            More: "Mais",
            Less: "Menos",
            Enthuastic: "Entusiasta",
            Daring: "Audaciosa",
            Diplomatic: "Diplomática",
            Satisfied: "Cooperativa",
            Cautious: "Cautelosa",
            Determined: "Determinada",
            Convicing: "Convincente",
            "Good Natured": "Boa ouvinte",
            Friendly: "Que fala muito e faz amigos",
            Accurate: "Que fala de forma precisa e específica",
            Outspoken: "Que fala de forma franca e sem rodeios",
            Calm: "Que fala calmamente",
            Talkative: "Conversadora",
            Controlled: "Controlada",
            Conventional: "Apoiadora",
            Decisive: "Decidida",
            Adventurous: "Aventureira",
            Insightful: "Perspicaz",
            "Out-going": "Espontânea",
            Moderate: "Moderada",
            Gentle: "Gentil",
            Persuasive: "Persuasiva",
            Humble: "Cuidadosa",
            Original: "Ousada",
            Expressive: "Expressiva",
            Conscientious: "Consciente",
            Dominant: "Com iniciativa",
            Responsive: "Compreensiva",
            Poised: "Com bom humor",
            Observant: "Analítica",
            Modest: "Mediadora",
            Inpatient: "Impaciente",
            Tactful: "Disciplinada",
            Agreeable: "Solidária",
            Magnetic: "Encantadora",
            Insistent: "Pioneira",
            Brave: "Valente",
            Inspiring: "Inspiradora",
            Submissive: "Leal",
            Timid: "Tímida",
            Reserved: "Minuciosa",
            Obliging: "Prestativa",
            "Strong-willed": "Rápida e objetiva",
            Cheerful: "Criativa",
            Stimulating: "Que gosta de muita interação",
            Kind: "Gentil",
            Perceptive: "Muito atenta aos detalhes",
            Independent: "Independente",
            Competitive: "Competitiva",
            Considerate: "Ponderada",
            Joyful: "Popular",
            Private: "Discreta",
            Fussy: "Que pensa objetivamente",
            Obedient: "Que segue processos",
            Firm: "Exigente",
            Playful: "Brincalhona",
            Attractive: "Agradável ",
            Instropective: "Instrospectiva",
            Stubborn: "Teimosa",
            Predictable: "Estável",
            Logical: "Que segue a lógica",
            Bold: "Corajosa",
            Loyal: "Fiel",
            Charming: "Que tenta agradar a todos",
            Sociable: "Sociável",
            Patient: "Paciente",
            "Self-reliant": "Auto-suficiente",
            "Soft spoken": "Que busca precisão",
            Wiling: "Apaziguadora",
            Eager: "Que busca ação e rapidez",
            Thorough: "Meticulosa",
            "High-spirited": "Espirituosa",
            Aggressive: "Que busca objetividade",
            Extroverted: "Extrovertida",
            Amiable: "Amável",
            Fearful: "Que planeja cada passo",
            Confident: "Confiante",
            Sympathetic: "Simpática",
            Impartial: "Imparcial",
            Assertive: "Que vai direto ao ponto",
            "Well-desciplined": "Muito disciplinada",
            Generous: "Tranquila",
            Animated: "Animada",
            Persistent: "Persistente",
            Impulsive: "Impulsiva",
            Introverted: "Introvertida",
            Forceful: "Incisiva ",
            "Easy-going": "Previsível",
            "Good mixer": "Que conecta e integra as pessoas",
            Refined: "Observadora e crítica",
            Vigorous: "Enérgica",
            Lenient: "Tolerante ",
            Captivating: "Que transmite conhecimento",
            Contented: "Que busca ouvir a todos",
            Demanding: "Que direciona as ações",
            Compliant: "Que cria procedimentos",
            Argumentative: "Que argumenta para convencer",
            Systematic: "Que analisa as informações",
            Cooperative: "Que apoia para resolver",
            "Light-hearted": "Que interage e conversa com todos",
            Jovial: "Expansiva",
            Precise: "Detalhista",
            Direct: "Que assume riscos calculados",
            "Even-tempered": "Serena e equilibrada",
            Restless: "Inquieta",
            Neighborly: "Atenciosa",
            Appealing: "Entusiasmada",
            Careful: "Pensativa",
            Respectful: "Com foco no processo e planejamento",
            Pioneering: "Com foco na ação e resultado",
            Optimistic: "Com foco nas possibilidades e na inovação",
            Helpful: "Com foco no grupo e na cooperação",
        },
        form: {
            welcome: "Seja bem-vindo a sua avaliação.",
            instructions:
                "Por favor, clique em continuar e siga as instruções.",
            hello_user: "Olá {0}",
            lets_try_again: "Começar novamente",
            warning_recording_again:
                "O vídeo gravado anteriormente para a pergunta não foi salvo no sistema, por favor tente novamente",
            "time-to-response": "para resposta",
            minutes: "{0} min.",
            seconds: "{0} seg",
            "testing-your-camera": "Testando a sua câmera",
            "i-can-see-me-and-the-quality-is-good":
                "Me vejo e a qualidade está boa.",
            "testing-your-audio": "Testando o seu áudio",
            "i-can-hear-me-and-the-quality-is-good":
                "Me ouço e a qualidade está boa.",
            congratulations: "Parabéns {0}",
            "you-finished": "Você concluiu o questionário",
            "wait-return": "Aguarde o retorno do selecionador",
            "to-start": "para começar",
            "answer-now": "Responder agora",
            start: "Começar",
            continue: "Continuar",
            "ok-already-answered": "Pronto, já respondi",
            "all-working": "Tudo funcionando",
            "have-a-good-interview": "Tenha uma boa entrevista",
            question: "Questão",
            of: "de",
            disc_behavioural_profile: "Perfil Comportamental DISC",
            "you-are-here-to-discover-your-behavioral-disc-profile":
                "Você está aqui para descobrir seu perfil comportamental DiSC.",
            "what-is-it-for": "Para que serve",
            "your-behavioral-profile-will-help-you-understand-how you-work-on-a-team-and-even-in-life-in-general":
                "Seu perfil comportamental vai te ajudar a entender como você se comporta em um time e na vida de forma geral.",
            "lets-begin": "Vamos começar",
            "there-will-be-28-quick-questions": "Serão 28 questões rápidas",
            "see-more-instructions-next": "Veja mais instruções a seguir",
            "skip-and-start-the-test": "Pular e começar o teste",
            "youve-completed-more-than-half-of-the-test":
                "Você já completou mais da metade do teste",
            "How_was_the_test?": "Como foi realizar o teste?",
            thanks_for_your_feedback: "Agradecemos pelo feedback!",
            we_will_do_our_best_to_improve_your_user_experience:
                "Vamos dar o máximo para melhorar sua experiência de uso",
            understood: "Entendi",
            start: "Começar",
            disc_invalid_profile_message:
                "Com base nas respostas fornecidas, não foi possível enquadrar seu perfil comportamental nos padrões estabelecidos pela metodologia DISC.",
            remake_evaluation:
                "Precisamos que você refaça a avaliação para que seu perfil seja identificado e você avance neste processo de Recrutamento e Seleção no qual está candidatado.",
            disc_denied_message_intro:
                "Por enquanto, não é necessário realizar seu DISC novamente!",
            disc_denied_message_body:
                "Você respondeu ao inventário comportamental DISC nos últimos 6 meses e o seu último resultado ainda está dentro da validade.",
            disc_denied_message_conclusion:
                "Essa medida é adotada para assegurar a consistência e transparência do nosso processo. Agradecemos muito pela sua compreensão!",
            execution_date: "Realizado em",
            valid_until: "Vigente até",
        },
    },
    evaluation: {
        expired: {
            title: "Prazo Expirado",
            description:
                "Infelizmente o prazo para responder o teste já venceu",
        },
        form: {
            welcome: "Seja bem-vindo a sua avaliação.",
            instructions: "Clique em continuar para iniciar o seu teste",
            attention: "Atenção",
            forward_without_answering: "Deseja avançar sem responder?",
            not_possible_return: "Não será possível voltar nessa questão",
            "choose-a-quiet-and-well-lit-place":
                "Escolha um local tranquilo e bem iluminado",
            "if-possible-use-wifi-or-broadband-connection":
                "Se possível, use wifi ou banda larga",
            "so-you-can-focus-on-what-really-matter":
                "Assim você pode focar no que realmente importa",
            "this-way-you-avoid-problems-with-slowness-during-the-interview":
                "Assim você evita problemas com lentidão durante a entrevista",
            "got-it": "Entendi",
            start: "Começar",
            "video-interview": {
                "fail-system-requirements":
                    "Infelizmente o seu navegador não suporta este serviço",
                welcome: "Seja bem-vindo a sua avaliação.",
                instructions:
                    "Por favor, clique em continuar e siga as instruções.",
                hello_user: "Olá, {0}!",
                lets_try_again: "Começar novamente",
                warning_recording_again:
                    "O vídeo gravado anteriormente para a pergunta não foi salvo no sistema, por favor tente novamente",
                "time-to-response": "para resposta",
                minutes: "{0} min.",
                seconds: "{0} seg",
                "testing-your-camera-and-audio":
                    "Testando a sua câmera e microfone",
                "testing-your-camera-and-audio-description":
                    "Grave um vídeo curto de você falando para conferir sua imagem e aúdio.",
                "testing-your-camera": "Testando a sua câmera",
                "i-can-see-me-and-the-quality-is-good":
                    "Me vejo e a qualidade está boa.",
                "testing-your-audio": "Testando o seu áudio",
                "i-can-hear-me-and-the-quality-is-good":
                    "Me ouço e a qualidade está boa.",
                congratulations: "Parabéns {0}",
                "you-finished": "Você concluiu o questionário",
                "wait-return": "Aguarde o retorno do selecionador",
                "to-start": "para começar",
                "answer-now": "Responder agora",
                start: "Começar",
                continue: "Continuar",
                "ok-already-answered": "Pronto, já respondi",
                "all-working": "Tudo funcionando",
                "have-a-good-interview": "Tenha uma boa entrevista",
                question: "Questão",
                of: "de",
            },
            "browser-not-supported": "Navegador não suportado",
            "please-use-one-of-the-browsers-below":
                "Por favor, utilize um dos navegadores abaixo:",
        },
    },
    "test-alerts": {
        "test-cam": "Testar câmera",
        "test-cam-mic": "Testar câmera e microfone",
        "test-mic": "Testar microfone",
    },
    styleguide: {
        spinbutton: {
            field: {
                label: "Form Field Spinbutton",
                placeholder: "",
                description: "Use os campos abaixo para ajustar o componente",
            },
            step: {
                label: "Step",
                placeholder: "",
                description: "",
            },
            min: {
                label: "Min",
                placeholder: "",
                description: "",
            },
            max: {
                label: "Max",
                placeholder: "",
                description: "",
            },
        },
    },

    /*
        'landingPage': {
            'environmentTitle': 'Ambiente de Trabalho',
            'employeesTitle': 'Nossos Colaboradores',
            'home': 'Início',

            'about': 'Sobre nós',
            'profile': 'Meu Perfil',
            'jobNotFound': 'Não encontrou sua vaga',
            'sendResume': 'Envie seu currículo',
            'aboutThe': 'Sobre a ',
            'mainJobs': 'Vagas em destaque',
            'search': 'Buscar',
            'jobsLowerCase': 'vagas',
            'values': 'Nossos Valores'
        },

        'landingPageJob': {




            'relatedJobs': 'Vagas relacionadas',
            'apply': 'Candidatar-se',
            'about': 'Sobre nós',
            'responsabilities': 'Responsabilidades e atribuições',
            'requirements': 'Requisitos e qualificações',
            'desirable': 'Desejável',
            'benefits': 'Benefícios',
            'additionalInfo': 'Informações adicionais',
            'period': 'Período',
            'jobsQuantity': 'Quantidade de vagas'		'myApplications': {
            'myApplications': 'Minhas Vagas',
        },
        'application': {
            'curriculum-step' : 'Preenchimento do Currículo',
            'application': 'Candidatura',
            'resume': 'Currículo',passwords.sent
            'fillResume': 'Preencha os dados específicos para a vaga',
            'isRelated': 'Você tem grau de parentesco com algum colaborador',
            'yes': 'Sim',
            'no': 'Não',
            'isIndicated': 'Você foi indicado para esta vaga',
            'indicationName': 'Informe o nome de quem indicou você',
            'name': 'Nome',
            'jobDetails': 'Ver detalhes da vaga',
            'short': 'Resumo',
            'editInfo': 'Você pode editar as informações antes de confirmar sua candidatura',
            'otherData': 'Outros dados',
            'confirmApplication': 'Confirmar candidatura',
            'confirmCancelTitle': 'Cancelar candidatura?',
            'confirmCancelText': 'Quer mesmo sair?',
            'confirmCancelComplement': 'Sua candidatura será cancelada',
            'yesCancel': 'Sim, cancelar',
            'noCancel': 'Continuar'
        },


        'myApplications': {
            'myApplications': 'Minhas Vagas',
            'quit': 'Desistir',
            'seeProgress': 'Ver progresso',
            'noVacancyEnrolled' : 'Você não está participando de nenhuma vaga',
            'searchForVacancies' : 'Buscar Vagas'

        },
        'admission': {
            'sendFile': 'Enviar arquivo',
            'front': 'Frente',
            'back': 'Verso',
            'basic': {
                'title': 'Dados Básicos',
                'name': 'Nome completo',
                'nickname': 'Apelido',
                'rg': 'RG',
                'rgSection': 'Dados do RG',
                'rgState': 'Estado Emissor RG',
                'rgCity': 'Cidade emissora RG',
                'rgEmitter': 'Órgão Emissor',
                'rgDate': 'Data de emissão RG',
                'birthdate': 'Data de nascimento',
                'birthState': 'Estado de nascimento',
                'birthCity': 'Cidade de nascimento',
                'mother': 'Nome da mãe',
                'motherNotDeclared': 'Nome da mãe não declarado no documento',
                'father': 'Nome do pai',
                'fatherNotDeclared': 'Nome do pai não declarado no documento',
                'gender': 'Sexo',
                'ethnicity': 'Raça/Cor',
                'photo': 'Foto',
                'uploadRG': 'Anexar RG',
                'maritalStatus': 'Estado Civil',
                'address': 'Comprovante de Endereço',
                'uploadAddress': 'Anexar comprovante de endereço',
            },
            'professional': {
                'title': 'Carteira de Trabalho',
                'hasProfessionalCard': 'Possui Carteira de Trabalho',
                'professionalCardType': 'Tipo de Carteira de Trabalho',
                'physics': 'Física',
                'digital': 'Digital',
                'ctpsNumber': 'CTPS Número',
                'ctpsSerie': 'CTPS Série',
                'ctpsCity': 'CTPS Cidade',
                'ctpsState': 'CTPS Estado',
                'ctpsDate': 'CTPS Data Emissão',
                'isFirstJob': 'Será seu primeiro emprego formal?',
                'hasPisPasep': 'Possui PIS?',
                'pisNumber': 'PIS/Pasep Número',
                'pisDate': 'PIS/Pasep Data',
                'uploadCTPS': 'Anexar CTPS',
                'uploadPIS': 'Anexar PIS',
                'professionalTip': 'A CTPS é um documento obrigatório para a sua contratação, caso não esteja de posse do documento, gentileza entrar em contato URGENTE com o(a) Recrutador(a) responsável pelo seu processo seletivo.'
            },
            'voter': {
                'title': 'Título de Eleitor',
                'hasVoter': 'Possui Título de Eleitor?',
                'voterNumber': 'Número',
                'voterZone': 'Zona',
                'voterSection': 'Seção',
                'voterState': 'Estado',
                'voterCity': 'Cidade',
                'voterDate': 'Data de Emissão',
                'uploadVoter': 'Anexar Comprovante Título de Eleitor'
            },
            'reservist': {
                'title': 'Certificado de Reservista',
                'hasReservist': 'Possui Certificado de Reservista?',
                'resevistRA': 'Número RA',
                'reservistSerie': 'Série',
                'reservistEmitter': 'Órgão',
                'reservistUF': 'UF',
                'reservistType': 'Tipo',
                'reservistCategory': 'Categoria',
                'reservistDate': 'Data de Emissão',
                'uploadReservist': 'Anexar Certificado de Reservista'
            },
            'driver': {
                'title': 'Carteira de Motorista',
                'hasDriverLicense': 'Possui Carteira de Motorista?',
                'driverNumber': 'Número',
                'driverLocal': 'Local',
                'driverType': 'Tipo de CNH',
                'driverDate': 'Data de Emissão',
                'driverExpirationDate': 'Data de Validade',
                'driverFirstDate': 'Primeira emissão',
                'uploadDriver': 'Anexar CNH (documento aberto)',
            },
            'bank': {
                'title': 'Dados Bancários',
                'hasBankAccount': 'Possui Conta Bancária no seu nome?',
                'bankName': 'Nome do Banco',
                'accountType': 'Tipo de Conta',
                'accountName': 'Nome do Titular',
                'accountPersonType': 'Tipo de Titular',
                'accountAgency': 'Agência',
                'accountNumber': 'Conta (com o dígito)',
                'accountBankingOperation': 'Operação Bancária',
                'uploadBank': 'Anexar Comprovante Conta Bancária',
                'PF': 'Pessoa Física',
                'PJ': 'Pessoa Jurídica',
                'isSelectyCorp': 'Esta conta foi aberta para uma oportunidade na Selecty Corp',
                'bankTip': 'A Selecty Corp possui parceria bancária com o Santander, Itaú e Bradesco. São aceitas as contas Correntes, em nome do Colaborador, e as contas do tipo Salário abertas especificamente para a oportunidade de contratação pelo Selecty Corp. São documentos válidos para comprovação bancária: Frente e Verso do Cartão, Extrato bancário atualizado, Comprovante de abertura da conta, Print de tela do aplicativo, desde que, conste o nome do titular e os dados de Banco, Agência e número da conta'
            },
            'dependent': {
                'title': 'Dependentes',
                'addTitle': 'Adicionar dependente',
                'name': 'Nome completo',
                'birthdate': 'Data de nascimento',
                'kinship': 'Grau de parentesco',
                'uploadBirthCertificate': 'Anexar Certidão de Nascimento',
                'uploadCPF': 'Anexar CPF do Dependente',
                'uploadScholarship': 'Anexar Declaração de Matrícula Escolar',
                'uploadGraduate': 'Anexar Comprovante de matrícula Universitária',
                'uploadEconomic': 'Anexar Declaração de Dependência Econômica Registrada em Cartório',
                'uploadGuardianship': 'Anexar Termo de Tutela',
                'uploadVaccination': 'Anexar Carteirinha de Vacinação',
            }
        },
        'profile' : {
            'computing': {
                'title': 'Informática',
                'basic': 'Conhecimento básico',
                'intermediate': 'Conhecimento intermediário',
                'advanced': 'Conhecimento avançado',
            },
            'presentationLetter': {
                'title': 'Carta de apresentação'
            },

        },
        'chat': {
            'startConversation': 'Iniciar Conversa',
            'send': 'Enviar'
        },
        'config': {
            'title': 'Configurações da conta',
            'shortTitle': 'Conta'
        },
        'lgpd':{
            'title' : 'Formulário de Requisição de Direitos do Titular de Dados Pessoais',
            'subtitle' : 'Orientações Gerais Disponibilizamos este formulário fundamentado legalmente pelo Art.18 da LGPD, para facilitar o exercício dos seus direitos previstos na Lei Geral de Dados Pessoais (LGPD). Lembramos de que a utilização deste formulário é facultativa, mas se trata de uma forma eficiente e segura para o processamento do seu pleito. Após o preenchimento do formulário ele será encaminhado, juntamente com o(s) comprovante(s) de identidade do requerente, para o e-mail privacidade@selecty.com.br.',
        },

        'video-interview' : {
            'welcome' : 'Seja bem-vindo a sua avaliação.',
            'instructions' : 'Por favor, clique em continuar e siga as instruções.',
            'hello_user' : "Olá {0}",
            "lets_try_again":"Começar novamente",
            "warning_recording_again": "O vídeo gravado anteriormente para a pergunta não foi salvo no sistema, por favor tente novamente",
            'time-to-response' : 'para resposta',
            'minutes' : '{0} min.',
            'seconds' : '{0} seg',
            'testing-your-camera' : 'Testando a sua câmera',
            'i-can-see-me-and-the-quality-is-good' : 'Me vejo e a qualidade está boa.',
            'testing-your-audio' : 'Testando o seu áudio',
            'i-can-hear-me-and-the-quality-is-good' : 'Me ouço e a qualidade está boa.',
            'congratulations' : 'Parabéns {0}',
            'you-finished' : 'Você concluiu o questionário',
            'wait-return' : 'Aguarde o retorno do selecionador',
            'to-start' : 'para começar',
            'answer-now' : 'Responder agora',
            'start' : 'Começar',
            'continue': 'Continuar',
            'ok-already-answered' : 'Pronto, já respondi',
            'all-working' : 'Tudo funcionando',
            'have-a-good-interview' : 'Tenha uma boa entrevista',
            'question': "Questão",
            'of' : 'de'
        },
        'disc' : {
            'profile' : {
                'achiever': 'Realizador',
                'agent': 'Representante',
                'appraiser': 'Avaliador',
                'counselor': 'Conselheiro',
                'creative': 'Criativo',
                'developer': 'Desbravador',
                'inspirational': 'Inspirador',
                'investigator': 'Investigador',
                'objective thinker': 'Pensador Objetivo',
                'overshift': 'Expandido',
                'perfectionist': 'Perfeccionista',
                'persuader': 'Convincente',
                'pratitioner': 'Praticante',
                'promoter': 'Promotor',
                'result-oriented': 'Orientado a Resultados',
                'specialist': 'Especialista',
                'tight': 'tight',
                'undershift': 'Comprimido'
            }
        },
        */
    //},
};
