// en, de, fr, br
import validationMessages from "vee-validate/dist/locale/es";
export default {
    //es: {
    validations: {
        messages: {
            ...validationMessages.messages,
            "email-or-cpf": "El campo {_field_} no es un E-MAIL o CPF válido",
            cpf: "El campo {_field_} tiene un formato no inválido",
            cnpj: "El campo {_field_} tiene un formato no inválido",
            "zip-code": "El campo {_field_} tiene un formato no inválido",
            "full-name": "El campo {_field_} es inválido",
            date: "El campo {_field_} es una fecha no válida",
            "before-date":
                "La fecha ingresada es posterior a la fecha de finalización",
            "after-date": "La fecha ingresada es anterior a la fecha de inicio",
        },
    },
    files: {
        remove: {
            done: "Archivo eliminado con éxito",
            fail: "No pudimos eliminar su archivo. Inténtalo de nuevo",
        },
    },
    changeable: {
        "indicator-text":
            "Cambios guardados | {cuenta} cambio pendiente | {cuenta} cambios pendientes",
    },
    errors: {
        title: { danger: "Error", warning: "Aviso", info: "Información" },
        "default-title": "Error",
        curriculum: { "not-found": "Currículum no encontrado" },
        registration: {
            message: "No pudimos completar su registro. Inténtalo de nuevo.",
            "account-existent":
                "¡Ya tienes una cuenta! Inicia sesión para continuar.",
        },
        enroll: {
            "not-found": "Vacante no encontrada",
            "already-applied": "Ya estás solicitando esta vacante",
            forbidden: "No puede postularse para esta vacante",
        },
        account: {
            error: "Falha ao enviar email de reset, tente novamente.",
            "not-found":
                "Conta não encontrada, verifique se o CPF foi digitado corretamente.",
            "password-reset-throttled":
                "Um e-mail de reset foi enviado há poucos instantes, aguarde 10 minutos para tentar novamente.",
        },
    },
    common: {
        yes: "Sí",
        no: "No",
        male: "Masculino",
        female: "Femenino",
        required: "Campo obligatorio",
        edit: "Edición",
        save: "Ahorrar",
        cancel: "Cancelar",
        delete: "Eliminar",
        advance: "Avance",
        "no-results-found": "Ningún resultado encontrado",
        see: "Ver-Mirar",
        or: "o",
        description: "Descripción",
        progress: "Progreso",
        join: "Ingressar",
        join_with_sj: "Iniciar sesión con SelectyJobs",
        join_with_facebook: "Iniciar sesión con Facebook",
        join_with_linkedin: "Iniciar sesión con LinkedIn",
        join_with_google: "Iniciar sesión con Google",
        join_with_ad: "Iniciar sesión con Active Directory",
        please_type_captcha_correctly:
            "Por favor, preencha o captcha corretamente.",
        to_continue_auth: "Para continuar, selecione uma das opções abaixo",
        register: "Registrarse",
        create_account: "Crear cuenta",
        create_profile: "Crear perfil",
        lets_begin: "¡Vamos a empezar! ",
        lets_begin_subtitle: "Ingrese la información básica a continuación:",
        jobs: "Vacantes",
        show: "Mostrar",
        hide: "Ocultar",
        hello: "Hola",
        search: "Buscar",
        complete_curriculum: "Completa mi currículum",
        follow_process: "Seguimiento del proceso de selección",
        curriculum: "Curriculum",
        admissional: "Admisión",
        logout: "Cerrar sesión",
        begin: "Inicio",
        vacancy: "Vacantes",
        messages: "Mensajes",
        account: "Cuenta",
        email: "Correo electrónico",
        password: "Contraseña",
        change: "Cambiar",
        close: "Cerrar",
        captcha_error: "Por favor complete el captcha correctamente.",
        search: "Buscar",
        for_companies: "Para empresas"
    },
    passwords: {
        sent: "Correo electrónico enviado correctamente.",
        user: "Correo electrónico enviado correctamente.",
        token: '"Token inválido. Por favor',
    },
    password_level: {
        very_week: "Muy débil",
        week: "Débil",
        regular: "Regular",
        strong: "Muy fuerte",
    },
    forms: {
        fields: {
            upload: "Adjuntar archivo",
            allowed_extensions: "Extensiones permitidas",
            default_error: '"No se puede enviar el archivo. Por favor',
        },
    },
    customize: {
        type: { placeholder: "Seleccionar" },
        base_theme: {
            label: "Tema principal",
            placeholder: "Seleccione el tema principal",
            description: "Texto de ayuda del tema principal",
        },
        "primary-color": {
            label: "Color primario",
            placeholder: "Seleccione el color primario",
            description: "Texto de ayuda de colores primarios",
        },
        title: {
            label: "Título",
            placeholder: "Escriba el título",
            description: "Texto de ayuda del título",
        },
    },
    user: {
        login: {
            email: {
                label: "Correo electrónico",
                placeholder: "",
                description: "",
            },
            is_foreigner: {
                label: "Soy extranjero",
                placeholder: "",
                description: "",
            },
            login: {
                label: "CPF",
                placeholder: "",
                description: "",
            },
            password: {
                label: "Contraseña",
                placeholder: "",
                description: "",
            },
            submit: "Ingressar",
            linked: "Ingresar con LinkedIn",
            sj: "Ingresar con SelectyJobs",
            joinMessage: "Ingrese para continuar",
            needAccount: "No está registrado",
            create_account: "Crear una cuenta",
            forgotPassword: "Olvidé mi contraseña",
            invalidEmailOrPassword:
                "Correo electrónico y/o contraseña (s) inválida (s).",
            forgotUsername: "¿Olvidó sus datos de acceso?",
        },
        forgot: {
            login: {
                label: "CPF",
                placeholder: "Escriba su CPF",
                description: "",
            },
            nextStep: "Próximo",
            title: "¿Olvidó su contraseña?",
            info: "No hay problema. Te enviaremos un correo electrónico con instrucciones para crear una nueva contraseña.",
            submit: "Crear una nueva contraseña",
            return_to: "Volver a Inicio de sesión",
            email_sent: "Correo electrónico enviados!",
            email_instructions:
                "Hemos enviado un correo electrónico a <b>{login}</b> con instrucciones para crear una nueva contraseña.",
            email_spam_instructions:
                "Si el correo electrónico tarda demasiado en llegar",
        },
        forgotAccount: {
            title: "¿Ha olvidado sus datos de acceso?",
            info: '"No hay problema',
        },
        confirmAccountData: {
            title: "¡Hemos encontrado su cuenta!",
            info: "Confirmaremos algunos datos antes de recuperar su cuenta.",
            cellphone:
                "Confirme a continuación el teléfono móvil registrado en su cuenta:",
            birth_date: "Confirme a continuación su fecha de nacimiento:",
            email: "Confirme a continuación la dirección de correo electrónico registrada en su cuenta:",
        },
        reset: {
            title: "Crear una nueva contraseña",
            info: "¡Casi allí! Ahora solo ingrese una nueva contraseña.",
            submit: "Cambiar mi contraseña",
            return_to: "Vuelve a iniciar sesión",
        },
        reset_email: {
            title: "Registre un nuevo correo electrónico",
            info: "¡Casi allí! Ahora solo registre un nuevo correo electrónico para enviar el restablecimiento de contraseña.",
            submit: "Cambiar e-mail",
            return_to: "Vuelve a iniciar sesión",
        },
    },
    curriculum: {
        title: "Curriculum",
        sections: {
            personal: {
                title: "Datos personales",
                address: "Datos de dirección",
                contacts: "Dato de contacto",
                diversity: "Diversidad e inclusión",
                diversity_subtitle:
                    "Valoramos la diversidad en el lugar de trabajo, por lo que puede proporcionar la siguiente información:",
                extra: "Campos Adicionales",
                cnh: "Licencia de conducir",
                login: "Su nombre de usuario",
                bnt_ready: "Todo listo",
                confirm: "Confirmar",
                extra_by_company: "Campos Adicionales {company}"
            },
            parsing: {
                title: "Adjunte su curriculum para cumplimentación automática",
                btn_upload_curriculum:
                    "Rellénalo por mí - Currículum de importación",
            },
            professional: {
                title: "Datos profesionales",
                experiences: "Tus experiencias",
                objectives: "Objetivos",
                experience: "Trayectoria profesional",
                add_experience: "Añade tus experiencias profesionales",
            },
            education: {
                title: "Educación",
                academic: "Su escolaridad",
                training: "Capacitación complementaria",
                languages: "Lengua",
                computing: "Conocimiento en informática",
            },
            dependents: { title: "Dependientes" },
            other_information: { title: "Otras informaciones" },
            additional: { title: "Informaciones adicionales" },
            presentation: { title: "Presentación" },
        },
        actions: {
            professional: { add_experience: "Añadir experiencia" },
            education: {
                add_academic: "Añadir educación",
                add_training: "Añadir entrenamiento",
                add_language: "Añadir lengua",
            },
            dependents: { add_dependent: "Añadir dependiente" },
        },
        texts: {
            professional: { activities: "Actividades" },
            education: {
                academic: {
                    time: {
                        morning: "Mañana",
                        afternoon: "Tarde",
                        night: "Noche",
                    },
                    period: { period: "No. - Periodo" },
                    situation: {
                        studying: "Estudiando",
                        completed: "Completado",
                        stopped: "Interrumpido",
                    },
                },
                none: "Ningún",
                basic: "Básico",
                intermediate: "Intermediario",
                advanced: "Avanzado",
                fluent: "Fluido",
            },
            dependents: {
                tokens: {
                    a: "Niño válido",
                    b: "Niño incapaz de trabajar",
                    c: "Cónyuge",
                    d: "Padre",
                    e: "Madre",
                    f: "Suegro",
                    g: "Otros",
                    h: "Abuelo (Ó)",
                    i: "Compañero",
                    j: "Hijastro",
                    k: "Excluido",
                    l: "Ex cónyuge",
                    m: "Hermana válida",
                    n: "Hermana incapaz de trabajar",
                    o: "Ex-compañero",
                    p: "Ex-suegro",
                    q: "Nieto",
                    r: "Ex-hijastro",
                },
            },
        },
        sensitive: {
            login_username: {
                label: "Correo electrónico o CPF",
                placeholder: "",
                description: "",
            },
            primary_email_confirmation: {
                label: "Confirmación de correo electrónico",
                placeholder: "Confirme su correo electrónico",
                description: "",
            },
            password: {
                label: "Contraseña",
                placeholder: "Escriba una contraseña",
                description: "",
            },
            password_confirmation: {
                label: "Confirmación de contraseña",
                placeholder: "Confirme la contraseña introducida",
                description: "",
            },
        },
        personal: {
            is_foreigner: {
                label: "Soy extranjero",
                placeholder: "",
                description: "",
            },
            primary_email: {
                label: "Correo electrónico",
                placeholder: "Introduce tu correo electrónico",
                description: "",
            },
            name: {
                label: "Nombre completo",
                placeholder: "Ingresa tu nombre completo",
                description: "",
            },
            assumed_name: {
                label: "Nombre social",
                placeholder: "",
                description: "",
            },
            photo3x4_file_id: {
                label: "Foto 3x4",
                placeholder: "",
                description: "",
            },
            cpf: {
                label: "CPF",
                placeholder: "Ingrese su CPF",
                description: "",
            },
            gender: {
                label: "Género",
                placeholder: "Sexo",
                description: "",
            },
            birth_date: {
                label: "Fecha de nacimiento",
                placeholder: "DD/MM/AAAA",
                description: "",
            },
            cellphone: {
                label: "Teléfono móvil",
                placeholder: "Teléfono móvil",
                description: "",
            },
            home_phone: {
                label: "Teléfono residencial",
                placeholder: "Teléfono residencial",
                description: "",
            },
            special_needs: {
                label: "Tiene alguna discapacidad",
                placeholder: "Seleccione",
                description: "",
            },
            special_needs_details: {
                label: "Especifique",
                placeholder: "Seleccione",
                description: "Descripción de PCD",
            },
            special_needs_file: {
                label: "Adjuntar informe",
                description: "Adjuntar informe/certificado de rehabilitación",
                placeholder: "Adjuntar informe",
            },
            rg: { label: "RG", placeholder: "RG", description: "" },
            state_rg: {
                label: "Estado de emisión RG",
                placeholder: "Emisión RG",
                description: "",
            },
            civil_status: {
                label: "Estado civil",
                placeholder: "Estado civil",
                description: "",
            },
            cnh_type: {
                label: "Tipo de CNH",
                placeholder: "Tipo de CNH",
                description: "",
            },
            children: {
                label: "Cantidad de niños",
                placeholder: "Cantidad de niños",
                description: "",
            },
            business_phone: {
                label: "Teléfono comercial",
                placeholder: "Teléfono comercial",
                description: "",
            },
            message_with: {
                label: "Contacto para mensajes",
                placeholder: "Contacto para mensajes",
                description: "",
            },
            secondary_email: {
                label: "Correo electrónico secundario",
                placeholder: "Correo electrónico secundario",
                description: "",
            },
            site: {
                label: "sitio",
                placeholder: "sitio",
                description: "",
            },
            avatar: {
                label: "Foto de perfil",
                placeholder: "",
                description: "Inserte una imagen aquí",
            },
            cv_external: {
                label: "Curiculum adjunto",
                placeholder: "",
                description: "",
            },
            cpf_file_id: {
                label: "Adjuntar CPF",
                placeholder: "",
                description: "",
            },
            city_rg: {
                label: "Ciudad de emisión RG",
                placeholder: "",
                description: "",
            },
            date_of_issue_rg: {
                label: "Fecha de expedición RG",
                placeholder: "",
                description: "",
            },
            rg_file_id: {
                label: "Adjunte RG (frente)",
                placeholder: "",
                description: "",
            },
            rg_file2_id: {
                label: "Adjuntar RG (atrás)",
                placeholder: "",
                description: "",
            },
            birth_file_id: {
                label: "Adjuntar certificado de nacimiento",
                placeholder: "",
                description: "",
            },
            nationality: {
                label: "Nacionalidad",
                placeholder: "",
                description: "",
            },
            born_state: {
                label: "Estado de nacimiento",
                placeholder: "",
                description: "",
            },
            born_city: {
                label: "Ciudad de nacimiento",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Nombre de la madre",
                placeholder: "",
                description: "",
            },
            mother_cpf: {
                label: "CPF de la madre",
                placeholder: "",
                description: "",
            },
            father_name: {
                label: "Nombre del Padre",
                placeholder: "",
                description: "",
            },
            father_cpf: {
                label: "CPF del padre",
                placeholder: "",
                description: "",
            },
            racial: {
                label: "Raza / color",
                placeholder: "",
                description: "",
            },
            national_health_service_number: {
                label: "Número de tarjeta SUS",
                placeholder: "",
                description: "",
            },
            national_health_service_number_file_id: {
                label: "Adjuntar tarjeta SUS",
                placeholder: "",
                description: "",
            },
            professional_number: {
                label: '"Número de registro profesional (Ej: CRM',
                placeholder: "",
                description: "",
            },
            professional_file_id: {
                label: "Adjuntar registro profesional",
                placeholder: "",
                description: "",
            },
        },
        address: {
            country: {
                label: "País",
                placeholder: "Seleccione el país",
                description: "",
            },
            zip_code: {
                label: "Código postal",
                placeholder: "Escriba su código postal",
                description: "",
            },
            street_type: {
                label: "Tipo de vía pública",
                placeholder: "Tipo de vía pública",
                description: "",
            },
            street: {
                label: "Vía pública",
                placeholder: "Vía pública",
                description: "",
            },
            number: {
                label: "Número",
                placeholder: "Número",
                description: "",
            },
            complement: {
                label: "Complemento",
                placeholder: "Complemento",
                description: "",
            },
            district: {
                label: "Vecindario",
                placeholder: "Vecindario",
                description: "",
            },
            state: {
                label: "Estado",
                placeholder: "Selecciona un Estado",
                description: "",
            },
            city: {
                label: "Ciudad",
                placeholder: "Seleccione una ciudad",
                description: "",
            },
        },
        diversity: {
            racial: {
                label: "Color/raza",
                description: "Esta información es obligatoria para E-social.",
                placeholder: "",
            },
            assumed_name: {
                label: "Nombre social",
                description: "",
                placeholder: "",
            },
            gender_identity: {
                label: "Identidad de género",
                description: "",
                placeholder: "",
            },
            gender_orientation: {
                label: "Orientación sexual",
                description: "",
                placeholder: "",
            },
        },
        professional: {
            intended_occupation: {
                label: "Puestos de trabajo previstos",
                placeholder: "",
                description: "(máximo de 3 niveles)",
            },
            interest_levels: {
                label: "Nivel de interes",
                placeholder: "",
                description: "(máximo de 3 niveles)",
            },
            interest_areas: {
                label: "Area de interes",
                placeholder: "",
                description: "(máximo 3 puestos de trabajo)",
            },
            salary_intended: {
                label: "Pretensión salarial",
                placeholder: "Pretensión salarial",
                description: "",
            },
            available_trip: {
                label: "¿Disponibilidad para viajar?",
                placeholder: "",
                description: "",
            },
            has_professional_history: {
                label: "¿Tiene experiencia profesional?",
                placeholder: "",
                description: "",
            },
            experiences: {
                company_id: {
                    label: "Empresa",
                    placeholder: "Empresa",
                    description: "",
                },
                occupation_id: {
                    label: "Puesto",
                    placeholder: "Puesto",
                    description: "",
                },
                start: {
                    label: "Inicio",
                    placeholder: "DD/MM/AAAA",
                    description: "",
                },
                finish: {
                    label: "Fin",
                    placeholder: "DD/MM/AAAA",
                    description: "",
                },
                last_salary: {
                    label: "Último Salario",
                    placeholder: "Último Salario",
                    description: "",
                },
                current_job: {
                    label: "Empleo actual",
                    placeholder: "Empleo actual",
                    description: "",
                },
                acting_area: {
                    label: "Segmento",
                    placeholder: "Segmento",
                    description: "",
                },
                company_size: {
                    label: "Tamaño de la empresa",
                    placeholder: "Tamaño de la empresa",
                    description: "",
                },
                activities: {
                    label: "Actividades realizadas",
                    placeholder: "Actividades realizadas",
                    description: "",
                },
            },
        },
        education: {
            has_schooling_level: {
                label: "¿Tiene formación académica?",
                placeholder: "",
                description: "",
            },
            academic: {
                education_level_id: {
                    label: "Nivel de educación",
                    description: "",
                    placeholder: "Seleccione el nivel",
                },
                course_id: {
                    label: "Curso",
                    description: "",
                    placeholder: "",
                },
                period: {
                    label: "Periodo",
                    description: "",
                    placeholder: "",
                },
                time: {
                    label: "Horario",
                    description: "",
                    placeholder: "",
                },
                institution: {
                    label: "Institución",
                    description: "",
                    placeholder: "Institución",
                },
                status: {
                    label: "Situación",
                    description: "",
                    placeholder: "Seleccione la situación",
                },
                start_date: {
                    label: "Fecha de inicio",
                    placeholder: "",
                    description: "",
                },
                conclusion_date: {
                    label: "Fecha de fin",
                    placeholder: "",
                    description: "",
                },
            },
            training: {
                additional_training_id: {
                    label: "Capacitación",
                    placeholder: "Capacitación",
                    description: "",
                },
                description: {
                    label: "Actividad",
                    placeholder: "Actividad",
                    description: "",
                },
                institution: {
                    label: "Institución",
                    placeholder: "Institución",
                    description: "",
                },
                status: {
                    label: "Situación",
                    placeholder: "Situación",
                    description: "",
                },
                start_date: {
                    label: "Período (inicio)",
                    placeholder: "Período (inicio)",
                    description: "",
                },
                finish_date: {
                    label: "Período (fin)",
                    placeholder: "Período (fin)",
                    description: "",
                },
            },
            languages: {
                language_id: {
                    label: "Lengua",
                    placeholder: "Lengua",
                    description: "",
                },
                speaking: {
                    label: "Habla",
                    placeholder: "Habla",
                    description: "",
                },
                writing: {
                    label: "Escribir",
                    placeholder: "Escribir",
                    description: "",
                },
                understanding: {
                    label: "Comprensión",
                    placeholder: "Comprensión",
                    description: "",
                },
                reading: {
                    label: "Lectura",
                    placeholder: "Lectura",
                    description: "",
                },
            },
        },
        computing: {
            title: "Informática",
            label: "Saddasdasdasdssa",
            basic: {
                label: "Básico",
                placeholder: "Básico",
                description: "",
            },
            intermediate: {
                label: "Intermediario",
                placeholder: "Intermediario",
                description: "",
            },
            advanced: {
                label: "Avanzado",
                placeholder: "Avanzado",
                description: "",
            },
        },
        additional: {
            abroad_experience: {
                label: "Experiencia en el extranjero",
                placeholder: "Experiencia en el extranjero",
                description: "",
            },
            has_abroad_experience: {
                label: "Tiene experiencia en el extranjero",
                placeholder: "Tiene experiencia en el extranjero",
                description: "",
            },
            description: {
                label: "Descripción",
                placeholder: "Descripción",
                description: "",
            },
            complementary_activities: {
                label: "Actividades complementares",
                placeholder: "Actividades complementares",
                description: "",
            },
            sports: {
                label: "Deportes",
                placeholder: "Deportes",
                description: "",
            },
            hobby: {
                label: "Aficiones",
                placeholder: "Aficiones",
                description: "",
            },
            music: {
                label: "Música",
                placeholder: "Música",
                description: "",
            },
            passions: {
                label: "Pasiones",
                placeholder: "Pasiones",
                description: "",
            },
            books: {
                label: "Libros",
                placeholder: "Libros",
                description: "",
            },
            social_work: {
                label: "Trabajo Social",
                placeholder: "Trabajo Social",
                description: "",
            },
            extra_informations: {
                label: "Informaciones adicionales",
                placeholder: "Informaciones adicionales",
                description: "",
            },
            cover_letter: {
                label: "Carta de presentación",
                placeholder: "Carta de presentación",
                description: "",
            },
        },
        ctps: {
            has_ctps: {
                label: "¿Tiene permiso de trabajo?",
                placeholder: "",
                description: "",
            },
            is_first_job: {
                label: "¿Será tu primer trabajo formal?",
                placeholder: "",
                description: "",
            },
            ctps_type: {
                label: "¿Tipo de permiso de trabajo?",
                placeholder: "",
                description: "",
            },
            ctps_number: {
                label: "Número CTPS",
                placeholder: "",
                description: "",
            },
            ctps_serial: {
                label: "Serie CTPS",
                placeholder: "",
                description: "",
            },
            ctps_state: {
                label: "Estado de CTPS",
                placeholder: "",
                description: "",
            },
            ctps_city: {
                label: "Ciudad de CTPS",
                placeholder: "",
                description: "",
            },
            ctps_date: {
                label: "Emisión de fecha de CTPS",
                placeholder: "",
                description: "",
            },
            ctps_file_id: {
                label: "Adjunte CTPS (frente)",
                placeholder: "",
                description: "",
            },
            ctps_file2_id: {
                label: "Adjuntar CTPS (Atrás)",
                placeholder: "",
                description: "",
            },
            ctps_file3_id: {
                label: "Adjunte el último registro de CTPS",
                placeholder: "",
                description: "",
            },
            has_pis: {
                label: "¿Tienes pis?",
                placeholder: "",
                description: "",
            },
            pis_pasep_number: {
                label: "Número de pis/pasep",
                placeholder: "",
                description: "",
            },
            pis_pasep_date: {
                label: "Datos de PIS/PASEP",
                placeholder: "",
                description: "",
            },
            pis_file_id: {
                label: "Adjuntar PIS",
                placeholder: "",
                description: "",
            },
        },
        voter: {
            has_voter_registration: {
                label: "¿Tiene título de elector?",
                placeholder: "",
                description: "",
            },
            voter_registration_number: {
                label: "Número",
                placeholder: "",
                description: "",
            },
            voter_registration_zone: {
                label: "Zona",
                placeholder: "",
                description: "",
            },
            voter_registration_section: {
                label: "Sección",
                placeholder: "",
                description: "",
            },
            voter_registration_state: {
                label: "Estado",
                placeholder: "",
                description: "",
            },
            voter_registration_city: {
                label: "Ciudad",
                placeholder: "",
                description: "",
            },
            voter_registration_date: {
                label: "Fecha de emisión",
                placeholder: "",
                description: "",
            },
            voter_registration_file_id: {
                label: "Adjunte el título de elector (frente)",
                placeholder: "",
                description: "",
            },
            voter_registration_file2_id: {
                label: "Adjunte el título de elector (atrás)",
                placeholder: "",
                description: "",
            },
        },
        armed_forces: {
            has_certificate_armed_forces: {
                label: "¿Posee Certificado de Reservista?",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_status: {
                label: "Situación",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service: {
                label: "RA Número",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_serie: {
                label: "Serie",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_organ: {
                label: "Órgano",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_uf: {
                label: "UF",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_type: {
                label: "Tipo",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_category: {
                label: "Categoría",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_date: {
                label: "Fecha de emisión",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file_id: {
                label: "Adjunte el certificado de reservista (frente)",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file2_id: {
                label: "Adjunte el certificado de reservista (atrás)",
                placeholder: "",
                description: "",
            },
        },
        cnh: {
            has_cnh: {
                label: "¿Tienes carnet de conducir?",
                placeholder: "",
                description: "",
            },
            cnh_number: {
                label: "Número",
                placeholder: "",
                description: "",
            },
            cnh_local: {
                label: "Local",
                placeholder: "",
                description: "",
            },
            cnh_organ: {
                label: "Órgano emisor",
                placeholder: "",
                description: "",
            },
            cnh_state: {
                label: "Estado de emisión",
                placeholder: "",
                description: "",
            },
            cnh_type: {
                label: "Tipo de CNH",
                placeholder: "",
                description: "",
            },
            cnh_date_emission: {
                label: "Fecha de emisión",
                placeholder: "",
                description: "",
            },
            cnh_date_validity: {
                label: "Fecha de validez",
                placeholder: "",
                description: "",
            },
            cnh_date_first_emission: {
                label: "Primera emisión",
                placeholder: "",
                description: "",
            },
            cnh_file_id: {
                label: "Adjunte CNH (frente)",
                placeholder: "",
                description: "",
            },
            cnh_file_2_id: {
                label: "Adjunte CNH (atrás)",
                placeholder: "",
                description: "",
            },
        },
        bank: {
            has_bank: {
                label: "¿Tiene una cuenta bancaria a su nombre?",
                placeholder: "",
                description: "",
            },
            bank_name: {
                label: "Nombre del banco",
                placeholder: "",
                description: "",
            },
            bank_type: {
                label: "Tipo de cuenta",
                placeholder: "",
                description: "",
            },
            bank_holder_name: {
                label: "Nombre del titular",
                placeholder: "",
                description: "",
            },
            bank_holder_type: {
                label: "Tipo de Titular",
                placeholder: "",
                description: "",
            },
            bank_agency: {
                label: "Agencia",
                placeholder: "",
                description: "",
            },
            bank_operation: {
                label: "Operación bancaria",
                placeholder: "",
                description: "",
            },
            account_file_id: {
                label: "Adjunte la cuenta bancaria de prueba (frente)",
                placeholder: "",
                description: "",
            },
            account_file2_id: {
                label: "Adjunte la cuenta bancaria de prueba (atrás)",
                placeholder: "",
                description: "",
            },
            bank_pix_type: {
                label: "Tipo de clave Pix",
                placeholder: "",
                description: "",
            },
            bank_pix_value: {
                label: "Clave PIX",
                placeholder: "",
                description: "",
            },
        },
        dependents: {
            name: {
                label: "Nombre completo",
                placeholder: "",
                description: "",
            },
            birth_date: {
                label: "Fecha de nacimiento",
                placeholder: "",
                description: "",
            },
            gender: { label: "Sexo", placeholder: "", description: "" },
            civil_status: {
                label: "Estado civil",
                placeholder: "",
                description: "",
            },
            kinship: {
                label: "Grado de parentesco",
                placeholder: "",
                description: "",
            },
            rg: { label: "RG", placeholder: "", description: "" },
            cpf: { label: "CPF", placeholder: "", description: "" },
            vaccine_number: {
                label: "Cartilla de vacunación",
                placeholder: "",
                description: "",
            },
            sus_card: {
                label: "Número de tarjeta SUS",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Nombre de la madre",
                placeholder: "",
                description: "",
            },
            income_tax: {
                label: "¿Quieres declararlo en tu impuesto sobre la renta?",
                placeholder: "",
                description: "",
            },
            life_insurance: {
                label: "¿Incluir a ese dependiente como beneficiario de su seguro de vida?",
                placeholder: "",
                description: "",
            },
            health_care: {
                label: "¿Incluir a este dependiente en el plan de asistencia médica?",
                placeholder: "",
                description: "",
            },
            file_1_id: {
                label: "Adjuntar certificado de nacimiento (frente)",
                placeholder: "",
                description: "",
            },
            file_2_id: {
                label: "Adjuntar certificado de nacimiento (atrás)",
                placeholder: "",
                description: "",
            },
            file_3_id: {
                label: "Adjunte CPF del dependiente",
                placeholder: "",
                description: "",
            },
            file_4_id: {
                label: "Adjuntar declaración de matriculación escolar",
                placeholder: "",
                description: "",
            },
            file_5_id: {
                label: "Adjuntar prueba de matrícula universitaria",
                placeholder: "",
                description: "",
            },
            file_6_id: {
                label: "Adjuntar Declaración de Dependencia Económica Registrada ante Notario",
                placeholder: "",
                description: "",
            },
            file_7_id: {
                label: "Adjuntar un acuerdo de tutela",
                placeholder: "",
                description: "",
            },
            file_8_id: {
                label: "Adjuntar la cartilla de vacunación (frente)",
                placeholder: "",
                description: "",
            },
            file_9_id: {
                label: "Ajuste de la tarjeta SUS",
                placeholder: "",
                description: "",
            },
            file_10_id: {
                label: "Adjuntar la cartilla de vacunación (atrás)",
                placeholder: "",
                description: "",
            },
            file_11_id: {
                label: "Adjuntar RG",
                placeholder: "",
                description: "",
            },
            file_12_id: {
                label: "Adjuntar certificado de matrimonio",
                placeholder: "",
                description: "",
            },
            file_13_id: {
                label: "Adjuntar informe PCD",
                placeholder: "",
                description: "",
            },
            file_14_id: {
                label: "Adjuntar expediente académico",
                placeholder: "",
                description: "",
            },
        },
        epi: {
            tshirt: {
                label: "Camiseta",
                placeholder: "",
                description: "",
            },
            trousers: {
                label: "Pantalón",
                placeholder: "",
                description: "",
            },
            shoe: {
                label: "Calzado",
                placeholder: "",
                description: "",
            },
            jacket: {
                label: "Chaqueta",
                placeholder: "",
                description: "",
            },
            shirt: {
                label: "Camisa",
                placeholder: "",
                description: "",
            },
        },
        benefits: {
            benefits: {
                label: "Seleccione los beneficios que desea recibir",
                placeholder: "",
                description: "",
            },
        },
        cnpj: {
            has_cnpj: {
                label: "¿Tiene una entidad jurídica?",
                placeholder: "",
                description: "",
            },
            cnpj_name: {
                label: "Razón social",
                placeholder: "",
                description: "",
            },
            cnpj_number: {
                label: "CNPJ",
                placeholder: "",
                description: "",
            },
        },
        contacts: {
            contact_name1: {
                label: "Nombre 1",
                placeholder: "",
                description: "",
            },
            contact_phone1: {
                label: "Teléfono 1",
                placeholder: "",
                description: "",
            },
            contact_name2: {
                label: "Nombre 2",
                placeholder: "",
                description: "",
            },
            contact_phone2: {
                label: "Teléfono 2",
                placeholder: "",
                description: "",
            },
        },
        forms: {
            transport_form_file_id: {
                label: "Adjuntar formulario de cupón de transporte",
                placeholder: "",
                description: "",
            },
            transport_file_id: {
                label: "Copia de la Tarjeta de cupón de Transporte",
                placeholder: "",
                description: "",
            },
            chartered_file_id: {
                label: "Formulario",
                placeholder: "",
                description: "",
            },
            salary_family_file_id: {
                label: "Formulario de salario familiar",
                placeholder: "",
                description: "",
            },
            impost_file_id: {
                label: "Adjuntar formulario del impuesto sobre la renta",
                placeholder: "",
                description: "",
            },
            has_contribution_sindical: {
                label: "¿Desea realizar su contribución sindical?",
                placeholder: "",
                description: "",
            },
            syndicate_contribution_file_id: {
                label: "Adjuntar Declaración de Adhesión a la Contribución Sindical",
                placeholder: "",
                description: "",
            },
            vaccine_file_id: {
                label: "Adjuntar Otros",
                placeholder: "",
                description: "",
            },
            unemployment_insurance_file_id: {
                label: "Adjuntar prueba del seguro de desempleo",
                placeholder: "",
                description: "",
            },
            contribution_inss_file_id: {
                label: "Adjuntar prueba de cotización al INSS",
                placeholder: "",
                description: "",
            },
            other_file_id: {
                label: "Adjuntar Otros",
                placeholder: "",
                description: "",
            },
            other_file2_id: {
                label: "Adjuntar Otros",
                placeholder: "",
                description: "",
            },
            other_file3_id: {
                label: "Adjuntar Otros",
                placeholder: "",
                description: "",
            },
            other_file4_id: {
                label: "Adjuntar Otros",
                placeholder: "",
                description: "",
            },
        },
    },
    admissional: {
        title: "Formulario de admisión",
        sections: {
            personal: {
                title: "Datos básicos",
                address: "Datos de dirección",
            },
            ctps: { title: "Cartera de Trabajo" },
            voter: { title: "Título de elector" },
            armed_forces: { title: "Certificado de reservista" },
            cnh: { title: "Licencia de conducir" },
            bank: {
                title: "Datos bancarios",
                generate_bank_letter:
                    "Gere aqui sua carta para abertura de conta bancária",
            },
            dependents: { title: "Dependientes" },
            epi: { title: "PPE/uniforme" },
            benefits: { title: "Selección de beneficios" },
            cnpj: { title: "Datos de la entidad jurídica" },
            contacts: { title: "Contactos de emergencia" },
            forms: { title: "Otros documentos" },
        },
    },
    vacancy: {
        filters: {
            text: {
                label: "",
                placeholder:
                    "Busque palabras clave como título del trabajo o empresa",
                description: "",
            },
            location: {
                label: "",
                placeholder: "Localización",
                description: "",
            },
            interval: {
                label: "",
                placeholder: "Fecha de publicación",
                description: "",
                tokens: {
                    anyTime: "Todos",
                    lastDay: "Últimas 24 horas",
                    lastThreeDays: "Últimos 3 días",
                    lastWeek: "Ultima semana",
                    lastMonth: "Últimos 30 días",
                },
            },
            positions: {
                label: "",
                placeholder: "",
                description: "",
                tokens: {
                    all: "Todos",
                    oneOrMore: "Más de 1 vacante",
                    fiveOrMore: "Más de 5 vacantes",
                    TenOrMore: "Más de 10 vacantes",
                },
            },
            contract_type: {
                label: "",
                placeholder: "Tipo de contrato",
                description: "",
                tokens: {
                    all: "Todos",
                    autonomous: "Autónomo",
                    clt: "CLT (efectivo)",
                    freeLance: "Trabajador autónomo",
                    juridicPerson: "Proveedor de servicios (PJ)",
                    temporary: "Temporario",
                    trainee: "Aprendiz",
                    internship: "Prácticas",
                    apprentice: "Joven aprendiz",
                    diarist: "Diarista",
                    cooperated: "Cooperado",
                    outsourced: "Subcontratado",
                },
            },
            workplace_type: {
                label: "",
                placeholder: "Todos los tipos de trabajo",
                description: "",
                tokens: {
                    all: "Todos",
                    presential: "Presencial",
                    hybrid: "Híbrido",
                    homeOffice: "Trabajo en casa",
                },
            },
            results:
                "Nenhum resultado encontrado | {count} vaga encontrada | {count} vagas encontradas",
        },
        actions: {
            share: "Compartir",
            apply: "Solicitar",
            "see-vacancy": "Ver vacante",
            seeAllVacancies: "Ver todas las vacantes",
            copy: "Copiar",
            favorite: "Vacante favorita",
            unfavorite: "Vacante no favorita",
        },
        texts: { pcd: { info: "Vacantes para personas con discapacidad" } },
        shareJob: "Comparte esta vacante",
        codVacancy: "Código de vacante:",
        jobsQuantity: "Numero de vacantes:",
        contract_type: "Tipo de contratación:",
        interest_area: "Área profesional:",
        workload: "Carga horaria:",
        work_schedule: "Jornada de trabajo:",
        salary: "Salario:",
        copyLink: "O copie el enlace",
        location: "Localización:",
        company: "Empresa",
        pcd: "Vacante PCD?",
        pcdInfo: "Tipos de PCD:",
        search: "Buscar",
        lastDay: "Últimas 24 horas",
        lastThreeDays: "Últimos 3 días",
        lastWeek: "Ultima semana",
        lastMonth: "Últimos 30 días",
        anyTime: "En cualquier momento",
        moreThan: "más de",
        anyJobsQuantity: "Cualquier cantidad de vacantes",
        cleanFilters: "Limpiar filtros",
        jobs: "vacantes",
        share: "Compatir",
        apply: "Solicitar",
        about: "Sobre nosotros",
        responsabilities: "Responsabilidades y obligaciones",
        requirements: "Requisitos y calificaciones",
        desirable: "Deseable",
        benefits: "Beneficios",
        additional_info: "Informaciones adicionales",
        keep_searching:
            "Siga buscando y experimente con otros términos y filtros para encontrar el trabajo adecuado para usted.",
        empty_vacancy: "No se encontró trabajo",
    },
    home: {
        sections: { vacancies: { title: "Vacantes destacadas" } },
        resume: "Currriculun",
        "active-vacancies":
            "Sin vacantes | 1 vacante activa | {Count} vacantes activas",
        "pending-items": "Sin pendencias | 1 pendencia | {cuenta} pendencias",
        "unread-messages": "1 no leído | {cuenta} no leído",
        completeness: "completado",
        messages: "Mensajes",
        welcome_msg: "Bienvenido a el inicio",
        hi: "Hola",
        follow: "acompañar",
        currentJobs: "Estás participando en",
        jobs: "vacantes",
        profileJobs: "Vacantes que se ajustan a su perfil",
        appointments: "Compromisos",
        curriculum: {
            empty: "Su currículum está un poco vacío",
            half: "Queda poco para completar su currículum",
            full: "Tu currículum está completo",
            complete: "Completa tu currículum",
            review: "Revisar el currículum",
            follow: "acompañar",
        },
    },
    registration: {
        placeholders: { "email-or-cpf": "" },
        congratulations_text:
            "Me alegro de que hayas encontrado una oportunidad.",
        account_not_found:
            '"No hemos encontrado ninguna cuenta registrada para este CPF',
        candidacy_start: "Iniciemos su solicitud",
        create_mini_curriculum:
            "Rellene su mini currículum para continuar su solicitud",
        hasAccount: "Ya tienes una cuenta?",
        login: "Entre aquí",
        fullName: "Nombre completo",
        typeFullName: "Escriba su nombre completo",
        createPassword: "Crear una contraseña",
        lowerLetter: "Una letra minúscula",
        upperLetter: "Una letra mayúscula",
        number: "Un número",
        specialChar: "Un carácter especial",
        eightChars: "8 caracteres al menos",
        agree_with: "Al pulsar inicio aceptas",
        as: "las",
        specific_policies: "Políticas específicas",
        from_company: "la empresa",
        os: "los",
        terms_and_conditions: "Términos y Condiciones",
        and_the: "y la",
        privacy_policy: "Política de privacidad",
        from_selecty_platform: "desde la plataforma Selecty",
        continue: "Continuar",
        start: "Inicio",
        createAccount: "Aceptar y registrarse",
        confirmPassword: "Confirmar la contraseña",
    },
    messages: {
        search: "Buscar mensajes",
        noMessagesFound: "Su buzón de mensajes está vacío",
        noSubject: "(sin asunto)",
        earlierMessages: "Mensajes anteriores",
        all: "Todo",
        read: "Leídos",
        unread: "No leídos",
        reply: "Responder",
        reply_message:
            "Escriba su mensaje de respuesta y haga clic en responder",
    },
    chat: { startConversation: "Iniciar conversación", send: "Enviar" },
    config: {
        title: "Configuraciones de cuentas",
        shortTitle: "Cuenta",
        notificationsTitle: "Notificaciones",
        password: {
            changePassword: "Cambiar la contraseña",
            savePassword: "Guardar contraseña",
            actualPassword: "contraseña actual",
            informActualPassword: "Escriba su contraseña actual",
            newPassword: "Nueva contraseña",
            informNewPassword: "Escriba una nueva contraseña",
            confirmPassword: "Confirmación de contraseña",
            informConfirmPassword: "Confirma tu nueva contraseña",
        },
        email: {
            changeEmail: "Cambia tu correo electrónico",
            saveEmail: "Guardar correo electrónico",
            newEmail: "Nuevo correo electrónico",
            informNewEmail: "Escriba el nuevo correo electrónico",
            confirmeNewEmail: "Confirmar el nuevo correo electrónico",
        },
        notifications: {
            push: {
                title: "Notificaciones push",
                description:
                    '"Reciba notificaciones push sobre nuevas vacantes',
            },
            email: {
                title: "Notificaciones vía correo electrónico",
                description:
                    '"Reciba actualizaciones por correo electrónico sobre nuevas vacantes',
            },
            whatsapp: {
                title: "Notificaciones vía Whatsapp",
                description:
                    '"Reciba actualizaciones a través de WhatsApp sobre nuevas vacantes',
            },
        },
        dataPrivacy: {
            title: "Privacidad de datos",
            description1: "Tú también puedes",
            description2: "ejercer todos los derechos de los titulares",
            description3:
                "Proporcionado en la Ley General de Protección de Datos - LGPD.",
            seePrivacyPolicy: "Ver Política de privacidad",
        },
        cancelAccount: {
            title: "Cancelación de cuentas",
            btnCancelAccount: "Cancelar la cuenta",
            description1: "La cancelación de la cuenta es un proceso",
            description2: "irreversible",
            description3: "que borrarán los datos de su sistema",
            description4:
                "La cancelación y la eliminación de todos sus datos se realizarán dentro de los 7 días hábiles y recibirá confirmación en su correo electrónico.",
        },
    },
    application: {
        title: "Mis vacantes",
        progress: "Progreso de la vacante",
        jobDetail: "Ver detalles de vacantes",
        applicationStep: "Está en la fase de solicitud",
        completeSteps: "Complete los siguientes pasos para continuar",
        candidacy_complete:
            "Complementa tu currículum para aumentar las posibilidades de ser encontrado.",
        candidacy_confirmed: "¡Candidatura confirmada!",
        start: "Comenzar",
        startInfo: '"Para comenzar este paso',
        nextStep: "Próxima fase",
        completeMessage: '"Una vez completado el proceso de solicitud',
        myApplications: "Mis vacantes",
        curriculumStep: "Completar el currículum",
        quit: "Ríndete",
        seeProgress: "Ver progreso",
        noVacancyEnrolled:
            "No está participando en ningún proceso de selección",
        searchForVacancies: "Buscar vacantes",
    },
    lgpd: {
        title: "Formulario para la solicitud de los derechos del titular de datos personales",
        subtitle:
            '"Directrices generales Proporcionamos este formulario legalmente basado en el ART.18 de la LGPD',
        btn_finish: "Finalizar",
        declaration_of_authenticity: {
            label: "Declaración de autenticidad de la información proporcionada",
            placeholder: "",
            description: '"Declaro',
        },
        data_holder: {
            label: "¿Es usted el interesado o abogado/representante?",
            placeholder: "",
            description: "",
        },
        sections: {
            personal_data_holder: {
                title: "Calificaciones del titular de datos personales",
                subtitle:
                    "Complete los espacios a continuación con las calificaciones del titular de datos personales.",
            },
            identity_comprovation: {
                title: "Prueba de la identidad del titular de datos personales",
                subtitle:
                    '"Solicitamos que la prueba de identidad del titular de datos personales se adjunte a la solicitud. Los documentos de identidad civil serán aceptados como identificación',
            },
            data_holder_rights: {
                title: "Derechos del titular de datos personales: ¿Qué derechos le gustaría ejercer?",
                subtitle:
                    '"Seleccione ""Sí"" solo para aquellos que desean hacer ejercicio en este momento."',
            },
            data_holder_rights_actions: {
                title: "Derechos de los titulares",
                subtitle: "",
            },
            data_request: {
                title: '"Para ayudar a Selecty a responder a su solicitud con rapidez y eficacia',
                subtitle: "",
            },
            result_request: {
                title: "Comunicación del resultado de la solicitud",
                subtitle: "",
            },
            legal_agent_qualifications: {
                title: "Cualificación del abogado o representante legal del interesado (si procede)",
                subtitle:
                    "Complete los espacios a continuación con las calificaciones del fiscal o representante legal del titular de datos personales (si se aplica).",
            },
            comprovation_power_grant: {
                title: "Prueba de concesión o delegación de poderes a terceros (si se aplica) el no título",
                subtitle:
                    '"Solicitamos que la prueba de identidad del fiscal o representante legal del titular de datos personales se adjunte a la solicitud',
            },
        },
        data_holder_name: {
            label: "Nombre completo del titular de datos personales",
            placeholder: "",
            description: "",
        },
        data_holder_cpf: {
            label: "CPF del titular de datos personales",
            placeholder: "",
            description: "",
        },
        data_holder_phone: {
            label: "Teléfono de contacto del titular de datos personales",
            placeholder: "",
            description: "",
        },
        data_holder_email: {
            label: "Correo electrónico de contacto del titular de datos personales",
            placeholder: "",
            description: "",
        },
        data_holder_category: {
            label: "Categoría de titular de datos personales",
            placeholder: "",
            description: "",
        },
        data_holder_additional_info: {
            label: "Informaciones adicionales",
            placeholder: "",
            description:
                "Cualquier información adicional que pueda ayudarnos a localizar sus datos personales",
        },
        data_holder_document: {
            label: "Adjuntar documento de identidad civil",
            placeholder: "",
            description: '"CPF',
        },
        confirm_treatment_data: {
            label: "¿Desea confirmar la existencia de tratamiento de sus datos personales?",
            placeholder: "",
            description:
                '"Le permite saber si Selecy ha realizado algún tipo de procesamiento de sus datos personales',
        },
        confirm_access_data: {
            label: "¿Quieres acceso a tus datos?",
            placeholder: "",
            description:
                '"Le permite conocer el procesamiento de datos personales realizados por Selecy. Este artículo debe marcarse solo si ya ha recibido o ha tenido alguna relación previa con nuestra empresa. Art.18',
        },
        hide_data: {
            label: '"¿Quieres eliminar',
            placeholder: "",
            description: '"Permite el anonimización',
        },
        exercise_rights: {
            label: '"Si ya tiene conocimiento de la existencia de un tratamiento de sus datos personales por parte de Selecty o se ha confirmado',
            placeholder: "",
            description: "",
        },
        data_request: {
            label: '"Si es posible',
            placeholder: "",
            description: "",
        },
        result_request: {
            label: "¿Cómo desea que se le informe del resultado de su solicitud?",
            placeholder: "",
            description: "",
        },
        legal_agent_name: {
            label: "Nombre completo del abogado o del representante legal",
            placeholder: "",
            description: "",
        },
        legal_agent_cpf: {
            label: "CPF del abogado o representante legal",
            placeholder: "",
            description: "",
        },
        legal_agent_address: {
            label: "Dirección del abogado o representante legal",
            placeholder: "",
            description: '"Calle',
        },
        legal_agent_phone: {
            label: "Teléfono de contacto del abogado o representante legal",
            placeholder: "",
            description: "Teléfono con DDD en formato xxxxx-xxxx",
        },
        legal_agent_email: {
            label: "Correo electrónico de abogado del apoderado o representante legal",
            placeholder: "",
            description: "",
        },
        legal_agent_document: {
            label: "Prueba de la identidad del abogado o representante legal del interesado",
            placeholder: "",
            description: "",
        },
        legal_agent_representative_document: {
            label: "Instrumento legal de representación o prueba de representación de los padres",
            placeholder: "",
            description: "",
        },
    },
    disc: {
        profile: {
            achiever: "Director",
            agent: "Representante",
            appraiser: "Evaluador",
            counselor: "Consejero",
            creative: "Creativo",
            developer: "Explorador",
            inspirational: "Inspirador",
            investigator: "Investigador",
            "objective thinker": "Pensador objetivo",
            overshift: "Expandido",
            perfectionist: "Perfeccionista",
            persuader: "Convincente",
            pratitioner: "Practicante",
            promoter: "Abogado",
            "result-oriented": "Orientación a los resultados",
            specialist: "Especialista",
            tight: "ajustado",
            undershift: "Comprimido",
        },
        question: {
            I_am: "Soy una persona...",
            More: "Más",
            Less: "Menos",
        },
        form: {
            welcome: "Dé la bienvenida a su evaluación.",
            instructions: "Haga clic en Continuar y seguir las instrucciones.",
            hello_user: "Hola {0}",
            lets_try_again: "Empezar de nuevo",
            warning_recording_again:
                '"El video previamente grabado para la pregunta no se guardó en el sistema',
            "time-to-response": "por respuesta",
            minutes: "{0} min.",
            seconds: "{0} Sec",
            "testing-your-camera": "Pruebe su cámara",
            "i-can-see-me-and-the-quality-is-good":
                "Me veo y la calidad es buena.",
            "testing-your-audio": "Probar su audio",
            "i-can-hear-me-and-the-quality-is-good":
                "Me escucho y la calidad es buena.",
            congratulations: "Felicitaciones {0}",
            "you-finished": "Ha cumplimentado el cuestionario",
            "wait-return": "Esperar el retorno del selector",
            "to-start": "para comenzar",
            "answer-now": "Responde ahora",
            start: "Para comenzar",
            continue: "Continuar",
            "ok-already-answered": '"Ahí',
            "all-working": "Todo funcionando",
            "have-a-good-interview": "Tenga una buena entrevista",
            question: "Pregunta",
            of: "de",
            disc_behavioural_profile: "perfil de comportamiento DISC",
            "you-are-here-to-discover-your-behavioral-disc-profile":
                "Estás aquí para descubrir tu perfil de comportamiento DiSC",
            "what-is-it-for": "Para que sirve",
            "your-behavioral-profile-will-help-you-understand-how you-work-on-a-team-and-even-in-life-in-general":
                "Tu perfil de comportamiento te ayudará a comprender cómo trabajas en equipo e incluso en la vida en general",
            "lets-begin": "Vamos a empezar",
            "there-will-be-28-quick-questions": "Habrá 28 preguntas rápidas",
            "see-more-instructions-next":
                "Vea más instrucciones a continuación",
            "skip-and-start-the-test": "Salta y comienza la prueba",
            "youve-completed-more-than-half-of-the-test":
                "Ha completado más de la mitad de la prueba",
            "How_was_the_test?": "¿Cómo fue el test?",
            thanks_for_your_feedback: "Agradecemos pelo feedback!",
            we_will_do_our_best_to_improve_your_user_experience:
                "Haremos todo lo posible para mejorar su experiencia de usuario",
            understood: "Entendido",
            start: "Empezar",
            disc_invalid_profile_message:
                "En base a las respuestas proporcionadas, no fue posible ajustar su perfil de comportamiento a los estándares establecidos por la metodología DISC.",
            remake_evaluation:
                "Necesitamos que vuelvas a hacer la evaluación para que se identifique tu perfil y puedas avanzar en este proceso de Reclutamiento y Selección en el que estás postulando.",
            disc_denied_message_intro:
                "Por ahora no es necesario volver a realizar tu DISC",
            disc_denied_message_body:
                "Ha completado el inventario de comportamiento DISC en los últimos 6 meses y su último resultado aún es válido.",
            disc_denied_message_conclusion:
                "Esta medida se adopta para garantizar la coherencia y transparencia de nuestro proceso. ¡Realmente apreciamos su comprensión!",
            execution_date: "Realizado en",
            valid_until: "Actual hasta",
        },
    },
    evaluation: {
        expired: {
            title: "Prazo Expirado",
            description:
                "Infelizmente o prazo para responder o teste já venceu",
        },
        form: {
            attention: "Atención",
            forward_without_answering:
                "¿Quieres seguir adelante sin responder?",
            not_possible_return: "No será posible retroceder sobre este tema.",
            "choose-a-quiet-and-well-lit-place":
                "Elige un lugar tranquilo y bien iluminado",
            "if-possible-use-wifi-or-broadband-connection":
                "Si es posible, use wifi o banda ancha",
            "so-you-can-focus-on-what-really-matter":
                "Para que puedas concentrarte en qué realmente importa",
            "this-way-you-avoid-problems-with-slowness-during-the-interview":
                "De esta manera evitas problemas con lentitud durante la entrevista",
            "got-it": "Entendido",
            start: "Empezar",
        },
    },
    "test-alerts": {
        "test-cam": "Probar cámara",
        "test-mic": "Probar micrófono",
    },
    styleguide: {
        spinbutton: {
            field: {
                label: "Botón giratorio de campo de formulario",
                placeholder: "",
                description:
                    "Utilice los campos siguientes para ajustar el componente",
            },
            step: { label: "Paso", placeholder: "", description: "" },
            min: { label: "Mínimo", placeholder: "", description: "" },
            max: { label: "Máximo", placeholder: "", description: "" },
        },
    },
    //},
};
